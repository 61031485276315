// // // Team.jsx

// // import React from 'react';
// // import Founder from './founder'; // Assuming this is the path to your Founder component

// // const Team = () => {
// //   const founders = [
// //     {
// //       name: 'Founder 1 Name',
// //       position: 'Co-Founder & CEO',
// //       bio: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
// //       imageUrl: '/images/founder1.jpg', // Replace with actual image path
// //     },
// //     {
// //       name: 'Founder 2 Name',
// //       position: 'Co-Founder & CTO',
// //       bio: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
// //       imageUrl: '/images/founder2.jpg', // Replace with actual image path
// //     },
// //     // Add more founders as needed
// //   ];

// //   return (
// //     <section className="bg-gray-100 py-12">
// //       <div className="container mx-auto px-4">
// //         <h2 className="text-3xl font-semibold text-center mb-8">Meet Our Team</h2>
// //         <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-8">
// //           {founders.map((founder, index) => (
// //             <Founder key={index} {...founder} />
// //           ))}
// //         </div>
// //       </div>
// //     </section>
// //   );
// // };

// // import React from 'react';
// // import Founder from './founder'; // Adjust the path as per your file structure
// // import Shiraz from "../../assets/Shiraz.jpeg";
// // import Kelvin from "../../assets/kelvin.jpeg";
// // import Hatem from "../../assets/Hatem.jpeg";
// // import Fira from "../../assets/fira.jpeg";
// // import Dami from "../../assets/Dami.jpeg";

// // const Team = () => {
// //   const founders = [
// //     {
// //       name: 'Firaoll Umar',
// //       position: 'Co-Founder & CEO',
// //       imageUrl: Fira,
// //     },
// //     {
// //       name: 'Shiraz Din',
// //       position: 'Co-Founder & CEO',
// //       imageUrl: Shiraz,
// //     },
// //     {
// //       name: 'Kelvin Tran',
// //       position: 'Co-Founder & CTO',
// //       imageUrl: Kelvin,
// //     },
// //     {
// //       name: 'Hatem Muhamad',
// //       position: 'Lead Software Engineer',
// //       imageUrl: Hatem,
// //     },
// //     {
// //       name: 'Damilola Adesola',
// //       position: 'Backend Software Engineer',
// //       imageUrl: Dami,
// //     },
// //   ];

// //   return (
// //     <section className="bg-gray-100 py-12">
// //       <div className="container mx-auto px-4">
// //         <h2 className="text-3xl font-semibold text-center mb-8">Meet Our Team</h2>
// //         <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5">
// //           {founders.map((founder, index) => (
// //             <Founder key={index} {...founder} />
// //           ))}
// //         </div>
// //       </div>
// //     </section>
// //   );
// // };

// // export default Team;


// import React from 'react';
// import Founder from './founder'; // Adjust the path as per your file structure
// import Shiraz from "../../assets/Shiraz.jpeg";
// import Kelvin from "../../assets/kelvin.jpeg";
// import Hatem from "../../assets/Hatem.jpeg";
// import Fira from "../../assets/fira.jpeg";
// import Dami from "../../assets/Dami.jpeg";
// import Neel from "../../assets/neel.jpeg";

// const Team = () => {
//   const founders = [
//     {
//       name: 'Firaoll Umar',
//       position: 'Co-Founder & CEO',
//       imageUrl: Fira,
//     },
//     {
//         name: 'Hatem Muhamad',
//         position: 'Lead Software Engineer',
//         imageUrl: Hatem,
//       },

//     {
//       name: 'Shiraz Din',
//       position: 'Co-Founder & CEO',
//       imageUrl: Shiraz,
//     },
//     {
//       name: 'Kelvin Tran',
//       position: 'Co-Founder & CTO',
//       imageUrl: Kelvin,
//     },
   
//     {
//       name: 'Damilola Adesola',
//       position: 'Backend Software Engineer',
//       imageUrl: Dami,
//     },

//     {
//         name: 'Neel Kulkarni',
//         position: 'Co-Founder & CTO',
//         imageUrl: Neel,
//       },

//   ];

//   return (
//     <section className="bg-gray-100 py-12">
//       <div className="container mx-auto px-4">
//         <h2 className="text-3xl font-semibold text-center mb-8">Meet Our Team</h2>
//         <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5">
//           {founders.map((founder, index) => (
//             <Founder key={index} {...founder} />
//           ))}
//         </div>
//       </div>
//     </section>
//   );
// };

// export default Team;



// import React from 'react';
// import Founder from './founder'; // Adjust the path as per your file structure
// import Shiraz from "../../assets/Shiraz.jpeg";
// import Kelvin from "../../assets/kelvin.jpeg";
// import Hatem from "../../assets/Hatem.jpeg";
// import Fira from "../../assets/fira.jpeg";
// import Dami from "../../assets/Dami.jpeg";
// import Neel from "../../assets/neel.jpeg";

// const Team = () => {
//   const founders = [
//     {
//       name: 'Firaoll Umar',
//       position: 'Founder & CEO',
//       imageUrl: Fira,
//     },
//     {
//       name: 'Hatem Muhamad',
//       position: 'Lead Software Engineer',
//       imageUrl: Hatem,
//     },

//     {
//         name: 'Damilola Adesola',
//         position: 'Backend Software Engineer',
//         imageUrl: Dami,
//       },
//     {
//       name: 'Shiraz Din',
//       position: 'CFO',
//       imageUrl: Shiraz,
//     },
//     {
//       name: 'Kelvin Tran',
//       position: 'COO',
//       imageUrl: Kelvin,
//     },
    
//     {
//       name: 'Neel Kulkarni',
//       position: 'CMO',
//       imageUrl: Neel,
//     },
//   ];

//   return (
//     <section className="py-24">
//       <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
//         <div className="mb-12">
//           <h2 className="font-manrope text-5xl text-center font-bold text-gray-900">Our Team</h2>
//         </div>
//         <div className="grid grid-cols-1 min-h-[500px] md:grid-cols-2 md:max-w-3xl lg:grid-cols-5 lg:max-w-full gap-8 mx-auto">
//           {founders.map((founder, index) => (
//             <div key={index} className="block group md:col-span-2 lg:col-span-1 relative">
//               <div className="relative mb-6">
//                 <img
//                   src={founder.imageUrl}
//                   alt={founder.name}
//                   className="w-40 h-40 rounded-full mx-auto transition-all duration-500 object-cover border border-solid border-transparent group-hover:border-indigo-600"
//                 />
//               </div>
//               <h4 className="text-xl font-semibold text-gray-900 mb-2 capitalize text-center transition-all duration-500 group-hover:text-indigo-600">
//                 {founder.name}
//               </h4>
//               <span className="text-gray-500 text-center block transition-all duration-500 group-hover:text-gray-900">
//                 {founder.position}
//               </span>
//             </div>
//           ))}
//         </div>
//       </div>
//     </section>
//   );
// };

// export default Team;


// import React from 'react';
// import Founder from './founder'; // Adjust the path as per your file structure
// import Shiraz from "../../assets/Shiraz.jpeg";
// import Kelvin from "../../assets/kelvin.jpeg";
// import Hatem from "../../assets/Hatem.jpeg";
// import Fira from "../../assets/firaoll.jpeg";
// import Dami from "../../assets/Dami.jpeg";
// import Neel from "../../assets/neel.jpeg";

// const Team = () => {
//   const founders = [
//     {
//       name: 'Firaoll Umar',
//       position: 'Project Lead',
//       imageUrl: Fira,
//     },
//     {
//       name: 'Hatem Muhamad',
//       position: 'CTO & Lead Software Engineer',
//       imageUrl: Hatem,
//     },
//     {
//       name: 'Shiraz Din',
//       position: 'Chief Financial Officer',
//       imageUrl: Shiraz,
//     },
//     {
//       name: 'Kelvin Tran',
//       position: 'Business Development Lead',
//       imageUrl: Kelvin,
//     },
//     {
//       name: 'Damilola Adesola',
//       position: 'Founding Full-Stack Engineer',
//       imageUrl: Dami,
//     },
//     {
//       name: 'Neel Kulkarni',
//       position: 'Chief Marketing Officer',
//       imageUrl: Neel,
//     },
//   ];

//   return (
//     <section className="py-24">
//       <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
//         <div className="mb-12">
//           <h2 className="font-manrope text-5xl text-center font-bold text-gray-900 mb-6">Meet the Team</h2>
//           <p className="text-lg text-gray-500 text-center">The brains behind Serenoty AI</p>
//         </div>
//         <div className="flex flex-wrap justify-center gap-y-14 max-w-3xl mx-auto lg:max-w-full">
//           {founders.map((founder, index) => (
//             <div key={index} className="group block text-center lg:w-1/5 sm:w-1/3 min-[450px] w-full">
//               <div className="relative mb-5">
//                 <img
//                   src={founder.imageUrl}
//                   alt={founder.name}
//                   className="w-28 h-28 rounded-2xl object-cover mx-auto transition-all duration-500 border-2 border-solid border-transparent group-hover:border-secondary"
//                 />
//               </div>
//               <h4 className="text-xl text-gray-900 font-semibold mb-2 transition-all duration-500 group-hover:text-secondary">{founder.name}</h4>
//               <span className="text-gray-500 block mb-6 transition-all duration-500 group-hover:text-gray-900">{founder.position}</span>
//             </div>
//           ))}
//         </div>
//       </div>
//     </section>
//   );
// };

// export default Team;


// import React from 'react';
// import { useInView } from 'react-intersection-observer';
// import './team.css'; // Make sure to create and adjust this CSS file as needed

// import Shiraz from "../../assets/Shiraz.jpeg";
// import Kelvin from "../../assets/kelvin.jpeg";
// import Hatem from "../../assets/Hatem.jpeg";
// import Fira from "../../assets/firaoll.jpeg";
// import Dami from "../../assets/Dami.jpeg";
// import Neel from "../../assets/neel.jpeg";
// import Peter from "../../assets/peter.png";

// const Team = () => {
//   const founders = [
//     {
//       name: 'Firaoll Umar',
//       position: 'Project Lead',
//       imageUrl: Fira,
//     },


  
//     {
//       name: 'Hatem Muhamad',
//       position: 'CTO & Lead Software Engineer',
//       imageUrl: Hatem,
//     },

//     {
//       name: 'Neel Kulkarni',
//       position: 'Chief Marketing Officer',
//       imageUrl: Neel,
//     },

//     {
//       name: 'Peter Huang',
//       position: 'Frontend Software Engineer ',
//       imageUrl: Peter,
//     },


//     {
//       name: 'Shiraz Din',
//       position: 'Chief Financial Officer',
//       imageUrl: Shiraz,
//     },
//     {
//       name: 'Kelvin Tran',
//       position: 'Business Development Lead',
//       imageUrl: Kelvin,
//     },
//     {
//       name: 'Damilola Adesola',
//       position: 'Full-Stack Engineer',
//       imageUrl: Dami,
//     },
   
//   ];

//   return (
//     <section className="py-24">
//       <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
//         <div className="mb-12">
//           <h2 className="font-manrope text-5xl text-center font-bold text-gray-900 mb-6">Our Team</h2>
  
//         </div>
//         <div className="flex flex-wrap justify-center gap-y-14 max-w-3xl mx-auto lg:max-w-full">
//           {founders.map((founder, index) => (
//             <ProfileCard key={index} founder={founder} />
//           ))}
//         </div>
//       </div>
//     </section>
//   );
// };

// const ProfileCard = ({ founder }) => {
//   const { ref, inView } = useInView({
//     triggerOnce: true,
//     threshold: 0.1,
//   });

//   return (
//     <div ref={ref} className={`group block text-center lg:w-1/5 sm:w-1/3 min-[450px] w-full transition-transform duration-500 ease-in-out ${inView ? 'transform scale-100 opacity-100' : 'transform scale-75 opacity-0'}`}>
//       <div className="relative mb-5">
//         <img
//           src={founder.imageUrl}
//           alt={founder.name}
//           className="w-28 h-28 rounded-2xl object-cover mx-auto transition-all duration-500 border-2 border-solid border-transparent group-hover:border-secondary"
//         />
//       </div>
//       <h4 className="text-xl text-gray-900 font-semibold mb-2 transition-all duration-500 group-hover:text-secondary">{founder.name}</h4>
//       <span className="text-gray-500 block mb-6 transition-all duration-500 group-hover:text-gray-900">{founder.position}</span>
//     </div>
//   );
// };

// export default Team;


// import React from 'react';
// import { useInView } from 'react-intersection-observer';
// import './team.css'; // Make sure to create and adjust this CSS file as needed

// import Shiraz from "../../assets/Shiraz.jpeg";
// import Kelvin from "../../assets/kelvin.jpeg";
// import Hatem from "../../assets/Hatem.jpeg";
// import Fira from "../../assets/fira.jpeg";
// import Dami from "../../assets/Dami.jpeg";
// import Neel from "../../assets/neel.jpeg";
// import Peter from "../../assets/peter.png";
// import Noel from "../../assets/noel.png"; // Add Noel's image here

// const Team = () => {
//   const founders = [
//     {
//       name: 'Firaoll Umar',
//       position: 'Project Lead',
//       imageUrl: Fira,
//     },
//     {
//       name: 'Hatem Muhamad',
//       position: 'CTO & Lead Software Engineer',
//       imageUrl: Hatem,
//     },
//     {
//       name: 'Neel Kulkarni',
//       position: 'Chief Marketing Officer',
//       imageUrl: Neel,
//     },
//     {
//       name: 'Peter Huang',
//       position: 'Frontend Software Engineer',
//       imageUrl: Peter,
//     },
//     {
//       name: 'Shiraz Din',
//       position: 'Chief Financial Officer',
//       imageUrl: Shiraz,
//     },
//     {
//       name: 'Kelvin Tran',
//       position: 'Business Development Lead',
//       imageUrl: Kelvin,
//     },
//     {
//       name: 'Damilola Adesola',
//       position: 'Full-Stack Engineer',
//       imageUrl: Dami,
//     },
//     {
//       name: 'Noel Thomas',
//       position: 'Software Engineer',
//       imageUrl: Noel,
//     },
//   ];

//   return (
//     <section className="py-24">
//       <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
//         <div className="mb-12">
//           <h2 className="font-manrope text-5xl text-center font-bold text-gray-900 mb-6">Our Team</h2>
//         </div>
//         <div className="flex flex-wrap justify-center gap-y-14 max-w-3xl mx-auto lg:max-w-full">
//           {founders.map((founder, index) => (
//             <ProfileCard key={index} founder={founder} />
//           ))}
//         </div>
//       </div>
//     </section>
//   );
// };

// const ProfileCard = ({ founder }) => {
//   const { ref, inView } = useInView({
//     triggerOnce: true,
//     threshold: 0.1,
//   });

//   return (
//     <div ref={ref} className={`group block text-center lg:w-1/5 sm:w-1/3 min-[450px] w-full transition-transform duration-500 ease-in-out ${inView ? 'transform scale-100 opacity-100' : 'transform scale-75 opacity-0'}`}>
//       <div className="relative mb-5">
//         <img
//           src={founder.imageUrl}
//           alt={founder.name}
//           className="w-28 h-28 rounded-2xl object-cover mx-auto transition-all duration-500 border-2 border-solid border-transparent group-hover:border-secondary grayscale" // Apply grayscale filter here
//         />
//       </div>
//       <h4 className="text-xl text-gray-900 font-semibold mb-2 transition-all duration-500 group-hover:text-secondary">{founder.name}</h4>
//       <span className="text-gray-500 block mb-6 transition-all duration-500 group-hover:text-gray-900">{founder.position}</span>
//     </div>
//   );
// };

// export default Team;


// import React from 'react';
// import './team.css'; // Make sure to create and adjust this CSS file as needed

// import Shiraz from "../../assets/Shiraz.jpeg";
// import Kelvin from "../../assets/kelvin.jpeg";
// import Hatem from "../../assets/Hatem.jpeg";
// import Fira from "../../assets/firaoll.jpeg";
// import Dami from "../../assets/Dami.jpeg";
// import Neel from "../../assets/neel.jpeg";
// import Peter from "../../assets/peter.png";
// import Noel from "../../assets/Noel.jpeg"; // Add Noel's image here

// const Team = () => {
//   const founders = [
//     {
//       name: 'Firaoll Umar',
//       position: 'Project Lead',
//       imageUrl: Fira,
//     //   bio: 'Leading projects with a vision for innovation and excellence.'
//     },
//     {
//       name: 'Hatem Muhamad',
//       position: 'CTO & Lead Software Engineer',
//       imageUrl: Hatem,
//     //   bio: 'Expert in software engineering and technology leadership.'
//     },
//     {
//       name: 'Neel Kulkarni',
//       position: 'Chief Marketing Officer',
//       imageUrl: Neel,
//     //   bio: 'Driving marketing strategies to enhance brand visibility.'
//     },
//     {
//       name: 'Peter Huang',
//       position: 'Frontend Developer',
//       imageUrl: Peter,
//     //   bio: 'Creating intuitive and engaging user interfaces.'
//     },
//     {
//       name: 'Shiraz Din',
//       position: 'Chief Financial Officer',
//       imageUrl: Shiraz,
//     //   bio: 'Managing financial strategies and ensuring fiscal responsibility.'
//     },
//     {
//       name: 'Kelvin Tran',
//       position: 'Business Development Lead',
//       imageUrl: Kelvin,
//     //   bio: 'Expanding business opportunities and fostering partnerships.'
//     },
//     {
//       name: 'Damilola Adesola',
//       position: 'Full-Stack Developer',
//       imageUrl: Dami,
//     //   bio: 'Versatile developer proficient in both front-end and back-end.'
//     },
//     {
//       name: 'Noel Thomas',
//       position: 'Software Engineer',
//       imageUrl: Noel,
//     //   bio: 'Passionate about coding and problem-solving.'
//     },
//   ];

//   return (
//     <section className="section">
//       <div className="team-container">
//         {founders.map((founder, index) => (
//           <div key={index} className="card">
//             <img src={founder.imageUrl} alt={founder.name} />
//             <div className="card-content">
//               <h4>{founder.name}</h4>
//               <span>{founder.position}</span>
//               <p>{founder.bio}</p>
//             </div>
//           </div>
//         ))}
//       </div>
//     </section>
//   );
// };

// export default Team;

import React from 'react';
import './team.css'; // Make sure the CSS reflects a clean and simple style

import Shiraz from "../../assets/Shiraz.jpeg";
import Kelvin from "../../assets/kelvin.jpeg";
import Hatem from "../../assets/Hatem.jpeg";
import Fira from "../../assets/fira.jpeg";
import Dami from "../../assets/Dami.jpeg";
import Neel from "../../assets/neel.jpeg";
import Peter from "../../assets/peter.png";
import Noel from "../../assets/Noel.jpeg"; 

const Team = () => {
  const founders = [
    {
      name: 'Firaoll Umar',
      position: 'Project Lead',
      imageUrl: Fira,
    },
    {
      name: 'Hatem Muhamad',
      position: 'CTO & Lead Software Engineer',
      imageUrl: Hatem,
    },
    {
      name: 'Neel Kulkarni',
      position: 'Chief Marketing Officer',
      imageUrl: Neel,
    },
    {
      name: 'Peter Huang',
      position: 'Frontend Developer',
      imageUrl: Peter,
    },
    {
      name: 'Shiraz Din',
      position: 'Chief Financial Officer',
      imageUrl: Shiraz,
    },
    {
      name: 'Kelvin Tran',
      position: 'Business Development Lead',
      imageUrl: Kelvin,
    },
    {
      name: 'Damilola Adesola',
      position: 'Full-Stack Developer',
      imageUrl: Dami,
    },
    {
      name: 'Noel Thomas',
      position: 'Software Engineer',
      imageUrl: Noel,
    },
  ];

  return (
    <section className="team-section">
      <div className="team-grid">
        {founders.map((founder, index) => (
          <div key={index} className="team-card">
            <img src={founder.imageUrl} alt={founder.name} className="team-image"/>
            <div className="team-info">
              <h4 className="team-name">{founder.name}</h4>
              <span className="team-position">{founder.position}</span>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Team;
