// import React, { useState, useEffect } from "react";
// import pic1 from "../../assets/scdemo.png";
// import serenoty from "../../assets/snlogo2.png";
// import page from "../../assets/pages.svg";
// import createGlobe from 'cobe';
// import './banner.css';

// const Banner = () => {
//   const [isSerenotyVisible, setIsSerenotyVisible] = useState(false);
//   const [isInsightsVisible, setIsInsightsVisible] = useState(false);
//   const [isGlobeVisible, setIsGlobeVisible] = useState(false);
//   const [globeScale, setGlobeScale] = useState(1);

//   const handleIntersection = (entry, setVisibility, visibilityState) => {
//     if (entry.isIntersecting && !visibilityState) {
//       setVisibility(true);
//     }
//   };

//   const options = {
//     root: null,
//     rootMargin: "0px",
//     threshold: 0.8 // Adjust threshold as needed
//   };

//   useEffect(() => {
//     const serenotyElement = document.querySelector("#serenoty");
//     const insightsElement = document.querySelector("#insights");
//     const globeElement = document.querySelector("#globe");

//     const serenotyObserver = new IntersectionObserver((entries) => {
//       entries.forEach(entry =>
//         handleIntersection(entry, setIsSerenotyVisible, isSerenotyVisible)
//       );
//     }, options);

//     const insightsObserver = new IntersectionObserver((entries) => {
//       entries.forEach(entry =>
//         handleIntersection(entry, setIsInsightsVisible, isInsightsVisible)
//       );
//     }, options);

//     const globeObserver = new IntersectionObserver((entries) => {
//       entries.forEach(entry =>
//         handleIntersection(entry, setIsGlobeVisible, isGlobeVisible)
//       );
//     }, options);

//     if (serenotyElement) {
//       serenotyObserver.observe(serenotyElement);
//     }

//     if (insightsElement) {
//       insightsObserver.observe(insightsElement);
//     }

//     if (globeElement) {
//       globeObserver.observe(globeElement);
//     }

//     return () => {
//       if (serenotyElement) {
//         serenotyObserver.unobserve(serenotyElement);
//       }

//       if (insightsElement) {
//         insightsObserver.unobserve(insightsElement);
//       }

//       if (globeElement) {
//         globeObserver.unobserve(globeElement);
//       }
//     };
//   }, [isSerenotyVisible, isInsightsVisible, isGlobeVisible]);

//   useEffect(() => {
//     const handleResize = () => {
//       if (window.matchMedia("(max-width: 768px)").matches) {
//         setGlobeScale(0.8); // Smaller scale for mobile screens
//       } else {
//         setGlobeScale(1); // Default scale for larger screens
//       }
//     };

//     // Set the initial scale
//     handleResize();

//     // Add event listener for resize
//     window.addEventListener("resize", handleResize);

//     // Cleanup event listener on component unmount
//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, []);

//   useEffect(() => {
//     if (isGlobeVisible) {
//       let phi = 0;
//       const canvas = document.getElementById("cobe");

//       if (canvas) {
//         createGlobe(canvas, {
//           devicePixelRatio: 2,
//           width: 1000,
//           height: 1000,
//           phi: 1.5,
//           opacity: 0.5,
//           theta: 0,
//           dark: 0, // Set dark to 0 for a white map
//           diffuse: 3.2,
//           scale: globeScale, // Use the state variable for scale
//           mapSamples: 16000,
//           mapBrightness: 4, // Adjust brightness to make map white against black
//           baseColor: [1, 1, 1],
//           markerColor: [244 / 255, 158 / 255, 128 / 255],
//           glowColor: [1, 1, 1],
//           offset: [0, 0],
//           markers: [
//             { location: [37.7595, -122.4367], size: 0.03 }, // San Francisco
//             { location: [40.7128, -74.006], size: 0.1 }, // New York
//             { location: [51.5074, -0.1278], size: 0.2 }, // London
//             { location: [35.6895, 139.6917], size: 0.08 }, // Tokyo
//             { location: [48.8566, 2.3522], size: 0.08 }, // Paris
//             { location: [55.7558, 37.6176], size: 0.08 }, // Moscow
//             { location: [-33.8688, 151.2093], size: 0.04 }, // Sydney
//             { location: [-22.9068, -43.1729], size: 0.05 }, // Rio de Janeiro
//             { location: [-33.4372, -70.6506], size: 0.04 }, // Santiago
//             { location: [-1.2864, 36.8172], size: 0.08 }, // Nairobi
//             { location: [-26.2041, 28.0473], size: 0.08 }, // Johannesburg
//             { location: [22.5726, 88.3639], size: 0.08 }, // Kolkata
//             { location: [19.076, 72.8777], size: 0.08 }, // Mumbai
//             { location: [-6.2088, 106.8456], size: 0.09 }, // Jakarta
//             // Additional markers
//             { location: [34.0522, -118.2437], size: 0.05 }, // Los Angeles
//             { location: [41.8781, -87.6298], size: 0.07 }, // Chicago
//             { location: [39.9042, 116.4074], size: 0.07 }, // Beijing
//             { location: [-34.6037, -58.3816], size: 0.06 }, // Buenos Aires
//             { location: [40.4168, -3.7038], size: 0.07 }, // Madrid
//             { location: [37.5665, 126.978], size: 0.07 }, // Seoul
//             { location: [41.9028, 12.4964], size: 0.07 }, // Rome
//             { location: [39.9526, -75.1652], size: 0.06 }, // Philadelphia
//             { location: [55.9533, -3.1883], size: 0.07 }, // Edinburgh
//             { location: [28.6139, 77.209], size: 0.07 }, // New Delhi
//             { location: [31.2304, 121.4737], size: 0.07 }, // Shanghai
//             { location: [-37.8136, 144.9631], size: 0.06 }, // Melbourne
//             { location: [-34.397, 150.8931], size: 0.05 }, // Wollongong
//           ],
//           onRender: (state) => {
//             state.phi = phi;
//             phi += 0.009; // Slowing down the rotation speed
//           },
//         });
//       }
//     }
//   }, [isGlobeVisible, globeScale]);

//   return (
//     <div className="font-clashdisplay max-w-7xl mt-20 mx-auto px-4 sm:px-6 lg:px-8">
//       <div className="ban-container text-center mt-4"></div>

//       <div className="space-y-8">
//         {/* Serenity Image Section */}
//         <div>
//           <section id="serenoty" className="py-12 md:py-16 bg-purple">
//             <div className="flex justify-center">
//               <div className="border rounded-xl border-tt border-4  overflow-hidden">
//                 <img src={serenoty} alt="Serenoty AI" className="object-cover" />
//               </div>
//             </div>
//           </section>
//         </div>


// {/* Gain Insights Faster Section */}
// <div>
//   <section id="insights" className="py-8 md:py-16 flex flex-col lg:flex-row justify-between bg-[#f8f8f8] rounded-2xl overflow-hidden" style={{ height: '600px' }}>
//     {/* Text Container */}
//     <div className="lg:w-1/2 p-10 flex flex-col justify-center">
//       <h2 className="text-3xl leading-relaxed font-extrabold sm:text-4xl text-[#333]">
//         Say goodbye to endless browsing...
//       </h2>
//       <p className="mt-4 text-[#333] text-opacity-60 text-base md:text-lg leading-relaxed">
//         Elevate your writing with an AI assistant. Receive{" "}
//         <span className="bg-secondary text-tt px-2 py-1 rounded-full font-semibold">
//           context-aware
//         </span>{" "}
//         suggestions and annotations directly within your workspace.
//       </p>
//     </div>
//     {/* Image Container */}
//     <div className="lg:w-full relative overflow-hidden flex items-end pb-0"  style={{ height: '600px' }}>
//       <img src={pic1} alt="Insightful content" className="object-cover w-full h-full rounded-md border border-lg" style={{ transform: 'translate(60px, 30px)' }} />
//       {/* Gain Insights Faster Section */}
// <div>
//   <section id="insights" className="py-8 md:py-16 flex flex-col lg:flex-row justify-between bg-[#f8f8f8] rounded-2xl overflow-hidden" style={{ height: '600px' }}>
//     {/* Text Container */}
//     <div className="lg:w-1/2 p-10 flex flex-col justify-center">
//       <h2 className="text-3xl leading-relaxed font-extrabold sm:text-4xl text-[#333]">
//         Say goodbye to endless browsing...
//       </h2>
//       <p className="mt-4 text-[#333] text-opacity-60 text-base md:text-lg leading-relaxed">
//         Elevate your writing with an AI assistant. Receive{" "}
//         <span className="bg-secondary text-tt px-2 py-1 rounded-full font-semibold">
//           context-aware
//         </span>{" "}
//         suggestions and annotations directly within your workspace.
//       </p>
//     </div>
//     {/* Image Container */}
//     <div className="lg:w-full relative overflow-hidden flex items-end pb-0">
//       <img src={pic1} alt="Insightful content" className="object-cover w-full h-full rounded-md border border-gray-200border-lg" style={{ transform: 'translate(15px, 30px)' }} />
//       {/* Black border gradient */}
     
//     </div>
//   </section>
// </div>



//     </div>
//   </section>
// </div>


// {/* Flex Container for 20K Pages and Globe Sections */}
// <div className="flex flex-col md:flex-row items-stretch space-y-8 md:space-y-0 md:space-x-4">
//   {/* 20K Pages Section */}
//   <div className="w-full md:w-1/2 relative">
//     <section className="flex flex-col justify-between h-full bg-[#F6F5F4] border border-tt rounded-2xl overflow-hidden py-10 md:py-16 lg:py-20 mb-8 lg:mb-0">
//       <div className="p-10">
//         <h3 className="text-3xl font-extrabold text-[#333]">Built for large documents</h3>
//         <p className="py-2 text-[#333] text-opacity-60 text-base md:text-lg leading-relaxed">
//           Easily manage and process PDFs up to 20,000 pages with uncompromised efficiency and speed.
//         </p>
//       </div>
//       <div className="p-8 flex flex-col items-center justify-end text-[#e1e1e1] relative">
//         <span className="z-10 absolute inset-0 flex items-center justify-center text-[3.5rem] sm:text-[4rem] font-semibold !tracking-[-0.3rem] !leading-[1]">20K Pages</span>
//         <img className="inset-0 m-auto max-w-full max-h-full sm:max-w-xs md:max-w-sm lg:max-w-md" src={page} alt="20K Pages Feature" />
//       </div>
//       <div className="absolute left-0 bottom-0 h-4 w-full z-10"></div>
//     </section>
//   </div>

//   {/* Globe Section */}
//   <div className="w-full md:w-1/2 relative">
//     <section id="globe" className="flex flex-col justify-between h-full bg-[#F6F5F4] border border-tt rounded-2xl overflow-hidden py-10 md:py-16 lg:py-20 mb-8 lg:mb-0">
//       <div className="p-10">
//         <h2 className="text-3xl font-extrabold text-[#333]">
//           Available{" "}
//           <span className="text-3xl font-extrabold text-secondary px-2 py-1 rounded-full font-semibold">wherever</span>{" "}
//           you are, in every major language
//         </h2>
//         <p className="py-2 text-[#333] text-opacity-60 text-base md:text-lg leading-relaxed">
//           Serenoty supports over 100 languages, including French, Spanish, Arabic, Chinese, German, Japanese, and many more.
//         </p>
//       </div>
//       <div className="flex justify-center relative">
//         <canvas id="cobe" className="w-full md:w-2/3 lg:w-1/2" style={{ width: "500px", height: "500px" }} width="1000" height="1000"></canvas>
//       </div>
//     </section>
//   </div>
// </div>

//       </div>
//     </div>
//   );
// };

// export default Banner;


// import React, { useState, useEffect } from "react";
// import pic1 from "../../assets/notephoto.png";
// import serenoty from "../../assets/serenotyphoto.png";
// import page from "../../assets/pages.svg";
// import createGlobe from "cobe";
// import "./banner.css";

// const Banner = () => {
//   const [isSerenotyVisible, setIsSerenotyVisible] = useState(false);
//   const [isInsightsVisible, setIsInsightsVisible] = useState(false);
//   const [isGlobeVisible, setIsGlobeVisible] = useState(false);
//   const [globeScale, setGlobeScale] = useState(1);

//   const handleIntersection = (entries, observer, setVisibility) => {
//     entries.forEach((entry) => {
//       if (entry.isIntersecting) {
//         setVisibility(true);
//         observer.unobserve(entry.target);
//       }
//     });
//   };

//   useEffect(() => {
//     const serenotyElement = document.querySelector("#serenoty");
//     const insightsElement = document.querySelector("#insights");
//     const globeElement = document.querySelector("#globe");

//     const options = {
//       root: null,
//       rootMargin: "0px",
//       threshold: 0.8,
//     };

//     const serenotyObserver = new IntersectionObserver((entries, observer) => {
//       handleIntersection(entries, observer, setIsSerenotyVisible);
//     }, options);

//     const insightsObserver = new IntersectionObserver((entries, observer) => {
//       handleIntersection(entries, observer, setIsInsightsVisible);
//     }, options);

//     const globeObserver = new IntersectionObserver((entries, observer) => {
//       handleIntersection(entries, observer, setIsGlobeVisible);
//     }, options);

//     serenotyElement && serenotyObserver.observe(serenotyElement);
//     insightsElement && insightsObserver.observe(insightsElement);
//     globeElement && globeObserver.observe(globeElement);

//     return () => {
//       serenotyElement && serenotyObserver.unobserve(serenotyElement);
//       insightsElement && insightsObserver.unobserve(insightsElement);
//       globeElement && globeObserver.unobserve(globeElement);
//     };
//   }, []);

//   useEffect(() => {
//     const handleResize = () => {
//       setGlobeScale(window.matchMedia("(max-width: 768px)").matches ? 0.8 : 1);
//     };

//     handleResize();
//     window.addEventListener("resize", handleResize);

//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, []);

//   useEffect(() => {
//     if (isGlobeVisible) {
//       let phi = 0;
//       const canvas = document.getElementById("cobe");

//       if (canvas) {
//         createGlobe(canvas, {
//           devicePixelRatio: 2,
//           width: 1000,
//           height: 1000,
//           phi: 1.5,
//           opacity: 0.5,
//           theta: 0,
//           dark: 0,
//           diffuse: 3.2,
//           scale: globeScale,
//           mapSamples: 16000,
//           mapBrightness: 4,
//           baseColor: [1, 1, 1],
//           markerColor: [244 / 255, 158 / 255, 128 / 255],
//           glowColor: [1, 1, 1],
//           offset: [0, 0],
//           markers: [
//             { location: [37.7595, -122.4367], size: 0.03 }, // San Francisco
//             { location: [40.7128, -74.006], size: 0.1 }, // New York
//             { location: [51.5074, -0.1278], size: 0.2 }, // London
//             { location: [35.6895, 139.6917], size: 0.08 }, // Tokyo
//             { location: [48.8566, 2.3522], size: 0.08 }, // Paris
//             { location: [55.7558, 37.6176], size: 0.08 }, // Moscow
//             { location: [-33.8688, 151.2093], size: 0.04 }, // Sydney
//             { location: [-22.9068, -43.1729], size: 0.05 }, // Rio de Janeiro
//             { location: [-33.4372, -70.6506], size: 0.04 }, // Santiago
//             { location: [-1.2864, 36.8172], size: 0.08 }, // Nairobi
//             { location: [-26.2041, 28.0473], size: 0.08 }, // Johannesburg
//             { location: [22.5726, 88.3639], size: 0.08 }, // Kolkata
//             { location: [19.076, 72.8777], size: 0.08 }, // Mumbai
//             { location: [-6.2088, 106.8456], size: 0.09 }, // Jakarta
//             { location: [34.0522, -118.2437], size: 0.05 }, // Los Angeles
//             { location: [41.8781, -87.6298], size: 0.07 }, // Chicago
//             { location: [39.9042, 116.4074], size: 0.07 }, // Beijing
//             { location: [-34.6037, -58.3816], size: 0.06 }, // Buenos Aires
//             { location: [40.4168, -3.7038], size: 0.07 }, // Madrid
//             { location: [37.5665, 126.978], size: 0.07 }, // Seoul
//             { location: [41.9028, 12.4964], size: 0.07 }, // Rome
//             { location: [39.9526, -75.1652], size: 0.06 }, // Philadelphia
//             { location: [55.9533, -3.1883], size: 0.07 }, // Edinburgh
//             { location: [28.6139, 77.209], size: 0.07 }, // New Delhi
//             { location: [31.2304, 121.4737], size: 0.07 }, // Shanghai
//             { location: [-37.8136, 144.9631], size: 0.06 }, // Melbourne
//             { location: [-34.397, 150.8931], size: 0.05 }, // Wollongong
//           ],
//           onRender: (state) => {
//             state.phi = phi;
//             phi += 0.009;
//           },
//         });
//       }
//     }
//   }, [isGlobeVisible, globeScale]);

//   return (
//     <div className="font-clashdisplay max-w-7xl mt-20 mx-auto px-4 sm:px-6 lg:px-8">
//       <div className="ban-container text-center mt-4"></div>

//       <div className="space-y-8">
//         <section id="serenoty" className="py-12 md:py-16 bg-purple">
//           <div className="flex justify-center">
//             <div className="border rounded-xl border-tt border-4 overflow-hidden">
//               <img src={serenoty} alt="Serenoty AI" className="object-cover" />
//             </div>
//           </div>
//         </section>

//         <section id="insights" className="py-8 md:py-16 flex flex-col lg:flex-row justify-between bg-[#f8f8f8] rounded-2xl overflow-hidden" style={{ height: "600px" }}>
//           <div className="lg:w-1/2 p-10 flex flex-col justify-center">
//             <h2 className="text-3xl leading-relaxed font-extrabold sm:text-4xl text-[#333]">
//               Say goodbye to endless browsing...
//             </h2>
//             <p className="mt-4 text-[#333] text-opacity-60 text-base md:text-lg leading-relaxed">
//               Elevate your writing with an AI assistant. Receive{" "}
//               <span className="bg-secondary text-tt px-2 py-1 rounded-full font-semibold">context-aware</span>{" "}
//               suggestions and annotations directly within your workspace.
//             </p>
//           </div>
//           <div className="lg:w-full relative overflow-hidden flex items-end pb-0" style={{ height: "600px" }}>
//             <img src={pic1} alt="Insightful content" className="object-cover w-full h-full rounded-md border border-lg" style={{ transform: "translate(60px, 30px)" }} />
//           </div>
//         </section>

//         <div className="flex flex-col md:flex-row items-stretch space-y-8 md:space-y-0 md:space-x-4">
//           <div className="w-full md:w-1/2 relative">
//             <section className="flex flex-col justify-between h-full bg-[#F6F5F4] border border-tt rounded-2xl overflow-hidden py-10 md:py-16 lg:py-20 mb-8 lg:mb-0">
//               <div className="p-10">
//                 <h3 className="text-3xl font-extrabold text-[#333]">Built for large documents</h3>
//                 <p className="py-2 text-[#333] text-opacity-60 text-base md:text-lg leading-relaxed">
//                   Easily manage and process PDFs up to 20,000 pages with uncompromised efficiency and speed.
//                 </p>
//               </div>
//               <div className="p-8 flex flex-col items-center justify-end text-[#e1e1e1] relative">
//                 <span className="z-10 absolute inset-0 flex items-center justify-center text-[3.5rem] sm:text-[4rem] font-semibold !tracking-[-0.3rem] !leading-[1]">20K Pages</span>
//                 <img className="inset-0 m-auto max-w-full max-h-full sm:max-w-xs md:max-w-sm lg:max-w-md" src={page} alt="20K Pages Feature" />
//               </div>
//               <div className="absolute left-0 bottom-0 h-4 w-full z-10"></div>
//             </section>
//           </div>

//           <div className="w-full md:w-1/2 relative">
//             <section id="globe" className="flex flex-col justify-between h-full bg-[#F6F5F4] border border-tt rounded-2xl overflow-hidden py-10 md:py-16 lg:py-20 mb-8 lg:mb-0">
//               <div className="p-10">
//                 <h2 className="text-3xl font-extrabold text-[#333]">
//                   Available{" "}
//                   <span className="text-3xl font-extrabold text-secondary px-2 py-1 rounded-full font-semibold">wherever</span>{" "}
//                   you are, in every major language
//                 </h2>
//                 <p className="py-2 text-[#333] text-opacity-60 text-base md:text-lg leading-relaxed">
//                   Serenoty supports over 100 languages, including French, Spanish, Arabic, Chinese, German, Japanese, and many more.
//                 </p>
//               </div>
//               <div className="flex justify-center relative">
//                 <canvas id="cobe" className="w-full md:w-2/3 lg:w-1/2" style={{ width: "500px", height: "500px" }} width="1000" height="1000"></canvas>
//               </div>
//             </section>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Banner;



// import React, { useState, useEffect } from "react";
// import pic1 from "../../assets/notephoto.png";
// import serenoty from "../../assets/serenotyphoto.png";
// import page from "../../assets/pages.svg";
// import createGlobe from "cobe";
// import "./banner.css";

// const Banner = () => {
//   const [isSerenotyVisible, setIsSerenotyVisible] = useState(false);
//   const [isInsightsVisible, setIsInsightsVisible] = useState(false);
//   const [isGlobeVisible, setIsGlobeVisible] = useState(false);
//   const [globeScale, setGlobeScale] = useState(1);

//   const handleIntersection = (entries, observer, setVisibility) => {
//     entries.forEach((entry) => {
//       if (entry.isIntersecting) {
//         setVisibility(true);
//         observer.unobserve(entry.target);
//       }
//     });
//   };

//   useEffect(() => {
//     const serenotyElement = document.querySelector("#serenoty");
//     const insightsElement = document.querySelector("#insights");
//     const globeElement = document.querySelector("#globe");

//     const options = {
//       root: null,
//       rootMargin: "0px",
//       threshold: 0.8,
//     };

//     const serenotyObserver = new IntersectionObserver((entries, observer) => {
//       handleIntersection(entries, observer, setIsSerenotyVisible);
//     }, options);

//     const insightsObserver = new IntersectionObserver((entries, observer) => {
//       handleIntersection(entries, observer, setIsInsightsVisible);
//     }, options);

//     const globeObserver = new IntersectionObserver((entries, observer) => {
//       handleIntersection(entries, observer, setIsGlobeVisible);
//     }, options);

//     serenotyElement && serenotyObserver.observe(serenotyElement);
//     insightsElement && insightsObserver.observe(insightsElement);
//     globeElement && globeObserver.observe(globeElement);

//     return () => {
//       serenotyElement && serenotyObserver.unobserve(serenotyElement);
//       insightsElement && insightsObserver.unobserve(insightsElement);
//       globeElement && globeObserver.unobserve(globeElement);
//     };
//   }, []);

//   useEffect(() => {
//     const handleResize = () => {
//       setGlobeScale(window.matchMedia("(max-width: 768px)").matches ? 0.8 : 1);
//     };

//     handleResize();
//     window.addEventListener("resize", handleResize);

//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, []);

//   useEffect(() => {
//     if (isGlobeVisible) {
//       let phi = 0;
//       const canvas = document.getElementById("cobe");

//       if (canvas) {
//         createGlobe(canvas, {
//           devicePixelRatio: 2,
//           width: 1000,
//           height: 1000,
//           phi: 1.5,
//           opacity: 0.5,
//           theta: 0,
//           dark: 0,
//           diffuse: 3.2,
//           scale: globeScale,
//           mapSamples: 16000,
//           mapBrightness: 4,
//           baseColor: [1, 1, 1],
//           markerColor: [244 / 255, 158 / 255, 128 / 255],
//           glowColor: [1, 1, 1],
//           offset: [0, 0],
//           markers: [
//             { location: [37.7595, -122.4367], size: 0.03 }, // San Francisco
//             { location: [40.7128, -74.006], size: 0.1 }, // New York
//             { location: [51.5074, -0.1278], size: 0.2 }, // London
//             { location: [35.6895, 139.6917], size: 0.08 }, // Tokyo
//             { location: [48.8566, 2.3522], size: 0.08 }, // Paris
//             { location: [55.7558, 37.6176], size: 0.08 }, // Moscow
//             { location: [-33.8688, 151.2093], size: 0.04 }, // Sydney
//             { location: [-22.9068, -43.1729], size: 0.05 }, // Rio de Janeiro
//             { location: [-33.4372, -70.6506], size: 0.04 }, // Santiago
//             { location: [-1.2864, 36.8172], size: 0.08 }, // Nairobi
//             { location: [-26.2041, 28.0473], size: 0.08 }, // Johannesburg
//             { location: [22.5726, 88.3639], size: 0.08 }, // Kolkata
//             { location: [19.076, 72.8777], size: 0.08 }, // Mumbai
//             { location: [-6.2088, 106.8456], size: 0.09 }, // Jakarta
//             { location: [34.0522, -118.2437], size: 0.05 }, // Los Angeles
//             { location: [41.8781, -87.6298], size: 0.07 }, // Chicago
//             { location: [39.9042, 116.4074], size: 0.07 }, // Beijing
//             { location: [-34.6037, -58.3816], size: 0.06 }, // Buenos Aires
//             { location: [40.4168, -3.7038], size: 0.07 }, // Madrid
//             { location: [37.5665, 126.978], size: 0.07 }, // Seoul
//             { location: [41.9028, 12.4964], size: 0.07 }, // Rome
//             { location: [39.9526, -75.1652], size: 0.06 }, // Philadelphia
//             { location: [55.9533, -3.1883], size: 0.07 }, // Edinburgh
//             { location: [28.6139, 77.209], size: 0.07 }, // New Delhi
//             { location: [31.2304, 121.4737], size: 0.07 }, // Shanghai
//             { location: [-37.8136, 144.9631], size: 0.06 }, // Melbourne
//             { location: [-34.397, 150.8931], size: 0.05 }, // Wollongong
//           ],
//           onRender: (state) => {
//             state.phi = phi;
//             phi += 0.009;
//           },
//         });
//       }
//     }
//   }, [isGlobeVisible, globeScale]);

//   return (
//     <div className="font-clashdisplay max-w-7xl mt-20 mx-auto px-4 sm:px-6 lg:px-8">
//       <div className="ban-container text-center mt-4"></div>

//       <div className="space-y-8">
//         <section id="serenoty" className="py-12 md:py-16 bg-purple">
//           <div className="flex justify-center">
//             <div className="border rounded-xl border-tt border-4 overflow-hidden">
//               <img src={serenoty} alt="Serenoty AI" className="object-cover" />
//             </div>
//           </div>
//         </section>

//         <section id="insights" className="py-8 md:py-16 flex flex-col lg:flex-row justify-between bg-[#f8f8f8] rounded-2xl overflow-hidden" style={{ height: "600px" }}>
//           <div className="lg:w-1/2 p-10 flex flex-col justify-center">
//             <h2 className="text-3xl leading-relaxed font-extrabold sm:text-4xl text-[#333]">
//               Say goodbye to endless browsing...
//             </h2>
//             <p className="mt-4 text-[#333] text-opacity-60 text-base md:text-lg leading-relaxed">
//               Elevate your writing with an AI assistant. Receive{" "}
//               <span className="bg-secondary text-tt px-2 py-1 rounded-full font-semibold">context-aware</span>{" "}
//               suggestions and annotations directly within your workspace.
//             </p>
//           </div>
//           <div className="lg:w-full relative overflow-hidden flex items-end pb-0" style={{ height: "600px" }}>
//             <img src={pic1} alt="Insightful content" className="object-cover w-full h-full rounded-md border border-lg" style={{ transform: "translate(60px, 30px)" }} />
//           </div>
//         </section>

//         <div className="flex flex-col md:flex-row items-stretch space-y-8 md:space-y-0 md:space-x-4">
//           <div className="w-full md:w-1/2 relative">
//             <section className="flex flex-col justify-between h-full bg-[#F6F5F4] border border-tt rounded-2xl overflow-hidden py-10 md:py-16 lg:py-20 mb-8 lg:mb-0">
//               <div className="p-10">
//                 <h3 className="text-3xl font-extrabold text-[#333]">Built for large documents</h3>
//                 <p className="py-2 text-[#333] text-opacity-60 text-base md:text-lg leading-relaxed">
//                   Easily manage and process PDFs up to 20,000 pages with uncompromised efficiency and speed.
//                 </p>
//               </div>
//               <div className="p-8 flex flex-col items-center justify-end text-[#e1e1e1] relative">
//                 <span className="z-10 absolute inset-0 flex items-center justify-center text-[3.5rem] sm:text-[4rem] font-semibold !tracking-[-0.3rem] !leading-[1]">20K Pages</span>
//                 <img className="inset-0 m-auto max-w-full max-h-full sm:max-w-xs md:max-w-sm lg:max-w-md" src={page} alt="20K Pages Feature" />
//               </div>
//               <div className="absolute left-0 bottom-0 h-4 w-full z-10"></div>
//             </section>
//           </div>

//           <div className="w-full md:w-1/2 relative">
//             <section id="globe" className="flex flex-col justify-between h-full bg-[#F6F5F4] border border-tt rounded-2xl overflow-hidden py-10 md:py-16 lg:py-20 mb-8 lg:mb-0">
//               <div className="p-10">
//                 <h2 className="text-3xl font-extrabold text-[#333]">
//                   Available{" "}
//                   <span className="text-3xl font-extrabold text-secondary px-2 py-1 rounded-full font-semibold">wherever</span>{" "}
//                   you are, in every major language
//                 </h2>
//                 <p className="py-2 text-[#333] text-opacity-60 text-base md:text-lg leading-relaxed">
//                   Serenoty supports over 100 languages, including French, Spanish, Arabic, Chinese, German, Japanese, and many more.
//                 </p>
//               </div>
//               <div className="flex justify-center relative">
//                 <canvas id="cobe" className="w-full md:w-2/3 lg:w-1/2" style={{ width: "500px", height: "500px" }} width="1000" height="1000"></canvas>
//               </div>
//             </section>
//           </div>
//         </div>

//         <div className="flex flex-col md:flex-row items-stretch space-y-8 md:space-y-0 md:space-x-4 mt-8">
//           <div className="w-full md:w-1/4 relative">
//             <div className="bg-[#E6F7F9] border border-tt rounded-2xl overflow-hidden p-6 text-center">
//               <div className="mb-4">
//                 <img src="/path/to/icon1.png" alt="Icon 1" className="mx-auto" />
//               </div>
//               <h3 className="text-xl font-semibold text-[#333]">Corporate, Pharma & Enterprise</h3>
//               <p className="mt-2 text-[#333] text-opacity-60">Stay on top of drug development & efficacy, clinical trials, and more</p>
//             </div>
//           </div>
//           <div className="w-full md:w-1/4 relative">
//             <div className="bg-[#F7E6F9] border border-tt rounded-2xl overflow-hidden p-6 text-center">
//               <div className="mb-4">
//                 <img src="/path/to/icon2.png" alt="Icon 2" className="mx-auto" />
//               </div>
//               <h3 className="text-xl font-semibold text-[#333]">Universities & Governments</h3>
//               <p className="mt-2 text-[#333] text-opacity-60">Track and improve research outcomes across your organization</p>
//             </div>
//           </div>
//           <div className="w-full md:w-1/4 relative">
//             <div className="bg-[#F9E6F7] border border-tt rounded-2xl overflow-hidden p-6 text-center">
//               <div className="mb-4">
//                 <img src="/path/to/icon3.png" alt="Icon 3" className="mx-auto" />
//               </div>
//               <h3 className="text-xl font-semibold text-[#333]">Researchers</h3>
//               <p className="mt-2 text-[#333] text-opacity-60">See exactly how publications have been cited by newer research</p>
//             </div>
//           </div>
//           <div className="w-full md:w-1/4 relative">
//             <div className="bg-[#F9EDE6] border border-tt rounded-2xl overflow-hidden p-6 text-center">
//               <div className="mb-4">
//                 <img src="/path/to/icon4.png" alt="Icon 4" className="mx-auto" />
//               </div>
//               <h3 className="text-xl font-semibold text-[#333]">Publishers</h3>
//               <p className="mt-2 text-[#333] text-opacity-60">Improve discoverability of your publications and support your authors</p>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Banner;


// import React, { useState, useEffect } from "react";
// import { Syringe, Briefcase, Microscope, BookOpenCheck } from "lucide-react";
// import pic1 from "../../assets/notephoto.png";
// import serenoty from "../../assets/snlogo2.png";
// import page from "../../assets/pages.svg";
// import createGlobe from "cobe";
// import "./banner.css";

// const Banner = () => {
//   const [isSerenotyVisible, setIsSerenotyVisible] = useState(false);
//   const [isInsightsVisible, setIsInsightsVisible] = useState(false);
//   const [isGlobeVisible, setIsGlobeVisible] = useState(false);
//   const [globeScale, setGlobeScale] = useState(1);

//   const handleIntersection = (entries, observer, setVisibility) => {
//     entries.forEach((entry) => {
//       if (entry.isIntersecting) {
//         setVisibility(true);
//         observer.unobserve(entry.target);
//       }
//     });
//   };

//   useEffect(() => {
//     const serenotyElement = document.querySelector("#serenoty");
//     const insightsElement = document.querySelector("#insights");
//     const globeElement = document.querySelector("#globe");

//     const options = {
//       root: null,
//       rootMargin: "0px",
//       threshold: 0.8,
//     };

//     const serenotyObserver = new IntersectionObserver((entries, observer) => {
//       handleIntersection(entries, observer, setIsSerenotyVisible);
//     }, options);

//     const insightsObserver = new IntersectionObserver((entries, observer) => {
//       handleIntersection(entries, observer, setIsInsightsVisible);
//     }, options);

//     const globeObserver = new IntersectionObserver((entries, observer) => {
//       handleIntersection(entries, observer, setIsGlobeVisible);
//     }, options);

//     serenotyElement && serenotyObserver.observe(serenotyElement);
//     insightsElement && insightsObserver.observe(insightsElement);
//     globeElement && globeObserver.observe(globeElement);

//     return () => {
//       serenotyElement && serenotyObserver.unobserve(serenotyElement);
//       insightsElement && insightsObserver.unobserve(insightsElement);
//       globeElement && globeObserver.unobserve(globeElement);
//     };
//   }, []);

//   useEffect(() => {
//     const handleResize = () => {
//       setGlobeScale(window.matchMedia("(max-width: 768px)").matches ? 0.8 : 1);
//     };

//     handleResize();
//     window.addEventListener("resize", handleResize);

//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, []);

//   useEffect(() => {
//     if (isGlobeVisible) {
//       let phi = 0;
//       const canvas = document.getElementById("cobe");

//       if (canvas) {
//         createGlobe(canvas, {
//           devicePixelRatio: 2,
//           width: 1000,
//           height: 1000,
//           phi: 1.5,
//           opacity: 0.5,
//           theta: 0,
//           dark: 0,
//           diffuse: 3.2,
//           scale: globeScale,
//           mapSamples: 16000,
//           mapBrightness: 4,
//           baseColor: [1, 1, 1],
//           markerColor: [244 / 255, 158 / 255, 128 / 255],
//           glowColor: [1, 1, 1],
//           offset: [0, 0],
//           markers: [
//             { location: [37.7595, -122.4367], size: 0.03 }, // San Francisco
//             { location: [40.7128, -74.006], size: 0.1 }, // New York
//             { location: [51.5074, -0.1278], size: 0.2 }, // London
//             { location: [35.6895, 139.6917], size: 0.08 }, // Tokyo
//             { location: [48.8566, 2.3522], size: 0.08 }, // Paris
//             { location: [55.7558, 37.6176], size: 0.08 }, // Moscow
//             { location: [-33.8688, 151.2093], size: 0.04 }, // Sydney
//             { location: [-22.9068, -43.1729], size: 0.05 }, // Rio de Janeiro
//             { location: [-33.4372, -70.6506], size: 0.04 }, // Santiago
//             { location: [-1.2864, 36.8172], size: 0.08 }, // Nairobi
//             { location: [-26.2041, 28.0473], size: 0.08 }, // Johannesburg
//             { location: [22.5726, 88.3639], size: 0.08 }, // Kolkata
//             { location: [19.076, 72.8777], size: 0.08 }, // Mumbai
//             { location: [-6.2088, 106.8456], size: 0.09 }, // Jakarta
//             { location: [34.0522, -118.2437], size: 0.05 }, // Los Angeles
//             { location: [41.8781, -87.6298], size: 0.07 }, // Chicago
//             { location: [39.9042, 116.4074], size: 0.07 }, // Beijing
//             { location: [-34.6037, -58.3816], size: 0.06 }, // Buenos Aires
//             { location: [40.4168, -3.7038], size: 0.07 }, // Madrid
//             { location: [37.5665, 126.978], size: 0.07 }, // Seoul
//             { location: [41.9028, 12.4964], size: 0.07 }, // Rome
//             { location: [39.9526, -75.1652], size: 0.06 }, // Philadelphia
//             { location: [55.9533, -3.1883], size: 0.07 }, // Edinburgh
//             { location: [28.6139, 77.209], size: 0.07 }, // New Delhi
//             { location: [31.2304, 121.4737], size: 0.07 }, // Shanghai
//             { location: [-37.8136, 144.9631], size: 0.06 }, // Melbourne
//             { location: [-34.397, 150.8931], size: 0.05 }, // Wollongong
//           ],
//           onRender: (state) => {
//             state.phi = phi;
//             phi += 0.009;
//           },
//         });
//       }
//     }
//   }, [isGlobeVisible, globeScale]);

//   return (
//     <div className="font-clashdisplay max-w-7xl mt-20 mx-auto px-4 sm:px-6 lg:px-8">
//       <div className="ban-container text-center mt-4"></div>

//       <div className="space-y-8">
//         <section id="serenoty" className="py-12 md:py-16 bg-purple">
//           <div className="flex justify-center">
//             <div className="border rounded-xl border-tt border-4 overflow-hidden">
//               <img src={serenoty} alt="Serenoty AI" className="object-cover" />
//             </div>
//           </div>
//         </section>

//         <section id="insights" className="py-8 md:py-16 flex flex-col lg:flex-row justify-between bg-[#f8f8f8] rounded-2xl overflow-hidden" style={{ height: "600px" }}>
//           <div className="lg:w-1/2 p-10 flex flex-col justify-center">
//             <h2 className="text-3xl leading-relaxed font-extrabold sm:text-4xl text-[#333]">
//               Say goodbye to endless browsing...
//             </h2>
//             <p className="mt-4 text-[#333] text-opacity-60 text-base md:text-lg leading-relaxed">
//               Elevate your writing with an AI assistant. Receive{" "}
//               <span className="bg-secondary text-tt px-2 py-1 rounded-full font-semibold">context-aware</span>{" "}
//               suggestions and annotations directly within your workspace.
//             </p>
//           </div>
//           <div className="lg:w-full relative overflow-hidden flex items-end pb-0" style={{ height: "600px" }}>
//             <img src={pic1} alt="Insightful content" className="object-cover w-full h-full rounded-md border border-lg" style={{ transform: "translate(60px, 30px)" }} />
//           </div>
//         </section>

//         <div className="flex flex-col md:flex-row items-stretch space-y-8 md:space-y-0 md:space-x-4">
//           <div className="w-full md:w-1/2 relative">
//             <section className="flex flex-col justify-between h-full bg-[#F6F5F4] border border-tt rounded-2xl overflow-hidden py-10 md:py-16 lg:py-20 mb-8 lg:mb-0">
//               <div className="p-10">
//                 <h3 className="text-3xl font-extrabold text-[#333]">Built for large documents</h3>
//                 <p className="py-2 text-[#333] text-opacity-60 text-base md:text-lg leading-relaxed">
//                   Easily manage and process PDFs up to 20,000 pages with uncompromised efficiency and speed.
//                 </p>
//               </div>
//               <div className="p-8 flex flex-col items-center justify-end text-[#e1e1e1] relative">
//                 <span className="z-10 absolute inset-0 flex items-center justify-center text-[3.5rem] sm:text-[4rem] font-semibold !tracking-[-0.3rem] !leading-[1]">20K Pages</span>
//                 <img className="inset-0 m-auto max-w-full max-h-full sm:max-w-xs md:max-w-sm lg:max-w-md" src={page} alt="20K Pages Feature" />
//               </div>
//               <div className="absolute left-0 bottom-0 h-4 w-full z-10"></div>
//             </section>
//           </div>

//           <div className="w-full md:w-1/2 relative">
//             <section id="globe" className="flex flex-col justify-between h-full bg-[#F6F5F4] border border-tt rounded-2xl overflow-hidden py-10 md:py-16 lg:py-20 mb-8 lg:mb-0">
//               <div className="p-10">
//                 <h2 className="text-3xl font-extrabold text-[#333]">
//                   Available{" "}
//                   <span className="text-3xl font-extrabold text-secondary px-2 py-1 rounded-full font-semibold">wherever</span>{" "}
//                   you are, in every major language
//                 </h2>
//                 <p className="py-2 text-[#333] text-opacity-60 text-base md:text-lg leading-relaxed">
//                   Serenoty supports over 100 languages, including French, Spanish, Arabic, Chinese, German, Japanese, and many more.
//                 </p>
//               </div>
//               <div className="flex justify-center relative">
//                 <canvas id="cobe" className="w-full md:w-2/3 lg:w-1/2" style={{ width: "500px", height: "500px" }} width="1000" height="1000"></canvas>
//               </div>
//             </section>
//           </div>
//         </div>

       
//       </div>
//     </div>
//   );
// };

// export default Banner;


// import React, { useState, useEffect } from "react";
// import { Syringe, Briefcase, Microscope, BookOpenCheck } from "lucide-react";
// import pic1 from "../../assets/notephoto.png";
// import serenoty from "../../assets/snlogo2.png";
// import page from "../../assets/pages.svg";
// import createGlobe from "cobe";
// import "./banner.css";

// const Banner = () => {
//   const [isSerenotyVisible, setIsSerenotyVisible] = useState(false);
//   const [isInsightsVisible, setIsInsightsVisible] = useState(false);
//   const [isGlobeVisible, setIsGlobeVisible] = useState(false);
//   const [globeScale, setGlobeScale] = useState(1);

//   const handleIntersection = (entries, observer, setVisibility) => {
//     entries.forEach((entry) => {
//       if (entry.isIntersecting) {
//         setVisibility(true);
//         observer.unobserve(entry.target);
//       }
//     });
//   };

//   useEffect(() => {
//     const serenotyElement = document.querySelector("#serenoty");
//     const insightsElement = document.querySelector("#insights");
//     const globeElement = document.querySelector("#globe");

//     const options = {
//       root: null,
//       rootMargin: "0px",
//       threshold: 0.8,
//     };

//     const serenotyObserver = new IntersectionObserver((entries, observer) => {
//       handleIntersection(entries, observer, setIsSerenotyVisible);
//     }, options);

//     const insightsObserver = new IntersectionObserver((entries, observer) => {
//       handleIntersection(entries, observer, setIsInsightsVisible);
//     }, options);

//     const globeObserver = new IntersectionObserver((entries, observer) => {
//       handleIntersection(entries, observer, setIsGlobeVisible);
//     }, options);

//     serenotyElement && serenotyObserver.observe(serenotyElement);
//     insightsElement && insightsObserver.observe(insightsElement);
//     globeElement && globeObserver.observe(globeElement);

//     return () => {
//       serenotyElement && serenotyObserver.unobserve(serenotyElement);
//       insightsElement && insightsObserver.unobserve(insightsElement);
//       globeElement && globeObserver.unobserve(globeElement);
//     };
//   }, []);

//   useEffect(() => {
//     const handleResize = () => {
//       setGlobeScale(window.matchMedia("(max-width: 768px)").matches ? 0.8 : 1);
//     };

//     handleResize();
//     window.addEventListener("resize", handleResize);

//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, []);

//   useEffect(() => {
//     if (isGlobeVisible) {
//       let phi = 0;
//       const canvas = document.getElementById("cobe");

//       if (canvas) {
//         createGlobe(canvas, {
//           devicePixelRatio: 2,
//           width: 1000,
//           height: 1000,
//           phi: 1.5,
//           opacity: 0.5,
//           theta: 0,
//           dark: 0,
//           diffuse: 3.2,
//           scale: globeScale,
//           mapSamples: 16000,
//           mapBrightness: 4,
//           baseColor: [1, 1, 1],
//           markerColor: [244 / 255, 158 / 255, 128 / 255],
//           glowColor: [1, 1, 1],
//           offset: [0, 0],
//           markers: [
//             { location: [37.7595, -122.4367], size: 0.03 }, // San Francisco
//             { location: [40.7128, -74.006], size: 0.1 }, // New York
//             { location: [51.5074, -0.1278], size: 0.2 }, // London
//             { location: [35.6895, 139.6917], size: 0.08 }, // Tokyo
//             { location: [48.8566, 2.3522], size: 0.08 }, // Paris
//             { location: [55.7558, 37.6176], size: 0.08 }, // Moscow
//             { location: [-33.8688, 151.2093], size: 0.04 }, // Sydney
//             { location: [-22.9068, -43.1729], size: 0.05 }, // Rio de Janeiro
//             { location: [-33.4372, -70.6506], size: 0.04 }, // Santiago
//             { location: [-1.2864, 36.8172], size: 0.08 }, // Nairobi
//             { location: [-26.2041, 28.0473], size: 0.08 }, // Johannesburg
//             { location: [22.5726, 88.3639], size: 0.08 }, // Kolkata
//             { location: [19.076, 72.8777], size: 0.08 }, // Mumbai
//             { location: [-6.2088, 106.8456], size: 0.09 }, // Jakarta
//             { location: [34.0522, -118.2437], size: 0.05 }, // Los Angeles
//             { location: [41.8781, -87.6298], size: 0.07 }, // Chicago
//             { location: [39.9042, 116.4074], size: 0.07 }, // Beijing
//             { location: [-34.6037, -58.3816], size: 0.06 }, // Buenos Aires
//             { location: [40.4168, -3.7038], size: 0.07 }, // Madrid
//             { location: [37.5665, 126.978], size: 0.07 }, // Seoul
//             { location: [41.9028, 12.4964], size: 0.07 }, // Rome
//             { location: [39.9526, -75.1652], size: 0.06 }, // Philadelphia
//             { location: [55.9533, -3.1883], size: 0.07 }, // Edinburgh
//             { location: [28.6139, 77.209], size: 0.07 }, // New Delhi
//             { location: [31.2304, 121.4737], size: 0.07 }, // Shanghai
//             { location: [-37.8136, 144.9631], size: 0.06 }, // Melbourne
//             { location: [-34.397, 150.8931], size: 0.05 }, // Wollongong
//           ],
//           onRender: (state) => {
//             state.phi = phi;
//             phi += 0.009;
//           },
//         });
//       }
//     }
//   }, [isGlobeVisible, globeScale]);

//   return (
//     <div className="font-clashdisplay max-w-7xl mt-20 mx-auto px-4 sm:px-6 lg:px-8">
//       <div className="ban-container text-center mt-4"></div>

//       <div className="space-y-8">
//         <section id="serenoty" className="py-12 md:py-16 bg-purple">
//           <div className="flex justify-center">
//             <div className="border rounded-xl border-tt border-4 overflow-hidden">
//               <img src={serenoty} alt="Serenoty AI" className="object-cover" />
//             </div>
//           </div>
//         </section>

//         <section id="insights" className="py-8 md:py-16 flex flex-col lg:flex-row justify-between bg-[#f8f8f8] rounded-2xl overflow-hidden" style={{ height: "600px" }}>
//           <div className="lg:w-1/2 p-10 flex flex-col justify-center">
//             <h2 className="text-3xl leading-relaxed font-extrabold sm:text-4xl text-[#333]">
//               Say goodbye to endless browsing...
//             </h2>
//             <p className="mt-4 text-[#333] text-opacity-60 text-base md:text-lg leading-relaxed">
//               Elevate your writing with an AI assistant. Receive{" "}
//               <span className="bg-secondary text-tt px-2 py-1 rounded-full font-semibold">context-aware</span>{" "}
//               suggestions and annotations directly within your workspace.
//             </p>
//           </div>
//           <div className="lg:w-full relative overflow-hidden flex items-end pb-0" style={{ height: "600px" }}>
//             <img src={pic1} alt="Insightful content" className="object-cover w-full h-full rounded-md border border-lg" style={{ transform: "translate(60px, 30px)" }} />
//           </div>
//         </section>

//         <div className="flex flex-col md:flex-row items-stretch space-y-8 md:space-y-0 md:space-x-4">
//           <div className="w-full md:w-1/2 relative">
//             <section className="flex flex-col justify-between h-full bg-[#F6F5F4] border border-tt rounded-2xl overflow-hidden py-10 md:py-16 lg:py-20 mb-8 lg:mb-0">
//               <div className="p-10">
//                 <h3 className="text-3xl font-extrabold text-[#333]">Access to 200+ million research papers</h3>
//                 <p className="py-2 text-[#333] text-opacity-60 text-base md:text-lg leading-relaxed">
//                   Dive into a vast repository of over 200 million research papers, articles, and academic documents, making your research thorough and efficient.
//                 </p>
//               </div>
//               <div className="p-8 flex flex-col items-center justify-end text-[#e1e1e1] relative">
//                 <span className="z-10 absolute inset-0 flex items-center justify-center text-[3.5rem] sm:text-[4rem] font-semibold !tracking-[-0.3rem] !leading-[1]">200M+ Papers</span>
//                 <img className="inset-0 m-auto max-w-full max-h-full sm:max-w-xs md:max-w-sm lg:max-w-md" src={page} alt="200 Million Papers" />
//               </div>
//               <div className="absolute left-0 bottom-0 h-4 w-full z-10"></div>
//             </section>
//           </div>

//           <div className="w-full md:w-1/2 relative">
//             <section id="globe" className="flex flex-col justify-between h-full bg-[#F6F5F4] border border-tt rounded-2xl overflow-hidden py-10 md:py-16 lg:py-20 mb-8 lg:mb-0">
//               <div className="p-10">
//                 <h2 className="text-3xl font-extrabold text-[#333]">
//                   Available{" "}
//                   <span className="text-3xl font-extrabold text-secondary px-2 py-1 rounded-full font-semibold">wherever</span>{" "}
//                   you are, in every major language
//                 </h2>
//                 <p className="py-2 text-[#333] text-opacity-60 text-base md:text-lg leading-relaxed">
//                   Serenoty supports over 100 languages, including French, Spanish, Arabic, Chinese, German, Japanese, and many more.
//                 </p>
//               </div>
//               <div className="flex justify-center relative">
//                 <canvas id="cobe" className="w-full md:w-2/3 lg:w-1/2" style={{ width: "500px", height: "500px" }} width="1000" height="1000"></canvas>
//               </div>
//             </section>
//           </div>
//         </div>

       
//       </div>
//     </div>
//   );
// };

// export default Banner;


import React, { useState, useEffect } from "react";
import { Syringe, Briefcase, Microscope, BookOpenCheck } from "lucide-react";
import pic1 from "../../assets/notephoto.png";
import serenoty from "../../assets/sn.png";
import page from "../../assets/pages.svg";
import createGlobe from "cobe";
import "./banner.css";

const Banner = () => {
  const [isSerenotyVisible, setIsSerenotyVisible] = useState(false);
  const [isInsightsVisible, setIsInsightsVisible] = useState(false);
  const [isGlobeVisible, setIsGlobeVisible] = useState(false);
  const [globeScale, setGlobeScale] = useState(1);

  const handleIntersection = (entries, observer, setVisibility) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        setVisibility(true);
        observer.unobserve(entry.target);
      }
    });
  };

  useEffect(() => {
    const serenotyElement = document.querySelector("#serenoty");
    const insightsElement = document.querySelector("#insights");
    const globeElement = document.querySelector("#globe");

    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.8,
    };

    const serenotyObserver = new IntersectionObserver((entries, observer) => {
      handleIntersection(entries, observer, setIsSerenotyVisible);
    }, options);

    const insightsObserver = new IntersectionObserver((entries, observer) => {
      handleIntersection(entries, observer, setIsInsightsVisible);
    }, options);

    const globeObserver = new IntersectionObserver((entries, observer) => {
      handleIntersection(entries, observer, setIsGlobeVisible);
    }, options);

    serenotyElement && serenotyObserver.observe(serenotyElement);
    insightsElement && insightsObserver.observe(insightsElement);
    globeElement && globeObserver.observe(globeElement);

    return () => {
      serenotyElement && serenotyObserver.unobserve(serenotyElement);
      insightsElement && insightsObserver.unobserve(insightsElement);
      globeElement && globeObserver.unobserve(globeElement);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setGlobeScale(window.matchMedia("(max-width: 768px)").matches ? 0.8 : 1);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (isGlobeVisible) {
      let phi = 0;
      const canvas = document.getElementById("cobe");

      if (canvas) {
        createGlobe(canvas, {
          devicePixelRatio: 2,
          width: 1000,
          height: 1000,
          phi: 1.5,
          opacity: 0.5,
          theta: 0,
          dark: 0,
          diffuse: 3.2,
          scale: globeScale,
          mapSamples: 16000,
          mapBrightness: 4,
          baseColor: [1, 1, 1],
          markerColor: [244 / 255, 158 / 255, 128 / 255],
          glowColor: [1, 1, 1],
          offset: [0, 0],
          markers: [
            { location: [37.7595, -122.4367], size: 0.03 }, // San Francisco
            { location: [40.7128, -74.006], size: 0.1 }, // New York
            { location: [51.5074, -0.1278], size: 0.2 }, // London
            { location: [35.6895, 139.6917], size: 0.08 }, // Tokyo
            { location: [48.8566, 2.3522], size: 0.08 }, // Paris
            { location: [55.7558, 37.6176], size: 0.08 }, // Moscow
            { location: [-33.8688, 151.2093], size: 0.04 }, // Sydney
            { location: [-22.9068, -43.1729], size: 0.05 }, // Rio de Janeiro
            { location: [-33.4372, -70.6506], size: 0.04 }, // Santiago
            { location: [-1.2864, 36.8172], size: 0.08 }, // Nairobi
            { location: [-26.2041, 28.0473], size: 0.08 }, // Johannesburg
            { location: [22.5726, 88.3639], size: 0.08 }, // Kolkata
            { location: [19.076, 72.8777], size: 0.08 }, // Mumbai
            { location: [-6.2088, 106.8456], size: 0.09 }, // Jakarta
            { location: [34.0522, -118.2437], size: 0.05 }, // Los Angeles
            { location: [41.8781, -87.6298], size: 0.07 }, // Chicago
            { location: [39.9042, 116.4074], size: 0.07 }, // Beijing
            { location: [-34.6037, -58.3816], size: 0.06 }, // Buenos Aires
            { location: [40.4168, -3.7038], size: 0.07 }, // Madrid
            { location: [37.5665, 126.978], size: 0.07 }, // Seoul
            { location: [41.9028, 12.4964], size: 0.07 }, // Rome
            { location: [39.9526, -75.1652], size: 0.06 }, // Philadelphia
            { location: [55.9533, -3.1883], size: 0.07 }, // Edinburgh
            { location: [28.6139, 77.209], size: 0.07 }, // New Delhi
            { location: [31.2304, 121.4737], size: 0.07 }, // Shanghai
            { location: [-37.8136, 144.9631], size: 0.06 }, // Melbourne
            { location: [-34.397, 150.8931], size: 0.05 }, // Wollongong
          ],
          onRender: (state) => {
            state.phi = phi;
            phi += 0.009;
          },
        });
      }
    }
  }, [isGlobeVisible, globeScale]);

  return (
    <div className="font-clashdisplay max-w-7xl mt-20 mx-auto px-4 sm:px-6 lg:px-8">
      <div className="ban-container text-center mt-4"></div>

      <div className="space-y-8">
        <section id="serenoty" className="py-12 md:py-16 bg-purple">
          <div className="flex justify-center">
            <div className="border rounded-xl border-tt border-4 overflow-hidden">
              <img src={serenoty} alt="Serenoty AI" className="object-cover" />
            </div>
          </div>
        </section>

        <section id="insights" className="py-8 md:py-16 flex flex-col lg:flex-row justify-between bg-[#f8f8f8] rounded-2xl overflow-hidden" style={{ height: "600px" }}>
          <div className="lg:w-1/2 p-10 flex flex-col justify-center">
            <h2 className="text-3xl leading-relaxed font-extrabold sm:text-4xl text-[#333]">
              Say goodbye to endless browsing...
            </h2>
            <p className="mt-4 text-[#333] text-opacity-60 text-base md:text-lg leading-relaxed">
              Elevate your writing with an AI assistant. Receive{" "}
              <span className="bg-secondary text-tt px-2 py-1 rounded-full font-semibold">context-aware</span>{" "}
              suggestions and annotations directly within your workspace.
            </p>
          </div>
          <div className="lg:w-full relative overflow-hidden flex items-end pb-0" style={{ height: "600px" }}>
            <img src={pic1} alt="Insightful content" className="object-cover w-full h-full rounded-md border border-lg" style={{ transform: "translate(60px, 30px)" }} />
          </div>
        </section>

        <div className="flex flex-col md:flex-row items-stretch space-y-8 md:space-y-0 md:space-x-4">
          <div className="w-full md:w-1/2 relative">
            <section className="flex flex-col justify-between h-full bg-[#F6F5F4] border border-tt rounded-2xl overflow-hidden py-10 md:py-16 lg:py-20 mb-8 lg:mb-0">
              <div className="p-10">
                <h3 className="text-3xl font-extrabold text-[#333]">Access to 200+ million research papers</h3>
                <p className="py-2 text-[#333] text-opacity-60 text-base md:text-lg leading-relaxed">
                  Dive into a vast repository of over 200 million research papers, articles, and academic documents, making your research thorough and efficient.
                </p>
              </div>
              <div className="p-8 flex flex-col items-center justify-end text-[#e1e1e1] relative">
                <span className="z-10 absolute inset-0 flex items-center justify-center text-[2.5rem] sm:text-[3.5rem] md:text-[4rem] font-semibold !tracking-[-0.3rem] !leading-[1]">200M+ Papers</span>
                <img className="inset-0 m-auto max-w-full max-h-full sm:max-w-xs md:max-w-sm lg:max-w-md" src={page} alt="200 Million Papers" />
              </div>
              <div className="absolute left-0 bottom-0 h-4 w-full z-10"></div>
            </section>
          </div>

          <div className="w-full md:w-1/2 relative">
            <section id="globe" className="flex flex-col justify-between h-full bg-[#F6F5F4] border border-tt rounded-2xl overflow-hidden py-10 md:py-16 lg:py-20 mb-8 lg:mb-0">
              <div className="p-10">
                <h2 className="text-3xl font-extrabold text-[#333]">
                  Available{" "}
                  <span className="text-3xl font-extrabold text-secondary px-2 py-1 rounded-full font-semibold">wherever</span>{" "}
                  you are, in every major language
                </h2>
                <p className="py-2 text-[#333] text-opacity-60 text-base md:text-lg leading-relaxed">
                  Serenoty supports over 100 languages, including French, Spanish, Arabic, Chinese, German, Japanese, and many more.
                </p>
              </div>
              <div className="flex justify-center relative">
                <canvas id="cobe" className="w-full md:w-2/3 lg:w-1/2" style={{ width: "500px", height: "500px" }} width="1000" height="1000"></canvas>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
