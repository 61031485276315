


// import React from 'react';
// import { Mail } from 'lucide-react'; // Importing the Mail icon from lucide-react
// import serenotyLogo from "../../assets/serenotylogo.png"; // Importing the logo

// const Footer = () => {
//   const year = new Date().getFullYear();

//   return (
//     <footer className="bg-bb text-[#fff] mt-20 py-10">
//       <div className="max-w-6xl mx-auto px-5">
//         <div className="grid grid-cols-1 md:grid-cols-12 gap-8 items-center">
//           <div className="md:col-span-4 flex items-center">
//             {/* <img src={serenotyLogo} alt="Serenoty logo" className="w-8 h-auto mr-3" /> */}
//             <span className="self-center whitespace-nowrap text-md md:text-2xl font-semibold text-[#fff]">
//   <span style={{ fontWeight: 'bold' }}>SERENOTY</span>&nbsp;<span style={{ fontWeight: 100, opacity: 0.4 }}>AI</span>
// </span>


//           </div>
//           <div className="md:col-span-8 grid grid-cols-2 md:grid-cols-4 gap-4"> {/* Adjusted grid for mobile */}
//             <div>
//               <h6 className="text-lg font-semibold">Resources</h6>
//               <ul className="mt-4">
//                 <li><a href="#" className="hover:opacity-60  transition-colors text-md">Guide</a></li>
//                 <li><a href="#" className="hover:opacity-60 transition-colors text-md">Updates</a></li>
//                 {/* Other resources links */}
//               </ul>
//             </div>
//             <div>
//               <h6 className="text-lg font-semibold">Company</h6>
//               <ul className="mt-4">
//                 <li><a href="#" className="hover:opacity-60  transition-colors text-md">About</a></li>
//                 <li><a href="#" className="hover:opacity-60  transition-colors text-md">Careers</a></li>
//                 {/* Other company links */}
//               </ul>
//             </div>
//             <div>
//               <h6 className="text-lg font-semibold">Socials</h6>
//               <ul className="flex-col mt-4 space-y-2">
//                 <li><a href="https://linkedin.com" className="hover:opacity-60  transition-colors text-md">LinkedIn</a></li>
//                 <li><a href="https://twitter.com" className="hover:opacity-60  transition-colors text-md">Twitter</a></li>
//                 {/* Add other social links if needed */}
//               </ul>
//             </div>
//             <div>
//               <h6 className="text-lg font-semibold">Legal</h6>
//               <ul className="mt-4">
//                 <li><a href="#" className="hover:opacity-60  transition-colors text-md">Terms</a></li>
//                 <li><a href="#" className="hover:opacity-60  transition-colors text-md">Privacy</a></li>
//               </ul>
//             </div>
//           </div>
//         </div>
//         <div className="text-center mt-8 pt-8 border-t border-gray-300 flex flex-col md:flex-row justify-center md:justify-between items-center">
//           <p className="text-sm">© {year} Serenoty, Inc. All rights reserved.</p>
//           <a href="mailto:info@serenoty.com" className="hover:text-secondary transition-colors flex items-center mt-4 md:mt-0">
//             <Mail className="w-4 h-4 mr-2" />
//             info@serenoty.com
//           </a>
//         </div>
//       </div>
//     </footer>
//   );
// };

// export default Footer;


import React from 'react';
import { Mail } from 'lucide-react'; // Importing the Mail icon from lucide-react
import serenotyLogo from "../../assets/serenotylogo.png"; // Importing the logo
import { Link } from 'react-router-dom';

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <footer className="bg-bb text-[#fff] mt-20 py-10">
      <div className="max-w-6xl mx-auto px-5">
        <div className="grid grid-cols-1 md:grid-cols-12 gap-8 items-center">
          <div className="md:col-span-4 flex items-center">
            {/* <img src={serenotyLogo} alt="Serenoty logo" className="w-8 h-auto mr-3" /> */}
            <span className="self-center whitespace-nowrap text-md md:text-2xl font-semibold text-[#fff]">
              <span style={{ fontWeight: 'bold' }}>SERENOTY</span>&nbsp;<span style={{ fontWeight: 100, opacity: 0.6 }}>AI</span>
            </span>
          </div>
          <div className="md:col-span-8 grid grid-cols-2 md:grid-cols-4 gap-4">
            <div>
              <h6 className="text-lg  opacity-80  font-semibold">Resources</h6>
              <ul className="mt-4  opacity-60 space-y-2">
                <li><a href="#" className="hover:opacity-60 transition-colors text-md">Guide</a></li>
                <li><a href="#" className="hover:opacity-60 transition-colors text-md">Updates</a></li>
                {/* Other resources links */}
              </ul>
            </div>
            <div>
              <h6 className="text-lg  opacity-80 font-semibold">Company</h6>
              <ul className="mt-4  opacity-60 space-y-2">
                <li><a href="#" className="hover:opacity-60 transition-colors text-md">About</a></li>
                <li><a href="#"   to="/careers"  className="hover:opacity-60 transition-colors text-md">Careers</a></li>
      
                {/* Other company links */}
              </ul>
            </div>
            <div>
              <h6 className="text-lg   opacity-80 font-semibold">Socials</h6>
              <ul className="mt-4 opacity-60 space-y-2">
                <li><a href="https://linkedin.com" className="hover:opacity-60 transition-colors text-md">LinkedIn</a></li>
                <li><a href="https://twitter.com" className="hover:opacity-60 transition-colors text-md">X (Twitter)</a></li>

                {/* Add other social links if needed */}
              </ul>
            </div>
            <div>
              <h6 className="text-lg  opacity-80 font-semibold">Legal</h6>
              <ul className="mt-4 space-y-2 opacity-60">
                <li><a href="#" className="hover:opacity-60 transition-colors text-md">Terms of service</a></li>
                <li><a href="#" className="hover:opacity-60 transition-colors text-md">Privacy/Security</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div className="text-center  mt-8 pt-8 border-t opacity-60  border-gray-300 flex flex-col md:flex-row justify-center md:justify-between items-center">
          <p className="text-sm">© {year} Serenoty, Inc. All rights reserved.</p>
          <a href="mailto:info@serenoty.com" className="  hover:opacity-60 transition-colors flex items-center mt-4 md:mt-0">
            <Mail className="w-4 h-4 mr-2" />
   info@serenoty.com
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
