
// import React, { useState, useEffect } from 'react';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Updated import
// import Navigation from "./components/nav/navbar.js";
// import Hero from "./components/hero/hero.js";
// import Banner from "./components/banner/banner.js";
// import FAQSection from "./components/questions/faq.js";
// import Waitlist from "./components/waitlist/waitlist.js";
// import Footer from "./components/footer/footer.js";
// import Careers from "./components/careers/careers.js"; 
// import Home from "./components/Home/home.js";


// export default function App() {
//   const [darkMode, setDarkMode] = useState(false);

//   useEffect(() => {
//     document.body.classList.toggle('dark', darkMode);
//   }, [darkMode]);

//   return (
//     <Router>
//       <div>
//         <Navigation darkMode={darkMode} setDarkMode={setDarkMode} />
//         <Routes> {/* Updated to use Routes */}
//           <Route path="/" element={<Hero />} />
//           <Route path="/careers" element={<Careers />} />
//           <Route path="/home" element={<Home />} />
//           {/* Add more routes as needed */}
//         </Routes> {/* Updated to use Routes */}
//         <Banner />
//         <FAQSection />
//         <Footer />
//       </div>
//     </Router>
//   );
// }



// import React, { useState, useEffect } from 'react';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import { createClient } from '@supabase/supabase-js';
// import { Auth } from '@supabase/auth-ui-react';
// import { ThemeSupa } from '@supabase/auth-ui-shared';
// import Navigation from "./components/nav/navbar.js";
// import Hero from "./components/hero/hero.js";
// import Banner from "./components/banner/banner.js";
// import FAQSection from "./components/questions/faq.js";
// import Waitlist from "./components/waitlist/waitlist.js";
// import Footer from "./components/footer/footer.js";
// import Careers from "./components/careers/careers.js";
// import Home from "./components/Home/home.js";

// // Initialize Supabase client
// const supabase = createClient('https://epgumgdoseythpcpwcxs.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImVwZ3VtZ2Rvc2V5dGhwY3B3Y3hzIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTUwMzU2NDcsImV4cCI6MjAzMDYxMTY0N30.GSQusnTBQruoLx5m_ghRMCp_SzBpGk1FaF38bJ67Chw');

// export default function App() {
//   const [session, setSession] = useState(null);

//   useEffect(() => {
//     // Get the initial session
//     supabase.auth.getSession().then(({ data: { session } }) => {
//       setSession(session);
//     });

//     // Listen for changes in authentication state
//     const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
//       setSession(session);
//     });

//     // Cleanup subscription on unmount
//     return () => subscription.unsubscribe();
//   }, []);

//   return (
//     <Router>
//       <div>
//         {!session ? (
//           <Auth supabaseClient={supabase} appearance={{ theme: ThemeSupa }} />
//         ) : (
//           <>
//             <Navigation />
//             <Routes>
//               <Route path="/" element={
//                 <>
//                   <Hero />
//                   <Banner />
//                   <FAQSection />
//                   <Footer />
//                 </>
//               } />
//               <Route path="/careers" element={
//                 <>
//                   <Careers />
//                   <Banner />
//                   <FAQSection />
//                   <Footer />
//                 </>
//               } />
//               <Route path="/home" element={<Home />} />
//             </Routes>
//           </>
//         )}
//       </div>
//     </Router>
//   );
// }


// import React, { useState, useEffect } from 'react';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import Navigation from "./components/nav/navbar.js";
// import Hero from "./components/hero/hero.js";
// import Banner from "./components/banner/banner.js";
// import FAQSection from "./components/questions/faq.js";
// import Footer from "./components/footer/footer.js";
// import Team from "./components/careers/careers.js";
// import Careers from "./components/careers/team.js";

// import Home from "./components/Home/home.js";
// import { createClient } from '@supabase/supabase-js';

// const supabase = createClient('https://epgumgdoseythpcpwcxs.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImVwZ3VtZ2Rvc2V5dGhwY3B3Y3hzIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTUwMzU2NDcsImV4cCI6MjAzMDYxMTY0N30.GSQusnTBQruoLx5m_ghRMCp_SzBpGk1FaF38bJ67Chw');

// export default function App() {
//   const [session, setSession] = useState(null);

//   useEffect(() => {
//     supabase.auth.getSession().then(({ data: { session } }) => {
//       setSession(session);
//     });

//     const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
//       setSession(session);
//     });

//     return () => subscription.unsubscribe();
//   }, []);

//   return (
//     <Router>
//       <div>
//         {!session ? (
//           <Navigation />
//         ) : (
//           <>
//             <Navigation />
//             <Routes>
//               <Route path="/" element={
//                 <>
//                   <Hero />
//                   <Banner />
//                   <FAQSection />
//                   <Footer />
//                 </>
//               } />
//               <Route path="/careers" element={
//                 <>
//                   <Careers />
//                   <Banner />
//                   <FAQSection />
//                   <Footer />
//                 </>
//               } />
//               <Route path="/home" element={<Home />} />
//             </Routes>
//           </>
//         )}
//       </div>
//     </Router>
//   );
// }


// import React from 'react';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import Navigation from "./components/nav/navbar.js";
// import Hero from "./components/hero/hero.js";
// import Banner from "./components/banner/banner.js";
// import FAQSection from "./components/questions/faq.js";
// import Footer from "./components/footer/footer.js";
// import Careers from "./components/careers/careers.js";
// import Team from "./components/team/team.js"; // Assuming this is the path to your Team component
// import Home from "./components/Home/home.js";

// export default function App() {
//   return (
//     <Router>
//       <div>
//         <Navigation />
//         <Routes>
//           <Route path="/" element={
//             <>
//               <Hero />
//               <Banner />
//               <FAQSection />
          
//               <Footer />
           
//             </>
//           } />
//           <Route path="/careers" element={
//             <>
//               <Hero />
//               <Careers />
//               <Banner />
//               <FAQSection />
        
//               <Footer />
//             </>
//           } />
//           <Route path="/home" element={<Home />} />
//         </Routes>
//       </div>
//     </Router>
//   );
// }


// import React from 'react';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import Navigation from "./components/nav/navbar.js";
// import Hero from "./components/hero/hero.js";
// import Banner from "./components/banner/banner.js";
// import FAQSection from "./components/questions/faq.js";
// import Footer from "./components/footer/footer.js";
// import Careers from "./components/careers/careers.js";
// import Team from "./components/team/team"; // Correct path to Team component
// import Home from "./components/Home/home.js";
// import Pricing from "./components/pricing/pricing.js";

// export default function App() {
//   return (
//     <Router>
//       <div>
//         <Navigation />
//         <Routes>
//           <Route path="/" element={
//             <>
//               <Hero />
//               <Banner />
//               <Team />
//               <FAQSection />
//               <Footer />
//             </>
//           } />
//           <Route path="/careers" element={
//             <>
//               <Hero />
//               <Careers />
//               <Banner />
//               <FAQSection />
//               <Team />
//               <Footer />
//             </>
//           } />
//           <Route path="/home" element={<Home />} />
//         </Routes>
//       </div>
//     </Router>
//   );
// }


import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navigation from "./components/nav/navbar.js";
import Hero from "./components/hero/hero.js";
import Banner from "./components/banner/banner.js";
import FAQSection from "./components/questions/faq.js";
import Footer from "./components/footer/footer.js";
import Careers from "./components/careers/careers.js";
import Team from "./components/team/team"; // Correct path to Team component
import Home from "./components/Home/home.js";
import Pricing from "./components/pricing/pricing.js";

export default function App() {
  return (
    <Router>
      <div>
        <Navigation />
        <Routes>
          <Route path="/" element={
            <>
              <Hero />
              <Banner />
              <Team />
              <FAQSection />
              <Footer />
            </>
          } />
          <Route path="/careers" element={
            <>
              <Hero />
              <Careers />
              <Banner />
                   {/* <Team /> */}
              <FAQSection />
              <Footer />
            </>
          } />
          <Route path="/home" element={<Home />} />
        </Routes>
      </div>
    </Router>
  );
}

