import React from 'react';

const Home = () => {
  return (
    <div className="min-h-screen bg-gray-100">
      <nav className="bg-white p-4 shadow-md flex justify-between items-center">
        <div className="text-xl font-bold">Logo</div>
        <div className="flex space-x-4">
          <button className="p-2 bg-gray-100 rounded-md">Support</button>
          <button className="p-2 bg-gray-100 rounded-md">Feedback</button>
          <button className="p-2 bg-gray-100 rounded-md">Upgrade</button>
        </div>
      </nav>
      <div className="bg-white p-8 text-center shadow-md">
        <h1 className="text-2xl font-bold">Welcome back, Firaoll</h1>
        <p className="text-gray-600">Import a document to understand its contents or start writing in a new note.</p>
      </div>
      <div className="p-8">
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
          <div className="bg-white p-4 rounded-lg shadow-md">
            <h2 className="font-bold">Write</h2>
            <p>Create a new note</p>
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md">
            <h2 className="font-bold">Import</h2>
            <p>Add files into your library</p>
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md col-span-1 md:col-span-2 lg:col-span-1">
            <input 
              type="text" 
              placeholder="Search your library..." 
              className="p-2 w-full border rounded-md"
            />
          </div>
        </div>
        <div className="mt-4">
          <h3 className="font-bold">Library</h3>
          <ul>
            <li className="bg-white p-4 rounded-lg shadow-md mt-2">BMEN503:</li>
            <li className="bg-white p-4 rounded-lg shadow-md mt-2">Pasted text</li>
            <li className="bg-white p-4 rounded-lg shadow-md mt-2">Concussion_biomechanics_head_acceleration_exposure</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Home;
