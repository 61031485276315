

// export default Careers;
import React from "react";
import "./careers.css"; // Ensure Tailwind CSS is imported properly

const Careers = () => {
  return (
    <div className="flex flex-col items-center justify-center mt-20 px-4">
      <h1 className="text-4xl font-bold mb-10 text-blue-600 text-center">Careers at Serenoty AI</h1>
      <div className="max-w-4xl w-full p-8 border border-gray-300 rounded-xl shadow-lg bg-white">
        
        <div className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">Founding Full-Stack Engineer</h2>
          <p className="text-lg text-justify">
            Serenoty AI is looking for a talented Founding Full-Stack Engineer to help us accelerate the pace of scientific discovery.
          </p>
        </div>

        <div className="mb-6">
          <h3 className="text-xl font-semibold mb-2">About the role:</h3>
          <ul className="list-disc list-inside text-justify">
            <li>Talk to users often and ship features they care about, wherever they are in the stack.</li>
            <li>Move fast but polish things -- we deeply care about good design but we don’t let this slow us down.</li>
            <li>Develop strong opinions, be able to communicate them and turn them into features.</li>
            <li>Create prototypes, run experiments to test them with users and understand their effectiveness.</li>
            <li>Build internal tools to automate non-product work. We do this a lot.</li>
            <li>As a founding member of the team, you'll make a significant impact as we rapidly scale revenue from $1M to $10M and then $10M to $100M+.</li>
          </ul>
        </div>

        <div className="mb-6">
          <h3 className="text-xl font-semibold mb-2">You’re likely a good fit if you:</h3>
          <ul className="list-disc list-inside text-justify">
            <li>Have independently built and scaled a product to at least a few thousand users (e.g. a side project or open-source project).</li>
            <li>Have worked in a high-growth, fast-paced environment (ideally another startup) and can drive things forward with relatively little oversight.</li>
          </ul>
        </div>

        <div className="mb-6">
          <h3 className="text-xl font-semibold mb-2">Bonuses:</h3>
          <ul className="list-disc list-inside text-justify">
            <li>Familiarity with AI and ML concepts, especially in the context of natural language processing and generative AI models.</li>
            <li>You’ve previously built a consumer product, or worked at a top consumer company before.</li>
          </ul>
        </div>

        <div className="mb-6">
          <p className="text-lg text-justify">
            If interested, reach out with a couple of sentences about you, links to what you've shipped before and what you want to do for us starting next week.
          </p>
        </div>

        <div className="mb-6">
          <h3 className="text-xl font-semibold mb-2">About the interview:</h3>
          <p className="text-lg text-justify">
            First, we'll have an introductory call to learn more about what you're looking for, the role and whether it's a good fit.
            Next, we usually do a quick take-home exercise or a follow-up call. This helps us get to know more about you and closely reflects what you’d be doing day-to-day at Serenoty AI.
            Finally, we'll ask you to work with us on a brief project. We'll make sure you have a clear understanding of what to expect beforehand so you’re fully prepared for this final step.
            If the onsite goes well, we'll make you an offer! We don't always stick to this process, but it should give you a good idea of what to expect.
          </p>
        </div>

        <div className="mb-6">
          <h3 className="text-xl font-semibold mb-2">About Serenoty AI:</h3>
          <p className="text-lg text-justify">
            Serenoty AI is a research assistant that helps academics quickly find insights in scientific literature so they can write papers faster. We’re using language models to offload rote research tasks, speeding up the research process and freeing up more human capacity for higher reasoning and insight.
            Since launching a year ago, we’ve grown to over 800,000 users.
            And we’re proud to power research teams at top orgs like Roche Pharmaceutical, Stanford University, GSK, Johns Hopkins and MIT.
            We’re based in Calgary, Alberta, and we offer premium healthcare (medical, dental, vision, etc.), flexible working hours, access to the Founders Inc community, and office space with a beautiful view of the marina and Golden Gate bridge.
          </p>
        </div>

        <div className="flex justify-center">
          <button
            type="button"
            className="inline-flex items-center px-6 py-3 bg-blue-600 text-white text-lg font-semibold rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600"
            onClick={() => window.location.href='https://fllky8j5bmm.typeform.com/to/WHfbZpRJ'}
          >
            Apply Now
          </button>
        </div>
      </div>
    </div>
  );
};

export default Careers;
