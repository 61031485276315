
// import React, { useState } from 'react';
// import { Navbar } from "flowbite-react";
// import { HiOutlineArrowRight } from "react-icons/hi";
// import serenotyLogo from "../../assets/serenotylogo.png";
// import './navbar.css';
// import { Link } from 'react-router-dom';


// const Navigation = () => {
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [isScrolled, setIsScrolled] = useState(false);
//   const [email, setEmail] = useState('');
//   const [isCodeSent, setIsCodeSent] = useState(false);
//   const [isLoading, setIsLoading] = useState(false);
  

//   const toggleModal = () => {
//     setIsModalOpen(!isModalOpen);
//   };

//   const handleAboutUsClick = () => {
//     // Implement action for About Us button click
//     console.log("About Us clicked");
//   };

//   const handlePricingClick = () => {
//     // Implement action for Pricing button click
//     console.log("Pricing clicked");
//   };

//   const scrollToAboveFooter = () => {
//     const footerHeight = 300; 
//     const scrollPosition = document.documentElement.scrollHeight - footerHeight;
  
//     window.scrollTo({
//       top: scrollPosition,
//       behavior: "smooth"
//     });
//   };


// const navbarClasses = `fixed top-0 left-0 w-full z-20 ${
//     isScrolled ? 'backdrop-blur-sm bg-bb' : 'backdrop-blur-lg bg-[#ffffffa2]'
//   } transition-opacity duration-300 ease-in-out`;



// const handleEmailChange = (e) => {
//     setEmail(e.target.value);
//   };

//   const handleContinue = () => {
//     if (!isCodeSent) {
//       // Send email with code
//       setIsCodeSent(true);
//       setIsLoading(true); // Show loader
//       // Simulating email sending delay
//       setTimeout(() => {
//         setIsLoading(false);
//       }, 2000); // Adjust this timeout as needed
//     } else {
//       // Verify code
//       // Assuming you have a function to verify code
//       // setIsCodeVerified(true);
//     }
//   };
  



// return (
//   <div className="nav-container">
//    <Navbar fluid={true} rounded={true} className={navbarClasses}>
//  <div className="container banner-container mx-auto px-4 flex justify-between items-center">
//       <div className="flex items-center">
//       {/* <img src={serenotyLogo} alt="Serenoty Logo" className="h-8 w-auto md:h-10 md:w-auto mr-2" /> */}
//       <span className="self-center whitespace-nowrap text-md md:text-2xl font-semibold text-primary">
//   <span style={{ fontWeight: 'semibold' }}>SERENOTY</span>&nbsp;<span style={{ fontWeight: 100, opacity: 0.4 }}>AI</span>
// </span>

//         </div>
// <div className="flex items-center md:hidden py-2"> 
// {/* <button
//   type="button"
//   className="inline-flex items-center px-2.5 h-[1.9rem] py-1.5 mr-2 border border-transparent text-xs md:text-sm font-semibold rounded-md text-primary hover:bg-tt hover:text-primary  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
//   onClick={toggleModal}
// >
//   About
// </button> */}

// <button
//   type="button"
//   className="inline-flex items-center px-2.5 h-[1.9rem] py-1.5 mr-2 border border-transparent text-xs md:text-sm font-semibold rounded-md text-primary hover:bg-tt hover:text-primary  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
//   onClick={toggleModal}
// >
// Careers
// </button>

//  <button
//   type="button"
//   className="inline-flex items-center px-2.5 h-[1.9rem] py-1.5 mr-2 border bg-tt border-transparent text-xs md:text-sm font-semibold rounded-md text-primary  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white "
//   onClick={() => window.location.href='https://fllky8j5bmm.typeform.com/to/WHfbZpRJ'}
// >
//   Apply for early access
// </button>

// {/* {/* <button
//   type="button"
//   className="inline-flex items-center px-2.5 py-1.5 h-[1.9rem] border bg-bb border-transparent text-xs md:text-sm font-semibold rounded-md text-white  hover:bg-primary  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
//   onClick={toggleModal}
// >
//   Sign Up
// </button> */}
// </div>

//         <div className="md:flex items-center space-x-2 hidden md:flex">

//     <button
//          type="button"
//         className="inline-flex items-center px-2.5 h-[1.9rem]  py-2 border border-transparent text-sm font-semibold rounded-md text-primary hover:bg-[#edebe7] hover:text-primary  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
//           onClick={toggleModal}
//      >
//          About
//         </button>




//         <button
//           type="button"
//           className="inline-flex items-center px-2.5 h-[1.9rem]  py-2 border border-transparent  text-sm font-semibold rounded-md text-primary  hover:bg-[#edebe7]  hover:text-primary  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
//           onClick={toggleModal}
//         >
//      Careers
//         </button>


        


//    <button
// type="button"
// className="inline-flex items-center px-2.5 h-[1.9rem] py-2 border bg-[#edebe7]  hover:bg-opacity-50 border-transparent text-sm font-semibold rounded-md text-primary  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white "
// onClick={() => window.location.href='https://fllky8j5bmm.typeform.com/to/WHfbZpRJ'}
// >
// Apply for early access
// </button>




// {/* <button
// type="button"
// className="inline-flex items-center px-2.5 py-2 h-[1.9rem] border bg-bb border-transparent text-sm font-semibold rounded-md text-white  hover:bg-opacity-80  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
// onClick={toggleModal}
// >
// Apply for early acess 
// </button> */}

//         </div>
//       </div>
//     </Navbar>

//     {isModalOpen && (
// <div className="fixed inset-0 z-50 flex justify-center items-center bg-black bg-opacity-50">
//   <div className="bg-white p-6 rounded-2xl relative max-w-sm w-full">
//     <button
//       className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
//       onClick={toggleModal}
//     >
//       <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//         <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
//       </svg>
//     </button>
//     <h2 className="mb-4 text-center text-bb">Sign Up</h2>
 
//     <div className="mb-4 flex items-center justify-center">
//       <hr className="border-gray-300 w-full" />
//     </div>
//     {/* <button className="bg-secondary  text-primary px-4 py-2 rounded-2xl mb-4 w-full flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-opacity-50 hover:bg-opacity-90">
// <span className="text-2xl font-bold mr-4 ml-0">G</span>
// <span className="mx-auto">Continue with Google</span>
// </button> */}
// <button className="bg-hover1 text-primary px-4 py-2 rounded-2xl mb-4 w-full flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-opacity-50 hover:bg-opacity-90 hover:transform hover:scale-105 transition duration-300 ease-in-out">
// <span className="text-2xl font-bold mr-4 ml-0">G</span>
// <span className="mx-auto font-medium font-semibold">Continue with Google</span>
// </button>

//     <div className="mb-4 flex items-center justify-center">
//       <hr className="border-gray-300 w-full" />
//       <span className="text-gray-500 mx-4">or</span>
//       <hr className="border-gray-300 w-full" />
//     </div>
//     <input
//       type="email"
//       className="border  font-medium font-semibold border-gray-300 rounded-2xl px-4 py-2 mb-4 w-full focus:outline-none focus:ring-2 focus:ring-gray-300 focus:border-transparent"
//       placeholder="Email"
//     />
//    <button className="bg-bb  text-white px-4 py-2 rounded-2xl mb-4 w-full focus:outline-none focus:ring-2   font-medium font-semibold focus:ring-primary focus:ring-opacity-50 r hover:transform hover:scale-105 transition duration-300 ease-in-out">Continue with email</button>

//     <div className="text-sm text-gray-500 text-center">
//       <a href="#" className="hover:underline">Privacy</a>
//       <span className="mx-2">•</span>
//       <a href="#" className="hover:underline">Terms</a>
//     </div>
//   </div>
// </div>
// )}



//   </div>
// );
// };

// export default Navigation;

// import React, { useState } from 'react';
// import { Navbar } from "flowbite-react";
// import { HiOutlineArrowRight } from "react-icons/hi";
// import serenotyLogo from "../../assets/serenotylogo.png";
// import './navbar.css';
// import { Link } from 'react-router-dom';

// const Navigation = () => {
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [isScrolled, setIsScrolled] = useState(false);
//   const [email, setEmail] = useState('');
//   const [isCodeSent, setIsCodeSent] = useState(false);
//   const [isLoading, setIsLoading] = useState(false);

//   const toggleModal = () => {
//     setIsModalOpen(!isModalOpen);
//   };

//   const handleAboutUsClick = () => {
//     console.log("About Us clicked");
//   };

//   const handlePricingClick = () => {
//     console.log("Pricing clicked");
//   };

//   const scrollToAboveFooter = () => {
//     const footerHeight = 300;
//     const scrollPosition = document.documentElement.scrollHeight - footerHeight;
//     window.scrollTo({
//       top: scrollPosition,
//       behavior: "smooth"
//     });
//   };

//   const navbarClasses = `fixed top-0 left-0 w-full z-20 ${
//     isScrolled ? 'backdrop-blur-sm bg-bb' : 'backdrop-blur-lg bg-[#ffffffa2]'
//   } transition-opacity duration-300 ease-in-out`;

//   const handleEmailChange = (e) => {
//     setEmail(e.target.value);
//   };

//   const handleContinue = () => {
//     if (!isCodeSent) {
//       setIsCodeSent(true);
//       setIsLoading(true); // Show loader
//       setTimeout(() => {
//         setIsLoading(false);
//       }, 2000); // Adjust this timeout as needed
//     }
//   };

//   return (
//     <div className="nav-container">
//      <Navbar fluid={true} rounded={true} className={navbarClasses}>
//   <div className="container banner-container mx-auto px-4 flex justify-between items-center">
//     <div className="flex items-center">
//       {/* <img src={serenotyLogo} alt="Serenoty Logo" className="h-8 w-auto md:h-10 md:w-auto mr-2" /> */}
//       <span className="self-center whitespace-nowrap text-md md:text-2xl font-semibold text-primary">
//         <span style={{ fontWeight: 'semibold' }}>SERENOTY</span>&nbsp;<span style={{ fontWeight: 100, opacity: 0.4 }}>AI</span>
//       </span>
//     </div>
//     <div className="flex items-center md:hidden py-2 space-x-2"> {/* Added space-x-2 here */}
//       <button
//         type="button"
//         className="inline-flex items-center px-2.5 h-[1.9rem] py-2 border bg-[#edebe7] hover:bg-opacity-50 border-transparent text-sm font-semibold rounded-md text-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
//         onClick={toggleModal}
//       >
//         Sign In
//       </button>

//       <button
//         type="button"
//         className="inline-flex items-center px-2.5 h-[1.9rem] py-2 border bg-bb hover:bg-opacity-50 border-transparent text-sm font-semibold rounded-md text-tt focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
//         onClick={toggleModal}
//       >
//         Sign Up
//       </button>
//     </div>
//     <div className="md:flex items-center space-x-2 hidden md:flex">


//             {/* <Link
//               to="/"
//               className="inline-flex items-center px-2.5 h-[1.9rem] py-2 border border-transparent text-sm font-semibold rounded-md text-primary hover:bg-[#edebe7] hover:text-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
//             >
//               Team 
//             </Link> */}
//             {/* <button

//               type="button"
//               className="inline-flex items-center px-2.5 h-[1.9rem] py-2 border border-transparent text-sm font-semibold rounded-md text-primary hover:bg-[#edebe7] hover:text-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
//               onClick={toggleModal}
//             // >
//             //   About
//             // </button> */}
//              {/* <Link
//             //   to="/careers"
//             //   className="inline-flex items-center px-2.5 h-[1.9rem] py-2 border border-transparent text-sm font-semibold rounded-md text-primary hover:bg-[#edebe7] hover:text-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
//             // >
//             //   Careers
//             // </Link> */}
//             {/* <button
//               type="button"
//               className="inline-flex items-center px-2.5 h-[1.9rem] py-2 border bg-[#edebe7] hover:bg-opacity-50 border-transparent text-sm font-semibold rounded-md text-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
//               onClick={() => window.location.href='https://fllky8j5bmm.typeform.com/to/WHfbZpRJ'}
//             >
//               Apply for early access
//             </button> */}

//             <button
//               type="button"
//               className="inline-flex items-center px-2.5 h-[1.9rem] py-2 border bg-[#edebe7] hover:bg-opacity-50 border-transparent text-sm font-semibold rounded-md text-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
//               onClick={toggleModal}
//             >
//               Sign In
//             </button>

//             <button
//               type="button"
//               className="inline-flex items-center px-2.5 h-[1.9rem] py-2 border bg-bb hover:bg-opacity-50 border-transparent text-sm font-semibold rounded-md text-tt focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
//               onClick={toggleModal}
//             >
//               Sign Up
//             </button>




//           </div>
//         </div>
//       </Navbar>

//       {isModalOpen && (
//         <div className="fixed inset-0 z-50 flex justify-center items-center bg-black bg-opacity-50">
//           <div className="bg-white p-6 rounded-2xl relative max-w-sm w-full">
//             <button
//               className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
//               onClick={toggleModal}
//             >
//               <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//                 <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
//               </svg>
//             </button>
//             <h2 className="mb-4 text-center text-bb">Sign Up</h2>
//             <div className="mb-4 flex items-center justify-center">
//               <hr className="border-gray-300 w-full" />
//             </div>
//             <button className="bg-hover1 text-primary px-4 py-2 rounded-2xl mb-4 w-full flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-opacity-50 hover:bg-opacity-90 hover:transform hover:scale-105 transition duration-300 ease-in-out">
//               <span className="text-2xl font-bold mr-4 ml-0">G</span>
//               <span className="mx-auto font-medium font-semibold">Continue with Google</span>
//             </button>
//             <div className="mb-4 flex items-center justify-center">
//               <hr className="border-gray-300 w-full" />
//               <span className="text-gray-500 mx-4">or</span>
//               <hr className="border-gray-300 w-full" />
//             </div>
//             <input
//               type="email"
//               className="border font-medium font-semibold border-gray-300 rounded-2xl px-4 py-2 mb-4 w-full focus:outline-none focus:ring-2 focus:ring-gray-300 focus:border-transparent"
//               placeholder="Email"
//             />
//             <button className="bg-bb text-white px-4 py-2 rounded-2xl mb-4 w-full focus:outline-none focus:ring-2 font-medium font-semibold focus:ring-primary focus:ring-opacity-50 hover:transform hover:scale-105 transition duration-300 ease-in-out">
//               Continue with email
//             </button>
//             <div className="text-sm text-gray-500 text-center">
//               <a href="#" className="hover:underline">Privacy</a>
//               <span className="mx-2">•</span>
//               <a href="#" className="hover:underline">Terms</a>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Navigation;






// import React, { useState } from 'react';
// import { Navbar } from "flowbite-react";
// import { HiOutlineArrowRight } from "react-icons/hi";
// import serenotyLogo from "../../assets/serenotylogo.png";
// import './navbar.css';
// import { Link, useNavigate } from 'react-router-dom';

// const Navigation = () => {
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [isScrolled, setIsScrolled] = useState(false);
//   const [email, setEmail] = useState('');
//   const [isCodeSent, setIsCodeSent] = useState(false);
//   const [isLoading, setIsLoading] = useState(false);

//   const navigate = useNavigate();

//   const toggleModal = () => {
//     setIsModalOpen(!isModalOpen);
//   };

//   const handleAboutUsClick = () => {
//     console.log("About Us clicked");
//   };

//   const handlePricingClick = () => {
//     navigate('/pricing');
//   };

//   const scrollToAboveFooter = () => {
//     const footerHeight = 300;
//     const scrollPosition = document.documentElement.scrollHeight - footerHeight;
//     window.scrollTo({
//       top: scrollPosition,
//       behavior: "smooth"
//     });
//   };

//   const navbarClasses = `fixed top-0 left-0 w-full z-20 ${
//     isScrolled ? 'backdrop-blur-sm bg-bb' : 'backdrop-blur-lg bg-[#ffffffa2]'
//   } transition-opacity duration-300 ease-in-out`;

//   const handleEmailChange = (e) => {
//     setEmail(e.target.value);
//   };

//   const handleContinue = () => {
//     if (!isCodeSent) {
//       setIsCodeSent(true);
//       setIsLoading(true); // Show loader
//       setTimeout(() => {
//         setIsLoading(false);
//       }, 2000); // Adjust this timeout as needed
//     }
//   };

//   return (
//     <div className="nav-container">
//       <Navbar fluid={true} rounded={true} className={navbarClasses}>
//         <div className="container banner-container mx-auto px-4 flex justify-between items-center">
//           <div className="flex items-center">
//             {/* <img src={serenotyLogo} alt="Serenoty Logo" className="h-8 w-auto md:h-10 md:w-auto mr-2" /> */}
//             <span className="self-center whitespace-nowrap text-md md:text-2xl font-semibold text-primary">
//               <span style={{ fontWeight: 'semibold' }}>SERENOTY</span>&nbsp;<span style={{ fontWeight: 100, opacity: 0.4 }}>AI</span>
//             </span>
//           </div>
//           <div className="flex items-center md:hidden py-2 space-x-2">
//             <button
//               type="button"
//               className="inline-flex items-center px-2.5 h-[1.9rem] py-2 border bg-[#edebe7] hover:bg-opacity-50 border-transparent text-sm font-semibold rounded-md text-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
//               onClick={toggleModal}
//             >
//               Sign In
//             </button>

//             <button
//               type="button"
//               className="inline-flex items-center px-2.5 h-[1.9rem] py-2 border bg-bb hover:bg-opacity-50 border-transparent text-sm font-semibold rounded-md text-tt focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
//               onClick={toggleModal}
//             >
//               Sign Up
//             </button>
//           </div>
//           <div className="md:flex items-center space-x-2 hidden md:flex">
//           <button
//               type="button"
//               className="inline-flex items-center px-2.5 h-[1.9rem] py-2 border bg-secondary hover:bg-opacity-50 border-transparent text-sm font-semibold rounded-md text-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
//               onClick={handlePricingClick}
//             >
//               Pricing
//             </button>
            
//             <button
//               type="button"
//               className="inline-flex items-center px-2.5 h-[1.9rem] py-2 border bg-[#edebe7] hover:bg-opacity-50 border-transparent text-sm font-semibold rounded-md text-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
//               onClick={toggleModal}
//             >
//               Sign In
//             </button>

//             <button
//               type="button"
//               className="inline-flex items-center px-2.5 h-[1.9rem] py-2 border bg-bb hover:bg-opacity-50 border-transparent text-sm font-semibold rounded-md text-tt focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
//               onClick={toggleModal}
//             >
//               Sign Up
//             </button>

           
//           </div>
//         </div>
//       </Navbar>

//       {isModalOpen && (
//         <div className="fixed inset-0 z-50 flex justify-center items-center bg-black bg-opacity-50">
//           <div className="bg-white p-6 rounded-2xl relative max-w-sm w-full">
//             <button
//               className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
//               onClick={toggleModal}
//             >
//               <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//                 <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
//               </svg>
//             </button>
//             <h2 className="mb-4 text-center text-bb">Sign Up</h2>
//             <div className="mb-4 flex items-center justify-center">
//               <hr className="border-gray-300 w-full" />
//             </div>
//             <button className="bg-hover1 text-primary px-4 py-2 rounded-2xl mb-4 w-full flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-opacity-50 hover:bg-opacity-90 hover:transform hover:scale-105 transition duration-300 ease-in-out">
//               <span className="text-2xl font-bold mr-4 ml-0">G</span>
//               <span className="mx-auto font-medium font-semibold">Continue with Google</span>
//             </button>
//             <div className="mb-4 flex items-center justify-center">
//               <hr className="border-gray-300 w-full" />
//               <span className="text-gray-500 mx-4">or</span>
//               <hr className="border-gray-300 w-full" />
//             </div>
//             <input
//               type="email"
//               className="border font-medium font-semibold border-gray-300 rounded-2xl px-4 py-2 mb-4 w-full focus:outline-none focus:ring-2 focus:ring-gray-300 focus:border-transparent"
//               placeholder="Email"
//             />
//             <button className="bg-bb text-white px-4 py-2 rounded-2xl mb-4 w-full focus:outline-none focus:ring-2 font-medium font-semibold focus:ring-primary focus:ring-opacity-50 hover:transform hover:scale-105 transition duration-300 ease-in-out">
//               Continue with email
//             </button>
//             <div className="text-sm text-gray-500 text-center">
//               <a href="#" className="hover:underline">Privacy</a>
//               <span className="mx-2">•</span>
//               <a href="#" className="hover:underline">Terms</a>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Navigation;














import React, { useState } from 'react';
import { Navbar } from "flowbite-react";
import { Link } from 'react-router-dom';
import serenotyLogo from "../../assets/serenotylogo.png";
import './navbar.css';

const Navigation = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const navbarClasses = `fixed top-0 left-0 w-full z-20 ${
    isScrolled ? 'backdrop-blur-sm bg-bb' : 'backdrop-blur-lg bg-[#ffffffa2]'
  } transition-opacity duration-300 ease-in-out`;

  return (
    <div className="nav-container">
      <Navbar fluid={true} rounded={true} className={navbarClasses}>
        <div className="container banner-container mx-auto px-4 flex justify-between items-center">
          <div className="flex items-center">
            <Link to="/" className="self-center whitespace-nowrap text-md md:text-2xl font-semibold text-primary">
              <span style={{ fontWeight: 'semibold' }}>SERENOTY</span>&nbsp;<span style={{ fontWeight: 100, opacity: 0.4 }}>AI</span>
            </Link>
          </div>
          <div className="flex items-center md:hidden py-2 space-x-2">
            <button
              type="button"
              className="inline-flex items-center px-2.5 h-[1.9rem] py-2 border bg-[#edebe7] hover:bg-opacity-50 border-transparent text-sm font-semibold rounded-md text-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
              onClick={toggleModal}
            >
              Sign In
            </button>
            <button
              type="button"
              className="inline-flex items-center px-2.5 h-[1.9rem] py-2 border bg-bb hover:bg-opacity-50 border-transparent text-sm font-semibold rounded-md text-tt focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
              onClick={toggleModal}
            >
              Sign Up
            </button>
          </div>
          <div className="md:flex items-center space-x-2 hidden md:flex">
            <button
              type="button"
              className="inline-flex items-center px-2.5 h-[1.9rem] py-2 border bg-[#edebe7] hover:bg-opacity-50 border-transparent text-sm font-semibold rounded-md text-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
              onClick={toggleModal}
            >
              Sign In
            </button>
            <button
              type="button"
              className="inline-flex items-center px-2.5 h-[1.9rem] py-2 border bg-bb hover:bg-opacity-50 border-transparent text-sm font-semibold rounded-md text-tt focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
              onClick={toggleModal}
            >
              Sign Up
            </button>
          </div>
        </div>
      </Navbar>

      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex justify-center items-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-2xl relative max-w-sm w-full">
            <button
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
              onClick={toggleModal}
            >
              <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
            <h2 className="mb-4 text-center text-bb">Sign Up</h2>
            <div className="mb-4 flex items-center justify-center">
              <hr className="border-gray-300 w-full" />
            </div>
            <button className="bg-hover1 text-primary px-4 py-2 rounded-2xl mb-4 w-full flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-opacity-50 hover:bg-opacity-90 hover:transform hover:scale-105 transition duration-300 ease-in-out">
              <span className="text-2xl font-bold mr-4 ml-0">G</span>
              <span className="mx-auto font-medium font-semibold">Continue with Google</span>
            </button>
            <div className="mb-4 flex items-center justify-center">
              <hr className="border-gray-300 w-full" />
              <span className="text-gray-500 mx-4">or</span>
              <hr className="border-gray-300 w-full" />
            </div>
            <input
              type="email"
              className="border font-medium font-semibold border-gray-300 rounded-2xl px-4 py-2 mb-4 w-full focus:outline-none focus:ring-2 focus:ring-gray-300 focus:border-transparent"
              placeholder="Email"
            />
            <button className="bg-bb text-white px-4 py-2 rounded-2xl mb-4 w-full focus:outline-none focus:ring-2 font-medium font-semibold focus:ring-primary focus:ring-opacity-50 hover:transform hover:scale-105 transition duration-300 ease-in-out">
              Continue with email
            </button>
            <div className="text-sm text-gray-500 text-center">
              <a href="#" className="hover:underline">Privacy</a>
              <span className="mx-2">•</span>
              <a href="#" className="hover:underline">Terms</a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Navigation;




// import React, { useState, useEffect } from 'react';
// import { Navbar } from "flowbite-react";
// import { Link } from 'react-router-dom';
// import serenotyLogo from "../../assets/serenotylogo.png";
// import { Auth } from '@supabase/auth-ui-react';
// import { ThemeSupa } from '@supabase/auth-ui-shared';
// import { createClient } from '@supabase/supabase-js';
// import './navbar.css';

// const supabase = createClient('https://epgumgdoseythpcpwcxs.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImVwZ3VtZ2Rvc2V5dGhwY3B3Y3hzIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTUwMzU2NDcsImV4cCI6MjAzMDYxMTY0N30.GSQusnTBQruoLx5m_ghRMCp_SzBpGk1FaF38bJ67Chw');

// const Navigation = () => {
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [isScrolled, setIsScrolled] = useState(false);
//   const [session, setSession] = useState(null);

//   useEffect(() => {
//     supabase.auth.getSession().then(({ data: { session } }) => {
//       setSession(session);
//     });

//     const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
//       setSession(session);
//     });

//     return () => subscription.unsubscribe();
//   }, []);

//   const toggleModal = () => {
//     setIsModalOpen(!isModalOpen);
//   };

//   const navbarClasses = `fixed top-0 left-0 w-full z-20 ${
//     isScrolled ? 'backdrop-blur-sm bg-bb' : 'backdrop-blur-lg bg-[#ffffffa2]'
//   } transition-opacity duration-300 ease-in-out`;

//   return (
//     <div className="nav-container">
//       <Navbar fluid={true} rounded={true} className={navbarClasses}>
//         <div className="container banner-container mx-auto px-4 flex justify-between items-center">
//           <div className="flex items-center">
//             <Link to="/" className="self-center whitespace-nowrap text-md md:text-2xl font-semibold text-primary">
//               <span style={{ fontWeight: 'semibold' }}>SERENOTY</span>&nbsp;<span style={{ fontWeight: 100, opacity: 0.4 }}>AI</span>
//             </Link>
//           </div>
//           <div className="flex items-center md:hidden py-2 space-x-2">
//             <button
//               type="button"
//               className="inline-flex items-center px-2.5 h-[1.9rem] py-2 border bg-[#edebe7] hover:bg-opacity-50 border-transparent text-sm font-semibold rounded-md text-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
//               onClick={toggleModal}
//             >
//               Sign In
//             </button>
//             <button
//               type="button"
//               className="inline-flex items-center px-2.5 h-[1.9rem] py-2 border bg-bb hover:bg-opacity-50 border-transparent text-sm font-semibold rounded-md text-tt focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
//               onClick={toggleModal}
//             >
//               Sign Up
//             </button>
//           </div>
//           <div className="md:flex items-center space-x-2 hidden md:flex">
//             <button
//               type="button"
//               className="inline-flex items-center px-2.5 h-[1.9rem] py-2 border bg-[#edebe7] hover:bg-opacity-50 border-transparent text-sm font-semibold rounded-md text-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
//               onClick={toggleModal}
//             >
//               Sign In
//             </button>
//             <button
//               type="button"
//               className="inline-flex items-center px-2.5 h-[1.9rem] py-2 border bg-bb hover:bg-opacity-50 border-transparent text-sm font-semibold rounded-md text-tt focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
//               onClick={toggleModal}
//             >
//               Sign Up
//             </button>
//           </div>
//         </div>
//       </Navbar>

//       {isModalOpen && (
//         <div className="fixed inset-0 z-50 flex justify-center items-center bg-black bg-opacity-50">
//           <div className="bg-white p-6 rounded-2xl relative max-w-sm w-full">
//             <button
//               className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
//               onClick={toggleModal}
//             >
//               <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//                 <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
//               </svg>
//             </button>
//             <h2 className="mb-4 text-center text-bb">Sign Up / Sign In</h2>
//             <Auth
//               supabaseClient={supabase}
//               providers={['google']}
//               appearance={{ theme: ThemeSupa }}
//               view="sign_in"
//             />
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Navigation;


// import React, { useState, useEffect } from 'react';
// import { Navbar } from "flowbite-react";
// import { Link } from 'react-router-dom';
// import { Auth } from '@supabase/auth-ui-react';
// import { ThemeSupa } from '@supabase/auth-ui-shared';
// import { createClient } from '@supabase/supabase-js';
// import serenotyLogo from "../../assets/serenotylogo.png";
// import './navbar.css';

// const supabase = createClient('https://epgumgdoseythpcpwcxs.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImVwZ3VtZ2Rvc2V5dGhwY3B3Y3hzIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTUwMzU2NDcsImV4cCI6MjAzMDYxMTY0N30.GSQusnTBQruoLx5m_ghRMCp_SzBpGk1FaF38bJ67Chw');

// const Navigation = () => {
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [isScrolled, setIsScrolled] = useState(false);
//   const [session, setSession] = useState(null);

//   useEffect(() => {
//     supabase.auth.getSession().then(({ data: { session } }) => {
//       setSession(session);
//     });

//     const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
//       setSession(session);
//     });

//     return () => subscription.unsubscribe();
//   }, []);

//   const toggleModal = () => {
//     setIsModalOpen(!isModalOpen);
//   };

//   const handleGoogleSignIn = async () => {
//     const { error } = await supabase.auth.signInWithOAuth({ provider: 'google' });
//     if (error) console.log('Error signing in with Google:', error.message);
//   };

//   const navbarClasses = `fixed top-0 left-0 w-full z-20 ${
//     isScrolled ? 'backdrop-blur-sm bg-bb' : 'backdrop-blur-lg bg-[#ffffffa2]'
//   } transition-opacity duration-300 ease-in-out`;

//   return (
//     <div className="nav-container">
//       <Navbar fluid={true} rounded={true} className={navbarClasses}>
//         <div className="container banner-container mx-auto px-4 flex justify-between items-center">
//           <div className="flex items-center">
//             <Link to="/" className="self-center whitespace-nowrap text-md md:text-2xl font-semibold text-primary">
//               <span style={{ fontWeight: 'semibold' }}>SERENOTY</span>&nbsp;<span style={{ fontWeight: 100, opacity: 0.4 }}>AI</span>
//             </Link>
//           </div>
//           <div className="flex items-center md:hidden py-2 space-x-2">
//             <button
//               type="button"
//               className="inline-flex items-center px-2.5 h-[1.9rem] py-2 border bg-[#edebe7] hover:bg-opacity-50 border-transparent text-sm font-semibold rounded-md text-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
//               onClick={toggleModal}
//             >
//               Sign In
//             </button>
//             <button
//               type="button"
//               className="inline-flex items-center px-2.5 h-[1.9rem] py-2 border bg-bb hover:bg-opacity-50 border-transparent text-sm font-semibold rounded-md text-tt focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
//               onClick={toggleModal}
//             >
//               Sign Up
//             </button>
//           </div>
//           <div className="md:flex items-center space-x-2 hidden md:flex">
//             <button
//               type="button"
//               className="inline-flex items-center px-2.5 h-[1.9rem] py-2 border bg-[#edebe7] hover:bg-opacity-50 border-transparent text-sm font-semibold rounded-md text-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
//               onClick={toggleModal}
//             >
//               Sign In
//             </button>
//             <button
//               type="button"
//               className="inline-flex items-center px-2.5 h-[1.9rem] py-2 border bg-bb hover:bg-opacity-50 border-transparent text-sm font-semibold rounded-md text-tt focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
//               onClick={toggleModal}
//             >
//               Sign Up
//             </button>
//           </div>
//         </div>
//       </Navbar>

//       {isModalOpen && (
//         <div className="fixed inset-0 z-50 flex justify-center items-center bg-black bg-opacity-50">
//           <div className="bg-white p-6 rounded-2xl relative max-w-sm w-full">
//             <button
//               className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
//               onClick={toggleModal}
//             >
//               <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//                 <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
//               </svg>
//             </button>
//             <h2 className="mb-4 text-center text-bb">Sign Up / Sign In</h2>
//             <div className="mb-4 flex items-center justify-center">
//               <hr className="border-gray-300 w-full" />
//             </div>
//             <button
//               onClick={handleGoogleSignIn}
//               className="bg-hover1 text-primary px-4 py-2 rounded-2xl mb-4 w-full flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-opacity-50 hover:bg-opacity-90 hover:transform hover:scale-105 transition duration-300 ease-in-out"
//             >
//               <span className="text-2xl font-bold mr-4 ml-0">G</span>
//               <span className="mx-auto font-medium font-semibold">Continue with Google</span>
//             </button>
//             <div className="mb-4 flex items-center justify-center">
//               <hr className="border-gray-300 w-full" />
//               <span className="text-gray-500 mx-4">or</span>
//               <hr className="border-gray-300 w-full" />
//             </div>
//             <input
//               type="email"
//               className="border font-medium font-semibold border-gray-300 rounded-2xl px-4 py-2 mb-4 w-full focus:outline-none focus:ring-2 focus:ring-gray-300 focus:border-transparent"
//               placeholder="Email"
//             />
//             <button className="bg-bb text-white px-4 py-2 rounded-2xl mb-4 w-full focus:outline-none focus:ring-2 font-medium font-semibold focus:ring-primary focus:ring-opacity-50 hover:transform hover:scale-105 transition duration-300 ease-in-out">
//               Continue with email
//             </button>
//             <div className="text-sm text-gray-500 text-center">
//               <a href="#" className="hover:underline">Privacy</a>
//               <span className="mx-2">•</span>
//               <a href="#" className="hover:underline">Terms</a>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Navigation;








// // import React, { useState } from 'react';
// // import { Navbar } from "flowbite-react";
// // import serenotyLogo from "../../assets/serenotylogo.png";

// // const Navigation = () => {
// //   const [isModalOpen, setIsModalOpen] = useState(false);
// //   const [email, setEmail] = useState('');
// //   const [isCodeSent, setIsCodeSent] = useState(false);
// //   const [isLoading, setIsLoading] = useState(false);

// //   const toggleModal = () => {
// //     setIsModalOpen(!isModalOpen);
// //   };


// //   return (
// //     <div>
// //      <Navbar fluid={true} rounded={true} className={navbarClasses}>
// //    <div className="container banner-container bg-[#f8f6f3] mx-auto px-4 flex justify-between items-center">
// //         <div className="flex items-center">
// //         <img src={serenotyLogo} alt="Serenoty Logo" className="h-8 w-auto md:h-10 md:w-auto mr-2" />
// //            <span className="self-center whitespace-nowrap text-md md:text-2xl font-semibold text-primary">
// //               serenoty            </span>
// //           </div>
// //   <div className="flex items-center md:hidden py-2"> 
// //   <button
// //     type="button"
// //     className="inline-flex items-center px-2.5 h-[1.9rem] py-1.5 mr-2 border border-transparent text-xs md:text-sm font-semibold rounded-md text-primary hover:bg-secondary hover:text-primary  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
// //     onClick={handlePricingClick}
// //   >
// //     About
// //   </button>

// //   <button
// //     type="button"
// //     className="inline-flex items-center px-2.5 h-[1.9rem] py-1.5 mr-2 border bg-secondary border-transparent text-xs md:text-sm font-semibold rounded-md text-primary  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white "
// //     onClick={handlePricingClick}
// //   >
// //     Log In
// //   </button>

// //   <button
// //     type="button"
// //     className="inline-flex items-center px-2.5 py-1.5 h-[1.9rem] border bg-primary border-transparent text-xs md:text-sm font-semibold rounded-md text-white  hover:bg-primary  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
// //     onClick={toggleModal}
// //   >
// //     Sign Up
// //   </button>
// // </div>



//           <div className="md:flex items-center space-x-2 hidden md:flex">
          

//         <button
//             type="button"
//             className="inline-flex items-center px-2.5 h-[1.9rem]  py-2 border border-transparent text-sm font-semibold rounded-md text-primary hover:bg-secondary hover:text-primary  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
//             onClick={toggleModal}
//           >
//             About
//           </button>
//           {/* Pricing Button */}
//           <button
//             type="button"
//             className="inline-flex items-center px-2.5 h-[1.9rem]  py-2 border border-transparent text-sm font-semibold rounded-md text-primary hover:bg-secondary hover:text-primary  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
//             onClick={toggleModal}
//           >
//           Contact
//           </button>


//      <button
//   type="button"
//   className="inline-flex items-center px-2.5 h-[1.9rem] py-2 border bg-secondary border-transparent text-sm font-semibold rounded-md text-primary  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white "
//   onClick={toggleModal}
// >
//   Log In
// </button>

// <button
//   type="button"
//   className="inline-flex items-center px-2.5 py-2 h-[1.9rem] border bg-primary border-transparent text-sm font-semibold rounded-md text-white  hover:bg-opacity-80  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
//   onClick={toggleModal}
// >
//   Sign Up
// </button>

//           </div>
//         </div>
//       </Navbar>

//       {isModalOpen && (
//   <div className="fixed inset-0 z-50 flex justify-center items-center bg-black bg-opacity-50">
//     <div className="bg-white p-6 rounded-2xl relative max-w-sm w-full">
//       <button
//         className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
//         onClick={toggleModal}
//       >
//         <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//           <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
//         </svg>
//       </button>
//       <h2 className="mb-4 text-center text-gray-500 ">Sign Up</h2>
   
//       <div className="mb-4 flex items-center justify-center">
//         <hr className="border-gray-300 w-full" />
//       </div>
//       {/* <button className="bg-secondary  text-primary px-4 py-2 rounded-2xl mb-4 w-full flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-opacity-50 hover:bg-opacity-90">
//   <span className="text-2xl font-bold mr-4 ml-0">G</span>
//   <span className="mx-auto">Continue with Google</span>
// </button> */}
// <button className="bg-secondary text-primary px-4 py-2 rounded-2xl mb-4 w-full flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-opacity-50 hover:bg-opacity-90 hover:transform hover:scale-105 transition duration-300 ease-in-out">
//   <span className="text-2xl font-bold mr-4 ml-0">G</span>
//   <span className="mx-auto font-medium font-semibold">Continue with Google</span>
// </button>

//       <div className="mb-4 flex items-center justify-center">
//         <hr className="border-gray-300 w-full" />
//         <span className="text-gray-500 mx-4">or</span>
//         <hr className="border-gray-300 w-full" />
//       </div>
//       <input
//         type="email"
//         className="border  font-medium font-semibold border-gray-300 rounded-2xl px-4 py-2 mb-4 w-full focus:outline-none focus:ring-2 focus:ring-gray-300 focus:border-transparent"
//         placeholder="Email"
//       />
//      <button className="bg-primary  text-white px-4 py-2 rounded-2xl mb-4 w-full focus:outline-none focus:ring-2   font-medium font-semibold focus:ring-primary focus:ring-opacity-50 r hover:transform hover:scale-105 transition duration-300 ease-in-out">Continue with email</button>

//       <div className="text-sm text-gray-500 text-center">
//         <a href="#" className="hover:underline">Privacy</a>
//         <span className="mx-2">•</span>
//         <a href="#" className="hover:underline">Terms</a>
//       </div>
//     </div>
//   </div>
// )}






//     </div>
//   );
// };

// export default Navigation;


//   const navbarClasses = `fixed top-0 left-0 w-full z-20 ${
//     isScrolled ? 'backdrop-blur-md bg-[#ffffff66]' : 'backdrop-blur-lg bg-[#ffffffa2]'
//   } transition-opacity duration-300 ease-in-out`;


//     const handleEmailChange = (e) => {
//     setEmail(e.target.value);
//   };

//   const handleContinue = () => {
//     if (!isCodeSent) {
//       // Send email with code
//       setIsCodeSent(true);
//       setIsLoading(true); // Show loader
//       // Simulating email sending delay
//       setTimeout(() => {
//         setIsLoading(false);
//       }, 2000); // Adjust this timeout as needed
//     } else {
//       // Verify code
//       // Assuming you have a function to verify code
//       // setIsCodeVerified(true);
//     }
//   };

//   return (
//     <div>
//       <Navbar fluid={true} rounded={true} className={navbarClasses}>
//         <div className="container mx-auto px-4 flex justify-between items-center">
//           <div className="flex items-center">
//             <img src={serenotyLogo} alt="Serenoty Logo" className="h-8 w-auto md:h-10 md:w-auto mr-2" />
//             <span className="self-center whitespace-nowrap text-md md:text-2xl font-semibold text-primary">serenoty</span>
//           </div>
//           <div className="md:flex items-center space-x-2 hidden md:flex">
//             <button
//               type="button"
//               className="inline-flex items-center px-2.5 h-[1.9rem] py-2 border border-transparent text-sm font-semibold rounded-md text-primary hover:bg-secondary hover:text-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
//               onClick={handlePricingClick}
//             >
//               About
//             </button>
//             {/* Pricing Button */}
//             <button
//               type="button"
//               className="inline-flex items-center px-2.5 h-[1.9rem] py-2 border border-transparent text-sm font-semibold rounded-md text-primary hover:bg-secondary hover:text-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
//               onClick={handlePricingClick}
//             >
//               Pricing
//             </button>
//             <button
//               type="button"
//               className="inline-flex items-center px-2.5 h-[1.9rem] py-2 border bg-secondary border-transparent text-sm font-semibold rounded-md text-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white "
//               onClick={handlePricingClick}
//             >
//               Log In
//             </button>
//             <button
//               type="button"
//               className="inline-flex items-center px-2.5 py-2 h-[1.9rem] border bg-primary border-transparent text-sm font-semibold rounded-md text-white hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
//               onClick={toggleModal}
//             >
//               Sign Up
//             </button>
//           </div>
//         </div>
//       </Navbar>
      

//       {isModalOpen && (
//         <div className="fixed inset-0 z-50 flex justify-center items-center bg-black bg-opacity-50">
//           <div className="bg-white p-6 rounded-2xl relative max-w-sm w-full">
//             <button
//               className="absolute top-2 left-2 text-gray-500 hover:text-gray-700"
//               onClick={toggleModal}
//             >
//               <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//                 <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
//               </svg>
//             </button>
//             <h2 className="mb-4 text-center text-gray-500 ">Sign Up</h2>
//             <div className="mb-4 flex items-center justify-center">
//               <hr className="border-gray-300 w-full" />
//             </div>
//             <button className="bg-secondary text-primary px-4 py-2 rounded-2xl mb-4 w-full flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-opacity-50 hover:bg-opacity-90 hover:transform hover:scale-105 transition duration-300 ease-in-out">
//               <span className="text-2xl font-bold mr-4 ml-0">G</span>
//               <span className="mx-auto font-medium font-semibold">Continue with Google</span>
//             </button>
//             <div className="mb-4 flex items-center justify-center">
//               <hr className="border-gray-300 w-full" />
//               <span className="text-gray-500 mx-4">or</span>
//               <hr className="border-gray-300 w-full" />
//             </div>
//             <input
//               type="email"
//               className="border font-medium font-semibold border-gray-300 rounded-2xl px-4 py-2 mb-4 w-full focus:outline-none focus:ring-2 focus:ring-gray-300 focus:border-transparent"
//               placeholder="Email"
//               value={email}
//               onChange={handleEmailChange}
//             />
//             <button
//               className="bg-primary text-white px-4 py-2 rounded-2xl mb-4 w-full focus:outline-none focus:ring-2 font-medium font-semibold focus:ring-primary focus:ring-opacity-50 hover:transform hover:scale-105 transition duration-300 ease-in-out"
//               onClick={handleContinue}
//             >
//               {isCodeSent ? (isLoading ? "Please check your email" : "Continue") : "Continue with email"}
//             </button>
//             <div className="text-sm text-gray-500 text-center">
//               <a href="#" className="hover:underline">Privacy</a>
//               <span className="mx-2">•</span>
//               <a href="#" className="hover:underline">Terms</a>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Navigation;

// import React, { useState } from 'react';
// import { Navbar } from "flowbite-react";
// import serenotyLogo from "../../assets/serenotylogo.png";

// const Navigation = () => {
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [email, setEmail] = useState('');
//   const [isCodeSent, setIsCodeSent] = useState(false);
//   const [isLoading, setIsLoading] = useState(false);
//   const [isScrolled, setIsScrolled] = useState(false); // Define isScrolled state

//   const toggleModal = () => {
//     setIsModalOpen(!isModalOpen);
//   };

//   const handleEmailChange = (e) => {
//     setEmail(e.target.value);
//   };

//   const handleContinue = () => {
//     if (!isCodeSent) {
//       // Send email with code
//       setIsCodeSent(true);
//       setIsLoading(true); // Show loader
//       // Simulating email sending delay
//       setTimeout(() => {
//         setIsLoading(false);
//       }, 2000); // Adjust this timeout as needed
//     } else {
//       // Verify code
//       // Assuming you have a function to verify code
//       // setIsCodeVerified(true);
//     }
//   };

//   const handlePricingClick = () => {
//     // Implement action for Pricing button click
//     console.log("Pricing clicked");
//   };

//   const navbarClasses = `fixed top-0 left-0 w-full z-20 ${
//     isScrolled ? 'backdrop-blur-md bg-[#ffffff66]' : 'backdrop-blur-lg bg-[#ffffffa2]'
//   } transition-opacity duration-300 ease-in-out`;

//   return (
//     <div>
//       <Navbar fluid={true} rounded={true} className={navbarClasses}>
//         <div className="container mx-auto px-4 flex justify-between items-center">
//           <div className="flex items-center">
//             <img src={serenotyLogo} alt="Serenoty Logo" className="h-8 w-auto md:h-10 md:w-auto mr-2" />
//             <span className="self-center whitespace-nowrap text-md md:text-2xl font-semibold text-primary">serenoty</span>
//           </div>
//           <div className="md:flex items-center space-x-2 hidden md:flex">
//             <button
//               type="button"
//               className="inline-flex items-center px-2.5 h-[1.9rem] py-2 border border-transparent text-sm font-semibold rounded-md text-primary hover:bg-secondary hover:text-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
//               onClick={handlePricingClick}
//             >
//               About
//             </button>
//             {/* Pricing Button */}
//             <button
//               type="button"
//               className="inline-flex items-center px-2.5 h-[1.9rem] py-2 border border-transparent text-sm font-semibold rounded-md text-primary hover:bg-secondary hover:text-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
//               onClick={handlePricingClick}
//             >
//               Pricing
//             </button>
//             <button
//               type="button"
//               className="inline-flex items-center px-2.5 h-[1.9rem] py-2 border bg-secondary border-transparent text-sm font-semibold rounded-md text-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white "

//               onClick={toggleModal}
//             >
//               Log In
//             </button>
//             <button
//               type="button"
//               className="inline-flex items-center px-2.5 py-2 h-[1.9rem] border bg-primary border-transparent text-sm font-semibold rounded-md text-white hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
//               onClick={toggleModal}
//             >
//               Sign Up
//             </button>
//           </div>
//         </div>
//       </Navbar>

//       {isModalOpen && (
//         <div className="fixed inset-0 z-50 flex justify-center items-center bg-black bg-opacity-50">
//           <div className="bg-white p-6 rounded-2xl relative max-w-sm w-full">
//             <button
//               className="absolute top-2 left-2 text-gray-500 hover:text-gray-700"
//               onClick={toggleModal}
//             >
//               <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//                 <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
//               </svg>
//             </button>
//             <h2 className="mb-4 text-center text-gray-500 ">Sign Up</h2>
//             <div className="mb-4 flex items-center justify-center">
//               <hr className="border-gray-300 w-full" />
//             </div>
//             <button className="bg-secondary text-primary px-4 py-2 rounded-2xl mb-4 w-full flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-opacity-50 hover:bg-opacity-90 hover:transform hover:scale-105 transition duration-300 ease-in-out">
//               <span className="text-2xl font-bold mr-4 ml-0">G</span>
//               <span className="mx-auto font-medium font-semibold">Continue with Google</span>
//             </button>
//             <div className="mb-4 flex items-center justify-center">
//               <hr className="border-gray-300 w-full" />
//               <span className="text-gray-500 mx-4">or</span>
//               <hr className="border-gray-300 w-full" />
//             </div>
//             <input
//               type="email"
//               className="border font-medium font-semibold border-gray-300 rounded-2xl px-4 py-2 mb-4 w-full focus:outline-none focus:ring-2 focus:ring-gray-300 focus:border-transparent"
//               placeholder="Email"
//               value={email}
//               onChange={handleEmailChange}
//             />
            
//             <button
//               className="bg-primary text-white px-4 py-2 rounded-2xl mb-4 w-full focus:outline-none focus:ring-2 font-medium font-semibold focus:ring-primary focus:ring-opacity-50 hover:transform hover:scale-105 transition duration-300 ease-in-out"
//               onClick={handleContinue}
//             >
//               {isCodeSent ? (isLoading ? "Please check your email" : "Continue") : "Continue with email"}
//             </button>

//             <div className="text-sm text-gray-500 text-center">
//               <a href="#" className="hover:underline">Privacy</a>
//               <span className="mx-2">•</span>
//               <a href="#" className="hover:underline">Terms</a>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Navigation;
