
// // External Dependencies
// import React, { useState, useEffect } from "react";
// import './hero.css';
// import Typewriter from 'typewriter-effect';

// // Assets
// import uoft from "../../assets/uoft.png";
// import uofc from "../../assets/uofc.png";
// import uofa from "../../assets/uofa.png";
// import ubc from "../../assets/ubc.png";
// import hero from "../../assets/scdemo.png";
// import hero2 from "../../assets/serenoty88.png";


// // Smooth scroll function
// const smoothScrollToBottom = (duration) => {
//   const start = window.pageYOffset;
//   const end = document.documentElement.scrollHeight - window.innerHeight;
//   const change = end - start;
//   let currentTime = 0;
//   const increment = 20;

//   const easeInOutQuad = (time, startValue, changeInValue, duration) => {
//     time /= duration / 2;
//     if (time < 1) return (changeInValue / 2) * time * time + startValue;
//     time--;
//     return (-changeInValue / 2) * (time * (time - 2) - 1) + startValue;
//   };

//   const animateScroll = () => {
//     currentTime += increment;
//     const val = easeInOutQuad(currentTime, start, change, duration);
//     window.scrollTo(0, val);
//     if (currentTime < duration) {
//       requestAnimationFrame(animateScroll);
//     }
//   };

//   animateScroll();
// };

// // Hero component
// const Hero = () => {
//   const [imageLoaded, setImageLoaded] = useState(false);

//   useEffect(() => {
//     // Set imageLoaded to true when the image is loaded
//     const handleImageLoad = () => {
//       setImageLoaded(true);
//     };
//     const img = new Image();
//     img.onload = handleImageLoad;
//     img.src = hero;

//     // Clean up listener
//     return () => {
//       img.onload = null;
//     };
//   }, []);

//   const waitlistCount = 379; // This can be dynamic if you're pulling from an API

//   const handleDragStart = (e) => {
//     // Calculate the initial position of the cursor and the element
//     const style = window.getComputedStyle(e.target, null);
//     const posX = parseInt(style.getPropertyValue("left"), 10) - e.clientX;
//     const posY = parseInt(style.getPropertyValue("top"), 10) - e.clientY;
  
//     // Add the data to the event to be used in the dragover handler
//     e.dataTransfer.setData("text/plain", `${posX},${posY}`);
//   };
  
//   // Drag end handler will not trigger scrolling
//   const handleDragEnd = (e) => {
//     // Prevent default behavior
//     e.preventDefault();
//     // Update the position of the element
//     const offset = e.dataTransfer.getData("text/plain").split(',');
//     const dm = document.getElementById('draggable-marker');
//     dm.style.left = (e.clientX + parseInt(offset[0], 10)) + 'px';
//     dm.style.top = (e.clientY + parseInt(offset[1], 10)) + 'px';
//   };




//   useEffect(() => {
//     const handleImageLoad = () => {
//       setImageLoaded(true);
//     };
//     const img = new Image();
//     img.onload = handleImageLoad;
//     img.src = hero;

//     return () => {
//       img.onload = null;
//     };
//   }, []);



// return (
//   <div className="hero-container bg-gray-100 flex flex-col items-center justify-center">
//     <div className="hero-content text-center max-w-4xl mt-20">
//       <h1 className="font-clashdisplay header-text text-5xl font-black tracking-tight leading-none text-gray-800 md:text-2xl lg:text-4xl dark:text-white mb-4">
//         <span className="static-text font-clashdisplay">Responsible AI for</span>
//         <span className="text-primary md:text-l lg:text-2xl font-clashdisplay-variable">
//           <Typewriter
//             options={{
//               strings: ['Researchers', 'Professionals', 'Writers', 'Students'],
//               autoStart: true,
//               loop: true,
//               deleteSpeed: 50,
//               delay: 75,
//               wrapperClassName: "text-primary font-clashdisplay-variable",
//               cursorClassName: "text-primary"
//             }}
//           />
//         </span>
//         <br />
//       </h1>
//       <p className="mb-8 text-lg lg:text-l sm:px-4 font-clashdisplay xl:px-8">
//         Harness the power of AI to enhance the way you digest information. For researchers, students, and professionals.
//       </p>
//       <span className="block mb-2 font-clashdisplay-variable">Endorsed by students and researchers from:</span>
//       <div className="flex justify-center items-center mb-8 lg:mb-16">
//         <img src={uoft} alt="University of Toronto Logo" className="w-1/6 sm:w-1/6 md:w-1/6 lg:w-1/6 xl:w-1/6 p-2" />
//         <img src={uofc} alt="University of Calgary Logo" className="w-1/6 sm:w-1/6 md:w-1/6 lg:w-1/6 xl:w-1/6 p-2" />
//         <img src={uofa} alt="University of Alberta Logo" className="w-1/6 sm:w-1/6 md:w-1/6 lg:w-1/6 xl:w-1/6 p-2" />
//         <img src={ubc} alt="University of British Columbia Logo" className="w-1/6 sm:w-1/6 md:w-1/6 lg:w-1/6 xl:w-1/6 p-2" />
//       </div>
//     </div>
//     <div className="hero-image mb-8 md:mb-16 max-w-3xl">
//       <div className={`border-4 border-gray-300 rounded-lg overflow-hidden transition-shadow duration-300 ${imageLoaded ? 'animate-pop-in shadow-md' : ''}`}>
//         <img
//           src={hero}
//           alt="A screenshot of the Serenoty platform interface"
//           className={`block w-full h-auto object-cover ${imageLoaded ? 'opacity-100' : 'opacity-0'}`}
//           onLoad={() => setImageLoaded(true)}
//         />
//       </div>
//     </div>
//   </div>
// );

// };

// export default Hero;

// import React, { useState, useEffect } from "react";
// import './hero.css';
// import Typewriter from 'typewriter-effect';

// import uoft from "../../assets/uoft.png";
// import uofc from "../../assets/uofc.png";
// import uofa from "../../assets/uofa.png";
// import ubc from "../../assets/ubc.png";
// import hero from "../../assets/snlogo.png";

// const Hero = () => {
//   const [imageLoaded, setImageLoaded] = useState(false);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const toggleModal = () => {
//     setIsModalOpen(!isModalOpen);
//   };
//   useEffect(() => {
//     const handleImageLoad = () => {
//       setImageLoaded(true);
//     };
//     const img = new Image();
//     img.onload = handleImageLoad;
//     img.src = hero;

//     return () => {
//       img.onload = null;
//     };
//   }, []);

//   return (
//     <div className="hero-container bg-gray-100 flex flex-col items-center justify-center">
//       <div className="hero-content text-center max-w-4xl mt-20">
//         <h1 className="font-clashdisplay tracking-widest header-text text-5xl font-black tracking-tight leading-none text-[#333] md:text-2xl lg:text-4xl dark:text-white mb-4">
//           <span className="static-text font-clashdisplay  font-bold tracking-widest text-[#333] text-4xl   mb-6  leading-10  md:text-l ">Responsible AI for</span>
//           <span className="text-secondary mt-10  md:text-sm lg:text-xl mt-50px tracking-widest leading-20 ">
//             <Typewriter
//               options={{
//                 strings: ['Researchers', 'Professionals', 'Writers', 'Students'],
//                 autoStart: true,
//                 loop: true,
//                 deleteSpeed: 50,
//                 delay: 75,
//                 wrapperClassName: " text-secondary",
//                 cursorClassName: "text-secondary"
//               }}
//             />
//           </span>
//           <br />
//         </h1>
//         {/* <p className="mb-8 text-lg lg:text-l  tracking-widest sm:px-4 text-[#333] xl:px-8">
//           Harness the power of AI to enhance the way you digest information. For researchers, students, and professionals.
//         </p> */}
//         <button
//   type="button"
//   className="inline-flex mb-10 tracking-widest leading-10 items-center px-3.5 py-2.5 h-[2rem] border bg-[#333] border-transparent text-xl md:text-sm font-semibold rounded-xl text-white  hover:opacity-60 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"

// >
// Get started for free
// </button>
//         <span className="block mb-2  tracking-widest ">Endorsed by students and researchers from:</span>
//         <div className="flex justify-center items-center mb-8 lg:mb-16">
//           <img src={uoft} alt="University of Toronto Logo" className="w-1/6 sm:w-1/6 md:w-1/6 lg:w-1/6 xl:w-1/6 p-2" />
//           <img src={uofc} alt="University of Calgary Logo" className="w-1/6 sm:w-1/6 md:w-1/6 lg:w-1/6 xl:w-1/6 p-2" />
//           <img src={uofa} alt="University of Alberta Logo" className="w-1/6 sm:w-1/6 md:w-1/6 lg:w-1/6 xl:w-1/6 p-2" />
//           <img src={ubc} alt="University of British Columbia Logo" className="w-1/6 sm:w-1/6 md:w-1/6 lg:w-1/6 xl:w-1/6 p-2" />
//         </div>
//       </div>
//       <div className="hero-image mb-8 md:mb-16 max-w-5xl">
//         <div className={`border-8 border-gray-300 rounded-xl overflow-hidden transition-shadow duration-300 ${imageLoaded ? 'animate-pop-in shadow-md' : ''}`}>
//           <img
//             src={hero}
//             alt="A screenshot of the Serenoty platform interface"
//             className={`block w-full h-auto object-cover ${imageLoaded ? 'opacity-100' : 'opacity-0'}`}
//             onLoad={() => setImageLoaded(true)}
//           />
//         </div>
//       </div>
      
//     </div>
//   );
// };

// export default Hero;



// import React, { useState, useEffect } from "react";
// import './hero.css';
// import Typewriter from 'typewriter-effect';

// import uoft from "../../assets/uoft.png";
// import uofc from "../../assets/uofc.png";
// import uofa from "../../assets/uofa.png";
// import ubc from "../../assets/ubc.png";
// import hero from "../../assets/snlogo.png";

// const Hero = () => {
//   const [imageLoaded, setImageLoaded] = useState(false);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const toggleModal = () => {
//     setIsModalOpen(!isModalOpen);
//   };
//   useEffect(() => {
//     const handleImageLoad = () => {
//       setImageLoaded(true);
//     };
//     const img = new Image();
//     img.onload = handleImageLoad;
//     img.src = hero;

//     return () => {
//       img.onload = null;
//     };
//   }, []);

//   return (
//     <div className="hero-container bg-gray-100 flex flex-col items-center justify-center">
//       <div className="hero-content text-center max-w-4xl mt-20">
//         <h1 className="font-clashdisplay tracking-widest header-text text-5xl font-black tracking-tight leading-none text-[#333] md:text-2xl lg:text-4xl dark:text-white mb-4">
//           <span className="static-text font-clashdisplay  font-bold tracking-widest text-[#333] text-4xl   mb-6  leading-10  md:text-l ">Responsible AI for</span>
//           <span className="text-secondary mt-10 sm:text-xs md:text-sm lg:text-xl mt-50px tracking-widest leading-20 ">
//   <Typewriter
//     options={{
//       strings: ['Researchers', 'Professionals', 'Writers', 'Students'],
//       autoStart: true,
//       loop: true,
//       deleteSpeed: 50,
//       delay: 75,
//       wrapperClassName: "text-secondary",
//       cursorClassName: "text-secondary"
//     }}
//   />
// </span>

//           <br />
//         </h1>
//         <button
//           type="button"
//           className="inline-flex mb-10 tracking-widest leading-10 items-center px-3.5 py-2.5 h-[2rem] border bg-[#333] border-transparent text-xl md:text-sm font-semibold rounded-xl text-white  hover:opacity-60 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
//           onClick={toggleModal}
//         >
//           Get started for free
//         </button>
//         <span className="block mb-2  tracking-widest ">Endorsed by students and researchers from:</span>
//         <div className="flex justify-center items-center mb-8 lg:mb-16">
//           <img src={uoft} alt="University of Toronto Logo" className="w-1/6 sm:w-1/6 md:w-1/6 lg:w-1/6 xl:w-1/6 p-2" />
//           <img src={uofc} alt="University of Calgary Logo" className="w-1/6 sm:w-1/6 md:w-1/6 lg:w-1/6 xl:w-1/6 p-2" />
//           <img src={uofa} alt="University of Alberta Logo" className="w-1/6 sm:w-1/6 md:w-1/6 lg:w-1/6 xl:w-1/6 p-2" />
//           <img src={ubc} alt="University of British Columbia Logo" className="w-1/6 sm:w-1/6 md:w-1/6 lg:w-1/6 xl:w-1/6 p-2" />
//         </div>
//       </div>
//       <div className="hero-image mb-8 md:mb-16 max-w-5xl">
//         <div className={`border-8 border-gray-300 rounded-xl overflow-hidden transition-shadow duration-300 ${imageLoaded ? 'animate-pop-in shadow-md' : ''}`}>
//           <img
//             src={hero}
//             alt="A screenshot of the Serenoty platform interface"
//             className={`block w-full h-auto object-cover ${imageLoaded ? 'opacity-100' : 'opacity-0'}`}
//             onLoad={() => setImageLoaded(true)}
//           />
//         </div>
//       </div>
//       {/* Render the modal only if isModalOpen is true */}
//       {isModalOpen && (
//         <div className="fixed inset-0 z-50 flex justify-center items-center bg-black bg-opacity-50">
//           <div className="bg-white p-6 rounded-2xl relative max-w-sm w-full">
//             <button
//               className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
//               onClick={toggleModal}
//             >
//               <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//                 <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
//               </svg>
//             </button>
//             <h2 className="mb-4 text-center text-gray-500">Sign Up</h2>
        
//             <div className="mb-4 flex items-center justify-center">
//               <hr className="border-gray-300 w-full" />
//             </div>
//             <button className="bg-secondary text-primary px-4 py-2 rounded-2xl mb-4 w-full flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-opacity-50 hover:bg-opacity-90 hover:transform hover:scale-105 transition duration-300 ease-in-out">
//               <span className="text-2xl font-bold mr-4 ml-0">G</span>
//               <span className="mx-auto font-medium font-semibold">Continue with Google</span>
//             </button>
        
//             <div className="mb-4 flex items-center justify-center">
//               <hr className="border-gray-300 w-full" />
//               <span className="text-gray-500 mx-4">or</span>
//               <hr className="border-gray-300 w-full" />
//             </div>
//             <input
//               type="email"
//               className="border font-medium font-semibold border-gray-300 rounded-2xl px-4 py-2 mb-4 w-full focus:outline-none focus:ring-2 focus:ring-gray-300 focus:border-transparent"
//               placeholder="Email"
//             />
//             <button className="bg-primary text-white px-4 py-2 rounded-2xl mb-4 w-full focus:outline-none focus:ring-2 font-medium font-semibold focus:ring-primary focus:ring-opacity-50 hover:transform hover:scale-105 transition duration-300 ease-in-out">Continue with email</button>
        
//             <div className="text-sm text-gray-500 text-center">
//               <a href="#" className="hover:underline">Privacy</a>
//               <span className="mx-2">•</span>
//               <a href="#" className="hover:underline">Terms</a>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };
//   return (
//     <div className="hero-container bg-gray-100 flex flex-col items-center justify-center">
//       <div className="hero-content text-center max-w-4xl mt-20">
//         <h1 className="font-clashdisplay tracking-widest header-text text-5xl font-black tracking-tight leading-none text-[#333] md:text-2xl lg:text-4xl dark:text-white mb-4">
//           <span className="static-text font-clashdisplay font-bold tracking-widest text-[#333] text-4xl mb-6 leading-10 md:text-l">
//             Responsible AI for
//           </span>
//           <span className="text-secondary mt-10 sm:text-xs md:text-xs lg:text-xl mt-50px tracking-widest leading-20">
//             <Typewriter
//               options={{
//                 strings: ["Researchers", "Professionals", "Writers", "Students"],
//                 autoStart: true,
//                 loop: true,
//                 deleteSpeed: 50,
//                 delay: 75,
//                 wrapperClassName: "text-secondary",
//                 cursorClassName: "text-secondary",
//               }}
//               className="text-xs md:text-xs" // Adjust font size here
//               containerClassName="typewriter-container" // Add a class for styling
//             />
//           </span>

//           <br />
//         </h1>
//         <button
//           type="button"
//           className="inline-flex mb-10 tracking-widest leading-10 items-center px-3.5 py-2.5 h-[2rem] border bg-[#333] border-transparent text-xl md:text-sm font-semibold rounded-xl text-white hover:opacity-60 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
//           onClick={toggleModal}
//         >
//           Get started for free
//         </button>
//         <span className="block mb-2 tracking-widest">
//           Endorsed by students and researchers from:
//         </span>
//         <div className="flex justify-center items-center mb-8 lg:mb-16">
//           <img
//             src={uoft}
//             alt="University of Toronto Logo"
//             className="w-1/6 sm:w-1/6 md:w-1/6 lg:w-1/6 xl:w-1/6 p-2"
//           />
//           <img
//             src={uofc}
//             alt="University of Calgary Logo"
//             className="w-1/6 sm:w-1/6 md:w-1/6 lg:w-1/6 xl:w-1/6 p-2"
//           />
//           <img
//             src={uofa}
//             alt="University of Alberta Logo"
//             className="w-1/6 sm:w-1/6 md:w-1/6 lg:w-1/6 xl:w-1/6 p-2"
//           />
//           <img
//             src={ubc}
//             alt="University of British Columbia Logo"
//             className="w-1/6 sm:w-1/6 md:w-1/6 lg:w-1/6 xl:w-1/6 p-2"
//           />
//         </div>
//       </div>
//       <div className="hero-image mb-8 md:mb-16 max-w-5xl">
//         <div
//           className={`border-8 border-gray-300 rounded-xl overflow-hidden transition-shadow duration-300 ${
//             imageLoaded ? "animate-pop-in shadow-md" : ""
//           }`}
//         >
//           <img
//             src={hero}
//             alt="A screenshot of the Serenoty platform interface"
//             className={`block w-full h-auto object-cover ${
//               imageLoaded ? "opacity-100" : "opacity-0"
//             }`}
//             onLoad={() => setImageLoaded(true)}
//           />
//         </div>
//       </div>
//       {/* Render the modal only if isModalOpen is true */}
//       {isModalOpen && (
//         <div className="fixed inset-0 z-50 flex justify-center items-center bg-black bg-opacity-50">
//           <div className="bg-white p-6 rounded-2xl relative max-w-sm w-full">
//             <button
//               className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
//               onClick={toggleModal}
//             >
//               <svg
//                 className="w-6 h-6"
//                 fill="none"
//                 viewBox="0 0 24 24"
//                 stroke="currentColor"
//               >
//                 <path
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                   strokeWidth={2}
//                   d="M6 18L18 6M6 6l12 12"
//                 />
//               </svg>
//             </button>
//             <h2 className="mb-4 text-center text-gray-500">Sign Up</h2>

//             <div className="mb-4 flex items-center justify-center">
//               <hr className="border-gray-300 w-full" />
//             </div>
//             <button className="bg-secondary text-primary px-4 py-2 rounded-2xl mb-4 w-full flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-opacity-50 hover:bg-opacity-90 hover:transform hover:scale-105 transition duration-300 ease-in-out">
//               <span className="text-2xl font-bold mr-4 ml-0">G</span>
//               <span className="mx-auto font-medium font-semibold">
//                 Continue with Google
//               </span>
//             </button>

//             <div className="mb-4 flex items-center justify-center">
//               <hr className="border-gray-300 w-full" />
//               <span className="text-gray-500 mx-4">or</span>
//               <hr className="border-gray-300 w-full" />
//             </div>
//             <input
//               type="email"
//               className="border font-medium font-semibold border-gray-300 rounded-2xl px-4 py-2 mb-4 w-full focus:outline-none focus:ring-2 focus:ring-gray-300 focus:border-transparent"
//               placeholder="Email"
//             />
//             <button className="bg-primary text-white px-4 py-2 rounded-2xl mb-4 w-full focus:outline-none focus:ring-2 font-medium font-semibold focus:ring-primary focus:ring-opacity-50 hover:transform hover:scale-105 transition duration-300 ease-in-out">
//               Continue with email
//             </button>

//             <div className="text-sm text-gray-500 text-center">
//               <a href="#" className="hover:underline">
//                 Privacy
//               </a>
//               <span className="mx-2">•</span>
//               <a href="#" className="hover:underline">
//                 Terms
//               </a>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Hero
// export default Hero;




// import React, { useState, useEffect } from "react";
// import "./hero.css";
// import Typewriter from "typewriter-effect";

// import uoft from "../../assets/uoft.png";
// import uofc from "../../assets/uofc.png";
// import uofa from "../../assets/uofa.png";
// import ubc from "../../assets/ubc.png";
// import hero from "../../assets/snlogo.png";

// const Hero = () => {
//   const [imageLoaded, setImageLoaded] = useState(false);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const toggleModal = () => {
//     setIsModalOpen(!isModalOpen);
//   };
//   useEffect(() => {
//     const handleImageLoad = () => {
//       setImageLoaded(true);
//     };
//     const img = new Image();
//     img.onload = handleImageLoad;
//     img.src = hero;

//     return () => {
//       img.onload = null;
//     };
//   }, []);

// ;


// return (
//   <div className="hero-container  flex flex-col items-center justify-center">
//     <div className="hero-content text-center max-w-4xl mt-20">
//       <h1 className=" tracking-widest header-text text-5xl font-black tracking-tight leading-none text-[#333] md:text-2xl lg:text-4xl dark:text-white mb-4">
//         <div className="mb-6"> {/* Add margin-bottom here */}
//         <span className="static-text font-clashdisplay font-bold tracking-widest text-gray-800 text-2xl md:text-4xl mb-6 leading-snug md:leading-normal">
//         The intelligent library for  
// </span>

//     </div>
    
//     <div className="typewriter-container">
//           <Typewriter
//             options={{
//               strings: ["researchers", "writers", "students", "analysts", "educators", "journalists", "lawyers", "scientists"],
//               autoStart: true,
//               loop: true,
//               deleteSpeed: 50,
//               delay: 75,
//               wrapperClassName: "text-secondary text-xl sm:text-lg md:text-2xl lg:text-3xl xl:text-4xl", // Adjust font size here for responsiveness
//               cursorClassName: "text-secondary text-xl sm:text-lg md:text-2xl lg:text-3xl xl:text-4xl",
//             }}
//             className="typewriter-text" // Add a class for styling
//           />
//         </div>

//         <br />
//       </h1>
//       <button
//     type="button"
//     className="inline-flex mb-10 tracking-widest leading-10 items-center px-3.5 py-2.5 h-[2rem] border bg-bb border-transparent text-xl md:text-sm font-semibold rounded-xl text-white hover:opacity-60 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
//     onClick={() => window.location.href='https://fllky8j5bmm.typeform.com/to/WHfbZpRJ'}
// >
//     Apply for early access
// </button>

//       <div className="hero-image mb-8 md:mb-16 max-w-md md:max-w-5xl">
//   <div
//     className={`border-4  rounded-2xl border-secondary overflow-hidden transition-shadow duration-300 ${
//       imageLoaded ? "animate-pop-in shadow-md" : ""
//     }`}
//   >
//     <img
//       src={hero}
//       alt="A screenshot of the Serenity platform interface"
//       className={`block w-full md:w-auto h-auto object-cover transition-opacity duration-300 ${
//         imageLoaded ? "opacity-100" : "opacity-0"
//       }`}
//       onLoad={() => setImageLoaded(true)}
//     />
//   </div>
// </div>
// <span className="text-xl sm:text-sm md:text-3xl font-extrabold pb-5 text-[#333] block mb-2 tracking-widest">
//   Endorsed by researchers and students from 
// </span>

//       <div className="flex justify-center items-center mb-8 lg:mb-16">
//         <img
//           src={uoft}
//           alt="University of Toronto Logo"
//           className="w-1/6 sm:w-1/6 md:w-1/6 lg:w-1/6 xl:w-1/6 p-2"
//         />
//         <img
//           src={uofc}
//           alt="University of Calgary Logo"
//           className="w-1/6 sm:w-1/6 md:w-1/6 lg:w-1/6 xl:w-1/6 p-2"
//         />
//         <img
//           src={uofa}
//           alt="University of Alberta Logo"
//           className="w-1/6 sm:w-1/6 md:w-1/6 lg:w-1/6 xl:w-1/6 p-2"
//         />
//         <img
//           src={ubc}
//           alt="University of British Columbia Logo"
//           className="w-1/6 sm:w-1/6 md:w-1/6 lg:w-1/6 xl:w-1/6 p-2"
//         />
//       </div>
//     </div>
   
   
    
//     {/* Render the modal only if isModalOpen is true */}
//     {isModalOpen && (
//       <div className="fixed inset-0 z-50 flex justify-center items-center bg-bb bg-opacity-50">
//         <div className="bg-white p-6 rounded-2xl relative max-w-sm w-full">
//           <button
//             className="absolute top-2 right-2 text-secondary hover:text-gray-700"
//             onClick={toggleModal}
//           >
//             <svg
//               className="w-6 h-6"
//               fill="none"
//               viewBox="0 0 24 24"
//               stroke="currentColor"
//             >
//               <path
//                 strokeLinecap="round"
//                 strokeLinejoin="round"
//                 strokeWidth={2}
//                 d="M6 18L18 6M6 6l12 12"
//               />
//             </svg>
//           </button>
//           <h2 className="mb-4 text-center text-gray-500">Sign Up</h2>

//           <div className="mb-4 flex items-center justify-center">
//             <hr className="border-gray-300 w-full" />
//           </div>
//           <button className="bg-tt text-primary px-4 py-2 rounded-2xl mb-4 w-full flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-opacity-50 hover:bg-opacity-90 hover:transform hover:scale-105 transition duration-300 ease-in-out">
//             <span className="text-2xl font-bold mr-4 ml-0">G</span>
//             <span className="mx-auto font-medium font-semibold">
//               Continue with Google
//             </span>
//           </button>

//           <div className="mb-4 flex items-center justify-center">
//             <hr className="border-gray-300 w-full" />
//             <span className="text-gray-500 mx-4">or</span>
//             <hr className="border-gray-300 w-full" />
//           </div>
//           <input
//             type="email"
//             className="border font-medium font-semibold border-gray-300 rounded-2xl px-4 py-2 mb-4 w-full focus:outline-none focus:ring-2 focus:ring-gray-300 focus:border-transparent"
//             placeholder="Email"
//           />
//           <button className="bg-bb text-white px-4 py-2 rounded-2xl mb-4 w-full focus:outline-none focus:ring-2 font-medium font-semibold focus:ring-primary focus:ring-opacity-50 hover:transform hover:scale-105 transition duration-300 ease-in-out">
//             Continue with email
//           </button>

//           <div className="text-sm text-gray-500 text-center">
//             <a href="#" className="hover:underline">
//               Privacy
//             </a>
//             <span className="mx-2">•</span>
//             <a href="#" className="hover:underline">
//               Terms
//             </a>
//           </div>
//         </div>
//       </div>
//     )}
//   </div>
// );
// };

// export default Hero;

// import React, { useState, useEffect } from "react";
// import "./hero.css";
// import Typewriter from "typewriter-effect";

// import uoft from "../../assets/uoft.png";
// import uofc from "../../assets/uofc.png";
// import uofa from "../../assets/uofa.png";
// import ubc from "../../assets/ubc.png";
// import hero from "../../assets/snlogo.png";
// import { Syringe, Briefcase, Microscope, BookOpenCheck } from "lucide-react";

// const Hero = () => {
//   const [imageLoaded, setImageLoaded] = useState(false);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const toggleModal = () => {
//     setIsModalOpen(!isModalOpen);
//   };

//   useEffect(() => {
//     const handleImageLoad = () => {
//       setImageLoaded(true);
//     };
//     const img = new Image();
//     img.onload = handleImageLoad;
//     img.src = hero;

//     return () => {
//       img.onload = null;
//     };
//   }, []);

//   return (
//     <div className="hero-container flex flex-col items-center justify-center pb-10">
//       <div className="hero-content text-center max-w-4xl mt-20">
//         <h1 className="tracking-widest header-text text-5xl font-black tracking-tight leading-none text-[#333] md:text-2xl lg:text-4xl dark:text-white mb-4">
//           <div className="mb-6">
//             <span className="static-text font-clashdisplay font-bold tracking-widest text-gray-800 text-2xl md:text-4xl mb-6 leading-snug md:leading-normal">
//               The intelligent library for
//             </span>
//           </div>
//           <div className="typewriter-container">
//             <Typewriter
//               options={{
//                 strings: [
//                   "researchers",
//                   "writers",
//                   "students",
//                   "analysts",
//                   "educators",
//                   "journalists",
//                   "lawyers",
//                   "scientists",
//                 ],
//                 autoStart: true,
//                 loop: true,
//                 deleteSpeed: 50,
//                 delay: 75,
//                 wrapperClassName:
//                   "text-secondary pb-10  text-xl sm:text-lg md:text-2xl lg:text-3xl xl:text-4xl",
//                 cursorClassName:
//                   "text-secondary pb-10 text-xl sm:text-lg md:text-2xl lg:text-3xl xl:text-4xl",
//               }}
//               className="typewriter-text"
//             />
//           </div>
//         </h1>
//         <button
//           type="button"
//           className="inline-flex mb-10 tracking-widest  leading-10 items-center px-3.5 py-2.5 h-[2rem] border bg-bb border-transparent text-xl md:text-sm font-semibold rounded-xl text-white hover:opacity-60 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
//           onClick={() =>
//             (window.location.href =
//               "https://fllky8j5bmm.typeform.com/to/WHfbZpRJ")
//           }
//         >
//           Apply for early access
//         </button>

//         <div className="hero-image mb-8 md:mb-16 max-w-md md:max-w-5xl">
//           <div
//             className={`border-4 rounded-2xl border-tt overflow-hidden transition-shadow duration-300 ${
//               imageLoaded ? "animate-pop-in shadow-md" : ""
//             }`}
//           >
//             <img
//               src={hero}
//               alt="A screenshot of the Serenity platform interface"
//               className={`block w-full md:w-auto h-auto object-cover transition-opacity duration-300 ${
//                 imageLoaded ? "opacity-100" : "opacity-0"
//               }`}
//               onLoad={() => setImageLoaded(true)}
//             />
//           </div>
//         </div>
//         <section className="flex flex-col md:flex-row items-stretch space-y-8 md:space-y-0 md:space-x-4 mt-8">
//           <div className="w-full md:w-1/4 relative">
//             <div className="bg-[#E6F7F9] border border-tt rounded-2xl overflow-hidden p-6 text-center h-full">
//               <div className="mb-4">
//                 <Syringe className="mx-auto text-secondary" size={48} />
//               </div>
//               <h3 className="text-xl font-semibold text-[#333]">Corporate, Pharma & Enterprise</h3>
//               <p className="mt-2 text-[#333] text-opacity-60">Stay on top of drug development & efficacy, clinical trials, and more</p>
//             </div>
//           </div>
//           <div className="w-full md:w-1/4 relative">
//             <div className="bg-[#F7E6F9] border border-tt rounded-2xl overflow-hidden p-6 text-center h-full">
//               <div className="mb-4">
//                 <Briefcase className="mx-auto text-secondary" size={48} />
//               </div>
//               <h3 className="text-xl font-semibold text-[#333]">Universities & Governments</h3>
//               <p className="mt-2 text-[#333] text-opacity-60">Track and improve research outcomes across your organization</p>
//             </div>
//           </div>
//           <div class="w-full md:w-1/4 relative">
//             <div class="bg-[#F9E6F7] border border-tt rounded-2xl overflow-hidden p-6 text-center h-full">
//               <div class="mb-4">
//                 <Microscope class="mx-auto text-secondary" size={48} />
//               </div>
//               <h3 class="text-xl font-semibold text-[#333]">Researchers</h3>
//               <p class="mt-2 text-[#333] text-opacity-60">See exactly how publications have been cited by newer research</p>
//             </div>
//           </div>
//           <div class="w-full md:w-1/4 relative">
//             <div class="bg-[#F9EDE6] border border-tt rounded-2xl overflow-hidden p-6 text-center h-full">
//               <div class="mb-4">
//                 <BookOpenCheck class="mx-auto text-secondary" size={48} />
//               </div>
//               <h3 class="text-xl font-semibold text-[#333]">Publishers</h3>
//               <p class="mt-2 text-[#333] text-opacity-60">Improve discoverability of your publications and support your authors</p>
//             </div>
//           </div>
//         </section>

        
//       </div>

//       {isModalOpen && (
//         <div className="fixed inset-0 z-50 flex justify-center items-center bg-bb bg-opacity-50">
//           <div className="bg-white p-6 rounded-2xl relative max-w-sm w-full">
//             <button
//               className="absolute top-2 right-2 text-secondary hover:text-gray-700"
//               onClick={toggleModal}
//             >
//               <svg
//                 className="w-6 h-6"
//                 fill="none"
//                 viewBox="0 0 24 24"
//                 stroke="currentColor"
//               >
//                 <path
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                   strokeWidth={2}
//                   d="M6 18L18 6M6 6l12 12"
//                 />
//               </svg>
//             </button>
//             <h2 className="mb-4 text-center text-gray-500">Sign Up</h2>

//             <div className="mb-4 flex items-center justify-center">
//               <hr className="border-gray-300 w-full" />
//             </div>
//             <button className="bg-tt text-primary px-4 py-2 rounded-2xl mb-4 w-full flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-opacity-50 hover:bg-opacity-90 hover:transform hover:scale-105 transition duration-300 ease-in-out">
//               <span className="text-2xl font-bold mr-4 ml-0">G</span>
//               <span className="mx-auto font-medium font-semibold">
//                 Continue with Google
//               </span>
//             </button>

//             <div className="mb-4 flex items-center justify-center">
//               <hr className="border-gray-300 w-full" />
//               <span className="text-gray-500 mx-4">or</span>
//               <hr className="border-gray-300 w-full" />
//             </div>
//             <input
//               type="email"
//               className="border font-medium font-semibold border-gray-300 rounded-2xl px-4 py-2 mb-4 w-full focus:outline-none focus:ring-2 focus:ring-gray-300 focus:border-transparent"
//               placeholder="Email"
//             />
//             <button className="bg-bb text-white px-4 py-2 rounded-2xl mb-4 w-full focus:outline-none focus:ring-2 font-medium font-semibold focus:ring-primary focus:ring-opacity-50 hover:transform hover:scale-105 transition duration-300 ease-in-out">
//               Continue with email
//             </button>

//             <div className="text-sm text-gray-500 text-center">
//               <a href="#" className="hover:underline">
//                 Privacy
//               </a>
//               <span className="mx-2">•</span>
//               <a href="#" className="hover:underline">
//                 Terms
//               </a>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Hero;


// import React, { useState, useEffect } from "react";
// import "./hero.css";
// import Typewriter from "typewriter-effect";

// import uoft from "../../assets/uoft.png";
// import uofc from "../../assets/uofc.png";
// import uofa from "../../assets/uofa.png";
// import ubc from "../../assets/ubc.png";
// import hero from "../../assets/snlogo.png";
// import { Syringe, Briefcase, Microscope, BookOpenCheck } from "lucide-react";

// const Hero = () => {
//   const [imageLoaded, setImageLoaded] = useState(false);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const toggleModal = () => {
//     setIsModalOpen(!isModalOpen);
//   };

//   useEffect(() => {
//     const handleImageLoad = () => {
//       setImageLoaded(true);
//     };
//     const img = new Image();
//     img.onload = handleImageLoad;
//     img.src = hero;

//     return () => {
//       img.onload = null;
//     };
//   }, []);

//   return (
//     <div className="hero-container flex flex-col items-center justify-center pb-10">
//       <div className="hero-content text-center max-w-4xl mt-20">
//         <h1 className="tracking-widest header-text text-5xl font-black tracking-tight leading-none text-[#333] md:text-2xl lg:text-4xl dark:text-white mb-4">
//           <div className="mb-6">
//             <span className="static-text font-clashdisplay font-bold tracking-widest text-gray-800 text-2xl md:text-4xl mb-6 leading-snug md:leading-normal">
//               The intelligent library for
//             </span>
//           </div>
//           <div className="typewriter-container">
//             <Typewriter
//               options={{
//                 strings: [
//                   "researchers",
//                   "writers",
//                   "students",
//                   "analysts",
//                   "educators",
//                   "journalists",
//                   "lawyers",
//                   "scientists",
//                 ],
//                 autoStart: true,
//                 loop: true,
//                 deleteSpeed: 50,
//                 delay: 75,
//                 wrapperClassName:
//                   "text-secondary pb-10  text-xl sm:text-lg md:text-2xl lg:text-3xl xl:text-4xl",
//                 cursorClassName:
//                   "text-secondary pb-10 text-xl sm:text-lg md:text-2xl lg:text-3xl xl:text-4xl",
//               }}
//               className="typewriter-text"
//             />
//           </div>
//         </h1>
//         <button
//           type="button"
//           className="inline-flex mb-10 tracking-widest  leading-10 items-center px-3.5 py-2.5 h-[2rem] border bg-bb border-transparent text-xl md:text-sm font-semibold rounded-xl text-white hover:opacity-60 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
//           onClick={() =>
//             (window.location.href =
//               "https://fllky8j5bmm.typeform.com/to/WHfbZpRJ")
//           }
//         >
//           Apply for early access
//         </button>

//         <div className="hero-image mb-4 md:mb-8 max-w-md md:max-w-5xl">
//           <div
//             className={`border-4 rounded-2xl border-tt overflow-hidden transition-shadow duration-300 ${
//               imageLoaded ? "animate-pop-in shadow-md" : ""
//             }`}
//           >
//             <img
//               src={hero}
//               alt="A screenshot of the Serenity platform interface"
//               className={`block w-full md:w-auto h-auto object-cover transition-opacity duration-300 ${
//                 imageLoaded ? "opacity-100" : "opacity-0"
//               }`}
//               onLoad={() => setImageLoaded(true)}
//             />
//           </div>
//         </div>
//         <section className="flex flex-col md:flex-row items-stretch space-y-8 md:space-y-0 md:space-x-4 mt-4">
//           <div className="w-full md:w-1/4 relative">
//             <div className="bg-[#E6F7F9] border border-tt rounded-2xl overflow-hidden p-6 text-center h-full">
//               <div className="mb-4">
//                 <Syringe className="mx-auto text-secondary" size={48} />
//               </div>
//               <h3 className="text-xl font-semibold text-[#333]">
//                 Corporate, Pharma & Enterprise
//               </h3>
//               <p className="mt-2 text-[#333] text-opacity-60">
//                 Stay on top of drug development & efficacy, clinical trials, and more
//               </p>
//             </div>
//           </div>
//           <div className="w-full md:w-1/4 relative">
//             <div className="bg-[#F7E6F9] border border-tt rounded-2xl overflow-hidden p-6 text-center h-full">
//               <div className="mb-4">
//                 <Briefcase className="mx-auto text-secondary" size={48} />
//               </div>
//               <h3 className="text-xl font-semibold text-[#333]">
//                 Universities & Governments
//               </h3>
//               <p className="mt-2 text-[#333] text-opacity-60">
//                 Track and improve research outcomes across your organization
//               </p>
//             </div>
//           </div>
//           <div className="w-full md:w-1/4 relative">
//             <div className="bg-[#F9E6F7] border border-tt rounded-2xl overflow-hidden p-6 text-center h-full">
//               <div className="mb-4">
//                 <Microscope className="mx-auto text-secondary" size={48} />
//               </div>
//               <h3 className="text-xl font-semibold text-[#333]">Researchers</h3>
//               <p className="mt-2 text-[#333] text-opacity-60">
//                 See exactly how publications have been cited by newer research
//               </p>
//             </div>
//           </div>
//           <div className="w-full md:w-1/4 relative">
//             <div className="bg-[#F9EDE6] border border-tt rounded-2xl overflow-hidden p-6 text-center h-full">
//               <div className="mb-4">
//                 <BookOpenCheck className="mx-auto text-secondary" size={48} />
//               </div>
//               <h3 className="text-xl font-semibold text-[#333]">Publishers</h3>
//               <p className="mt-2 text-[#333] text-opacity-60">
//                 Improve discoverability of your publications and support your authors
//               </p>
//             </div>
//           </div>
//         </section>
//       </div>

//       {isModalOpen && (
//         <div className="fixed inset-0 z-50 flex justify-center items-center bg-bb bg-opacity-50">
//           <div className="bg-white p-6 rounded-2xl relative max-w-sm w-full">
//             <button
//               className="absolute top-2 right-2 text-secondary hover:text-gray-700"
//               onClick={toggleModal}
//             >
//               <svg
//                 className="w-6 h-6"
//                 fill="none"
//                 viewBox="0 0 24 24"
//                 stroke="currentColor"
//               >
//                 <path
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                   strokeWidth={2}
//                   d="M6 18L18 6M6 6l12 12"
//                 />
//               </svg>
//             </button>
//             <h2 className="mb-4 text-center text-gray-500">Sign Up</h2>

//             <div className="mb-4 flex items-center justify-center">
//               <hr className="border-gray-300 w-full" />
//             </div>
//             <button className="bg-tt text-primary px-4 py-2 rounded-2xl mb-4 w-full flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-opacity-50 hover:bg-opacity-90 hover:transform hover:scale-105 transition duration-300 ease-in-out">
//               <span className="text-2xl font-bold mr-4 ml-0">G</span>
//               <span className="mx-auto font-medium font-semibold">
//                 Continue with Google
//               </span>
//             </button>

//             <div className="mb-4 flex items-center justify-center">
//               <hr className="border-gray-300 w-full" />
//               <span className="text-gray-500 mx-4">or</span>
//               <hr className="border-gray-300 w-full" />
//             </div>
//             <input
//               type="email"
//               className="border font-medium font-semibold border-gray-300 rounded-2xl px-4 py-2 mb-4 w-full focus:outline-none focus:ring-2 focus:ring-gray-300 focus:border-transparent"
//               placeholder="Email"
//             />
//             <button className="bg-bb text-white px-4 py-2 rounded-2xl mb-4 w-full focus:outline-none focus:ring-2 font-medium font-semibold focus:ring-primary focus:ring-opacity-50 hover:transform hover:scale-105 transition duration-300 ease-in-out">
//               Continue with email
//             </button>

//             <div className="text-sm text-gray-500 text-center">
//               <a href="#" className="hover:underline">
//                 Privacy
//               </a>
//               <span className="mx-2">•</span>
//               <a href="#" className="hover:underline">
//                 Terms
//               </a>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Hero;




// import React, { useState, useEffect } from "react";
// import "./hero.css";
// import Typewriter from "typewriter-effect";
// import Counter from "./Counter"; // Import the Counter component

// import uoft from "../../assets/uoft.png";
// import uofc from "../../assets/uofc.png";
// import uofa from "../../assets/uofa.png";
// import ubc from "../../assets/ubc.png";
// import hero from "../../assets/snlogo.png";
// import { Syringe, Briefcase, Microscope, BookOpenCheck } from "lucide-react";

// const Hero = () => {
//   const [imageLoaded, setImageLoaded] = useState(false);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const toggleModal = () => {
//     setIsModalOpen(!isModalOpen);
//   };

//   useEffect(() => {
//     const handleImageLoad = () => {
//       setImageLoaded(true);
//     };
//     const img = new Image();
//     img.onload = handleImageLoad;
//     img.src = hero;

//     return () => {
//       img.onload = null;
//     };
//   }, []);

//   return (
//     <div className="hero-container flex flex-col items-center justify-center pb-10">
//       <div className="hero-content text-center max-w-4xl mt-20">
//         <h1 className="tracking-widest header-text text-5xl font-black tracking-tight leading-none text-[#333] md:text-2xl lg:text-4xl dark:text-white mb-4">
//           <div className="mb-6">
//             <span className="static-text font-clashdisplay font-bold tracking-widest text-gray-800 text-2xl md:text-4xl mb-6 leading-snug md:leading-normal">
//               The intelligent library for
//             </span>
//           </div>
//           <div className="typewriter-container">
//             <Typewriter
//               options={{
//                 strings: [
//                   "researchers",
//                   "writers",
//                   "students",
//                   "analysts",
//                   "educators",
//                   "journalists",
//                   "lawyers",
//                   "scientists",
//                 ],
//                 autoStart: true,
//                 loop: true,
//                 deleteSpeed: 50,
//                 delay: 75,
//                 wrapperClassName:
//                   "text-secondary pb-10  text-xl sm:text-lg md:text-2xl lg:text-3xl xl:text-4xl",
//                 cursorClassName:
//                   "text-secondary pb-10 text-xl sm:text-lg md:text-2xl lg:text-3xl xl:text-4xl",
//               }}
//               className="typewriter-text"
//             />
//           </div>
//         </h1>
//         <button
//           type="button"
//           className="inline-flex mb-10 tracking-widest  leading-10 items-center px-3.5 py-2.5 h-[2rem] border bg-bb border-transparent text-xl md:text-sm font-semibold rounded-xl text-white hover:opacity-60 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
//           onClick={() =>
//             (window.location.href =
//               "https://fllky8j5bmm.typeform.com/to/WHfbZpRJ")
//           }
//         >
//           Apply for early access
//         </button>

//         <div className="hero-image mb-4 md:mb-8 max-w-md md:max-w-5xl">
//           <div
//             className={`border-4 rounded-2xl border-tt overflow-hidden transition-shadow duration-300 ${
//               imageLoaded ? "animate-pop-in shadow-md" : ""
//             }`}
//           >
//             <img
//               src={hero}
//               alt="A screenshot of the Serenity platform interface"
//               className={`block w-full md:w-auto h-auto object-cover transition-opacity duration-300 ${
//                 imageLoaded ? "opacity-100" : "opacity-0"
//               }`}
//               onLoad={() => setImageLoaded(true)}
//             />
//           </div>
//         </div>

//         {/* Counter Section */}
//         <section className="stats-container text-center mt-10">
//           <div className="flex flex-col md:flex-row items-center justify-center space-y-6 md:space-y-0 md:space-x-12">
//             <div className="stat-item text-4xl font-bold text-secondary">
//               <Counter end={214000000} duration={2000} /> Papers
//             </div>
//             <div className="stat-item text-4xl font-bold text-secondary">
//               <Counter end={2490000000} duration={2000} /> Citations
//             </div>
//             <div className="stat-item text-4xl font-bold text-secondary">
//               <Counter end={79000000} duration={2000} /> Authors
//             </div>
//           </div>
//         </section>

//         <section className="flex flex-col md:flex-row items-stretch space-y-8 md:space-y-0 md:space-x-4 mt-4">
//           <div className="w-full md:w-1/4 relative">
//             <div className="bg-[#E6F7F9] border border-tt rounded-2xl overflow-hidden p-6 text-center h-full">
//               <div className="mb-4">
//                 <Syringe className="mx-auto text-secondary" size={48} />
//               </div>
//               <h3 className="text-xl font-semibold text-[#333]">
//                 Corporate, Pharma & Enterprise
//               </h3>
//               <p className="mt-2 text-[#333] text-opacity-60">
//                 Stay on top of drug development & efficacy, clinical trials, and more
//               </p>
//             </div>
//           </div>
//           <div className="w-full md:w-1/4 relative">
//             <div className="bg-[#F7E6F9] border border-tt rounded-2xl overflow-hidden p-6 text-center h-full">
//               <div className="mb-4">
//                 <Briefcase className="mx-auto text-secondary" size={48} />
//               </div>
//               <h3 className="text-xl font-semibold text-[#333]">
//                 Universities & Governments
//               </h3>
//               <p className="mt-2 text-[#333] text-opacity-60">
//                 Track and improve research outcomes across your organization
//               </p>
//             </div>
//           </div>
//           <div className="w-full md:w-1/4 relative">
//             <div className="bg-[#F9E6F7] border border-tt rounded-2xl overflow-hidden p-6 text-center h-full">
//               <div className="mb-4">
//                 <Microscope className="mx-auto text-secondary" size={48} />
//               </div>
//               <h3 className="text-xl font-semibold text-[#333]">Researchers</h3>
//               <p className="mt-2 text-[#333] text-opacity-60">
//                 See exactly how publications have been cited by newer research
//               </p>
//             </div>
//           </div>
//           <div className="w-full md:w-1/4 relative">
//             <div className="bg-[#F9EDE6] border border-tt rounded-2xl overflow-hidden p-6 text-center h-full">
//               <div className="mb-4">
//                 <BookOpenCheck className="mx-auto text-secondary" size={48} />
//               </div>
//               <h3 className="text-xl font-semibold text-[#333]">Publishers</h3>
//               <p className="mt-2 text-[#333] text-opacity-60">
//                 Improve discoverability of your publications and support your authors
//               </p>
//             </div>
//           </div>
//         </section>
//       </div>

//       {isModalOpen && (
//         <div className="fixed inset-0 z-50 flex justify-center items-center bg-bb bg-opacity-50">
//           <div className="bg-white p-6 rounded-2xl relative max-w-sm w-full">
//             <button
//               className="absolute top-2 right-2 text-secondary hover:text-gray-700"
//               onClick={toggleModal}
//             >
//               <svg
//                 className="w-6 h-6"
//                 fill="none"
//                 viewBox="0 0 24 24"
//                 stroke="currentColor"
//               >
//                 <path
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                   strokeWidth={2}
//                   d="M6 18L18 6M6 6l12 12"
//                 />
//               </svg>
//             </button>
//             <h2 className="mb-4 text-center text-gray-500">Sign Up</h2>

//             <div className="mb-4 flex items-center justify-center">
//               <hr className="border-gray-300 w-full" />
//             </div>
//             <button className="bg-tt text-primary px-4 py-2 rounded-2xl mb-4 w-full flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-opacity-50 hover:bg-opacity-90 hover:transform hover:scale-105 transition duration-300 ease-in-out">
//               <span className="text-2xl font-bold mr-4 ml-0">G</span>
//               <span className="mx-auto font-medium font-semibold">
//                 Continue with Google
//               </span>
//             </button>

//             <div className="mb-4 flex items-center justify-center">
//               <hr className="border-gray-300 w-full" />
//               <span className="text-gray-500 mx-4">or</span>
//               <hr className="border-gray-300 w-full" />
//             </div>
//             <input
//               type="email"
//               className="border font-medium font-semibold border-gray-300 rounded-2xl px-4 py-2 mb-4 w-full focus:outline-none focus:ring-2 focus:ring-gray-300 focus:border-transparent"
//               placeholder="Email"
//             />
//             <button className="bg-bb text-white px-4 py-2 rounded-2xl mb-4 w-full focus:outline-none focus:ring-2 font-medium font-semibold focus:ring-primary focus:ring-opacity-50 hover:transform hover:scale-105 transition duration-300 ease-in-out">
//               Continue with email
//             </button>

//             <div className="text-sm text-gray-500 text-center">
//               <a href="#" className="hover:underline">
//                 Privacy
//               </a>
//               <span className="mx-2">•</span>
//               <a href="#" className="hover:underline">
//                 Terms
//               </a>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Hero;



// import React, { useState, useEffect } from "react";
// import "./hero.css";
// import Typewriter from "typewriter-effect";
// import Counter from "./Counter"; // Assuming you have created the Counter component as described

// import uoft from "../../assets/uoft.png";
// import uofc from "../../assets/uofc.png";
// import uofa from "../../assets/uofa.png";
// import ubc from "../../assets/ubc.png";
// import hero from "../../assets/snlogo.png";
// import { Syringe, Briefcase, Microscope, BookOpenCheck } from "lucide-react";

// const Hero = () => {
//   const [imageLoaded, setImageLoaded] = useState(false);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const toggleModal = () => {
//     setIsModalOpen(!isModalOpen);
//   };

//   useEffect(() => {
//     const handleImageLoad = () => {
//       setImageLoaded(true);
//     };
//     const img = new Image();
//     img.onload = handleImageLoad;
//     img.src = hero;

//     return () => {
//       img.onload = null;
//     };
//   }, []);

//   return (
//     <div className="hero-container flex flex-col items-center justify-center pb-10">
//       <div className="hero-content text-center max-w-4xl mt-20">
//         <h1 className="tracking-widest header-text text-5xl font-black tracking-tight leading-none text-[#333] md:text-2xl lg:text-4xl dark:text-white mb-4">
//           <div className="mb-6">
//             <span className="static-text font-clashdisplay font-bold tracking-widest text-gray-800 text-2xl md:text-4xl mb-6 leading-snug md:leading-normal">
//               The intelligent library for
//             </span>
//           </div>
//           <div className="typewriter-container">
//             <Typewriter
//               options={{
//                 strings: [
//                   "researchers",
//                   "writers",
//                   "students",
//                   "analysts",
//                   "educators",
//                   "journalists",
//                   "lawyers",
//                   "scientists",
//                 ],
//                 autoStart: true,
//                 loop: true,
//                 deleteSpeed: 50,
//                 delay: 75,
//                 wrapperClassName:
//                   "text-secondary pb-10  text-xl sm:text-lg md:text-2xl lg:text-3xl xl:text-4xl",
//                 cursorClassName:
//                   "text-secondary pb-10 text-xl sm:text-lg md:text-2xl lg:text-3xl xl:text-4xl",
//               }}
//               className="typewriter-text"
//             />
//           </div>
//         </h1>
//         <button
//           type="button"
//           className="inline-flex mb-10 tracking-widest leading-10 items-center px-3.5 py-2.5 h-[2rem] border bg-bb border-transparent text-xl md:text-sm font-semibold rounded-xl text-white hover:opacity-60 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
//           onClick={() =>
//             (window.location.href =
//               "https://fllky8j5bmm.typeform.com/to/WHfbZpRJ")
//           }
//         >
//           Apply for early access
//         </button>

//         <div className="hero-image mb-4 md:mb-8 max-w-md md:max-w-5xl">
//           <div
//             className={`border-4 rounded-2xl border-tt overflow-hidden transition-shadow duration-300 ${
//               imageLoaded ? "animate-pop-in shadow-md" : ""
//             }`}
//           >
//             <img
//               src={hero}
//               alt="A screenshot of the Serenity platform interface"
//               className={`block w-full md:w-auto h-auto object-cover transition-opacity duration-300 ${
//                 imageLoaded ? "opacity-100" : "opacity-0"
//               }`}
//               onLoad={() => setImageLoaded(true)}
//             />
//           </div>
//         </div>

//         {/* Stats Section */}
//         <div className="stats-section flex flex-col items-center space-y-8 md:space-y-0 md:space-x-4 mt-4">
//           <div className="stat-item text-center">
//             <Counter end={214000000} duration={3} className="text-4xl font-bold text-primary" />
//             <p className="text-xl font-semibold text-gray-800">Papers</p>
//           </div>
//           <div className="stat-item text-center">
//             <Counter end={2490000000} duration={3} className="text-4xl font-bold text-primary" />
//             <p className="text-xl font-semibold text-gray-800">Citations</p>
//           </div>
//           <div className="stat-item text-center">
//             <Counter end={79000000} duration={3} className="text-4xl font-bold text-primary" />
//             <p className="text-xl font-semibold text-gray-800">Authors</p>
//           </div>
//         </div>

//         <section className="tiles-section flex flex-col md:flex-row items-stretch space-y-8 md:space-y-0 md:space-x-4 mt-8">
//           <div className="w-full md:w-1/4 relative">
//             <div className="bg-[#E6F7F9] border border-tt rounded-2xl overflow-hidden p-6 text-center h-full">
//               <div className="mb-4">
//                 <Syringe className="mx-auto text-secondary" size={48} />
//               </div>
//               <h3 className="text-xl font-semibold text-[#333]">
//                 Corporate, Pharma & Enterprise
//               </h3>
//               <p className="mt-2 text-[#333] text-opacity-60">
//                 Stay on top of drug development & efficacy, clinical trials, and more
//               </p>
//             </div>
//           </div>
//           <div className="w-full md:w-1/4 relative">
//             <div className="bg-[#F7E6F9] border border-tt rounded-2xl overflow-hidden p-6 text-center h-full">
//               <div className="mb-4">
//                 <Briefcase className="mx-auto text-secondary" size={48} />
//               </div>
//               <h3 className="text-xl font-semibold text-[#333]">
//                 Universities & Governments
//               </h3>
//               <p className="mt-2 text-[#333] text-opacity-60">
//                 Track and improve research outcomes across your organization
//               </p>
//             </div>
//           </div>
//           <div className="w-full md:w-1/4 relative">
//             <div className="bg-[#F9E6F7] border border-tt rounded-2xl overflow-hidden p-6 text-center h-full">
//               <div className="mb-4">
//                 <Microscope className="mx-auto text-secondary" size={48} />
//               </div>
//               <h3 className="text-xl font-semibold text-[#333]">Researchers</h3>
//               <p className="mt-2 text-[#333] text-opacity-60">
//                 See exactly how publications have been cited by newer research
//               </p>
//             </div>
//           </div>
//           <div className="w-full md:w-1/4 relative">
//             <div className="bg-[#F9EDE6] border border-tt rounded-2xl overflow-hidden p-6 text-center h-full">
//               <div className="mb-4">
//                 <BookOpenCheck className="mx-auto text-secondary" size={48} />
//               </div>
//               <h3 className="text-xl font-semibold text-[#333]">Publishers</h3>
//               <p className="mt-2 text-[#333] text-opacity-60">
//                 Improve discoverability of your publications and support your authors
//               </p>
//             </div>
//           </div>
//         </section>
//       </div>

//       {isModalOpen && (
//         <div className="fixed inset-0 z-50 flex justify-center items-center bg-bb bg-opacity-50">
//           <div className="bg-white p-6 rounded-2xl relative max-w-sm w-full">
//             <button
//               className="absolute top-2 right-2 text-secondary hover:text-gray-700"
//               onClick={toggleModal}
//             >
//               <svg
//                 className="w-6 h-6"
//                 fill="none"
//                 viewBox="0 0 24 24"
//                 stroke="currentColor"
//               >
//                 <path
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                   strokeWidth={2}
//                   d="M6 18L18 6M6 6l12 12"
//                 />
//               </svg>
//             </button>
//             <h2 className="mb-4 text-center text-gray-500">Sign Up</h2>

//             <div className="mb-4 flex items-center justify-center">
//               <hr className="border-gray-300 w-full" />
//             </div>
//             <button className="bg-tt text-primary px-4 py-2 rounded-2xl mb-4 w-full">
//               Sign up with Email
//             </button>
//             <button className="bg-[#4285F4] text-white px-4 py-2 rounded-2xl mb-4 w-full">
//               Sign up with Google
//             </button>
//             <p className="text-center text-gray-500 text-xs mt-4">
//               By signing up, you agree to our Terms and Conditions.
//             </p>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Hero;


// import React, { useState, useEffect } from "react";
// import Typewriter from "typewriter-effect";
// import Counter from "./Counter"; // Assuming you have created the Counter component as described
// import { Syringe, Briefcase, Microscope, BookOpenCheck } from "lucide-react";

// import uoft from "../../assets/uoft.png";
// import uofc from "../../assets/uofc.png";
// import uofa from "../../assets/uofa.png";
// import ubc from "../../assets/ubc.png";
// import hero from "../../assets/snlogo.png";

// const Hero = () => {
//   const [imageLoaded, setImageLoaded] = useState(false);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const toggleModal = () => {
//     setIsModalOpen(!isModalOpen);
//   };

//   useEffect(() => {
//     const handleImageLoad = () => {
//       setImageLoaded(true);
//     };
//     const img = new Image();
//     img.onload = handleImageLoad;
//     img.src = hero;

//     return () => {
//       img.onload = null;
//     };
//   }, []);

//   return (
//     <div className="hero-container flex flex-col items-center justify-center pb-10">
//       <div className="hero-content text-center max-w-4xl mt-20">
//         <h1 className="tracking-widest header-text text-5xl font-black tracking-tight leading-none text-gray-800 dark:text-white mb-4">
//           <div className="mb-6">
//             <span className="static-text font-clashdisplay font-bold tracking-widest text-gray-800 text-2xl md:text-4xl mb-6 leading-snug md:leading-normal">
//               The intelligent library for
//             </span>
//           </div>
//           <div className="typewriter-container">
//             <Typewriter
//               options={{
//                 strings: [
//                   "researchers",
//                   "writers",
//                   "students",
//                   "analysts",
//                   "educators",
//                   "journalists",
//                   "lawyers",
//                   "scientists",
//                 ],
//                 autoStart: true,
//                 loop: true,
//                 deleteSpeed: 50,
//                 delay: 75,
//                 wrapperClassName:
//                   "text-secondary pb-10 text-xl sm:text-lg md:text-2xl lg:text-3xl xl:text-4xl",
//                 cursorClassName:
//                   "text-secondary pb-10 text-xl sm:text-lg md:text-2xl lg:text-3xl xl:text-4xl",
//               }}
//               className="typewriter-text"
//             />
//           </div>
//         </h1>
//         <button
//           type="button"
//           className="inline-flex mb-10 tracking-widest leading-10 items-center px-3.5 py-2.5 h-[2rem] border bg-blue-500 border-transparent text-xl md:text-sm font-semibold rounded-xl text-white hover:opacity-80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
//           onClick={() =>
//             (window.location.href =
//               "https://fllky8j5bmm.typeform.com/to/WHfbZpRJ")
//           }
//         >
//           Apply for early access
//         </button>

//         <div className="hero-image mb-4 md:mb-8 max-w-md md:max-w-5xl">
//           <div
//             className={`border-4 rounded-2xl border-teal-500 overflow-hidden transition-shadow duration-300 ${
//               imageLoaded ? "animate-pop-in shadow-md" : ""
//             }`}
//           >
//             <img
//               src={hero}
//               alt="A screenshot of the Serenity platform interface"
//               className={`block w-full md:w-auto h-auto object-cover transition-opacity duration-300 ${
//                 imageLoaded ? "opacity-100" : "opacity-0"
//               }`}
//               onLoad={() => setImageLoaded(true)}
//             />
//           </div>
//         </div>

//         {/* Stats Section */}
//         <div className="stats-section grid grid-cols-1 sm:grid-cols-3 gap-4 mt-8">
//           <div className="stat-item bg-white shadow-lg rounded-2xl p-6 text-center">
//             <Counter
//               end={214000000}
//               duration={3}
//               className="text-4xl font-bold text-blue-500 mb-2"
//             />
//             <p className="text-xl font-semibold text-gray-800">Papers</p>
//           </div>
//           <div className="stat-item bg-white shadow-lg rounded-2xl p-6 text-center">
//             <Counter
//               end={2490000000}
//               duration={3}
//               className="text-4xl font-bold text-blue-500 mb-2"
//             />
//             <p className="text-xl font-semibold text-gray-800">Citations</p>
//           </div>
//           <div className="stat-item bg-white shadow-lg rounded-2xl p-6 text-center">
//             <Counter
//               end={79000000}
//               duration={3}
//               className="text-4xl font-bold text-blue-500 mb-2"
//             />
//             <p className="text-xl font-semibold text-gray-800">Authors</p>
//           </div>
//         </div>

//         <section className="tiles-section grid grid-cols-1 md:grid-cols-4 gap-4 mt-8">
//           <div className="tile bg-blue-100 border border-teal-500 rounded-2xl p-6 text-center">
//             <div className="mb-4">
//               <Syringe className="mx-auto text-blue-500" size={48} />
//             </div>
//             <h3 className="text-xl font-semibold text-gray-800">
//               Corporate, Pharma & Enterprise
//             </h3>
//             <p className="mt-2 text-gray-600">
//               Stay on top of drug development & efficacy, clinical trials, and more
//             </p>
//           </div>
//           <div className="tile bg-purple-100 border border-teal-500 rounded-2xl p-6 text-center">
//             <div className="mb-4">
//               <Briefcase className="mx-auto text-blue-500" size={48} />
//             </div>
//             <h3 className="text-xl font-semibold text-gray-800">
//               Universities & Governments
//             </h3>
//             <p className="mt-2 text-gray-600">
//               Track and improve research outcomes across your organization
//             </p>
//           </div>
//           <div className="tile bg-pink-100 border border-teal-500 rounded-2xl p-6 text-center">
//             <div className="mb-4">
//               <Microscope className="mx-auto text-blue-500" size={48} />
//             </div>
//             <h3 className="text-xl font-semibold text-gray-800">Researchers</h3>
//             <p className="mt-2 text-gray-600">
//               See exactly how publications have been cited by newer research
//             </p>
//           </div>
//           <div className="tile bg-orange-100 border border-teal-500 rounded-2xl p-6 text-center">
//             <div className="mb-4">
//               <BookOpenCheck className="mx-auto text-blue-500" size={48} />
//             </div>
//             <h3 className="text-xl font-semibold text-gray-800">Publishers</h3>
//             <p className="mt-2 text-gray-600">
//               Improve discoverability of your publications and support your authors
//             </p>
//           </div>
//         </section>
//       </div>

//       {isModalOpen && (
//         <div className="fixed inset-0 z-50 flex justify-center items-center bg-blue-500 bg-opacity-50">
//           <div className="bg-white p-6 rounded-2xl relative max-w-sm w-full">
//             <button
//               className="absolute top-2 right-2 text-blue-500 hover:text-gray-700"
//               onClick={toggleModal}
//             >
//               <svg
//                 className="w-6 h-6"
//                 fill="none"
//                 viewBox="0 0 24 24"
//                 stroke="currentColor"
//               >
//                 <path
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                   strokeWidth={2}
//                   d="M6 18L18 6M6 6l12 12"
//                 />
//               </svg>
//             </button>
//             <h2 className="mb-4 text-center text-gray-500">Sign Up</h2>

//             <div className="mb-4 flex items-center justify-center">
//               <hr className="border-gray-300 w-full" />
//             </div>
//             <button className="bg-teal-500 text-white px-4 py-2 rounded-2xl mb-4 w-full">
//               Sign up with Email
//             </button>
//             <button className="bg-blue-500 text-white px-4 py-2 rounded-2xl mb-4 w-full">
//               Sign up with Google
//             </button>
//             <p className="text-center text-gray-500 text-xs mt-4">
//               By signing up, you agree to our Terms and Conditions.
//             </p>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Hero;


// import React, { useState, useEffect } from "react";
// import Typewriter from "typewriter-effect";
// import Counter from "./Counter"; // Assuming you have created the Counter component as described
// import { Syringe, Briefcase, Microscope, BookOpenCheck } from "lucide-react";

// import hero from "../../assets/snlogo.png";

// // Helper function to format numbers
// const formatNumber = (number) => {
//   if (number >= 1e9) {
//     return `${(number / 1e9).toFixed(1)} Billion`;
//   } else if (number >= 1e6) {
//     return `${(number / 1e6).toFixed(1)} Million`;
//   }
//   return number.toString();
// };

// const Hero = () => {
//   const [imageLoaded, setImageLoaded] = useState(false);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const toggleModal = () => {
//     setIsModalOpen(!isModalOpen);
//   };

//   useEffect(() => {
//     const handleImageLoad = () => {
//       setImageLoaded(true);
//     };
//     const img = new Image();
//     img.onload = handleImageLoad;
//     img.src = hero;

//     return () => {
//       img.onload = null;
//     };
//   }, []);

//   return (
//     <div className="hero-container flex flex-col items-center justify-center pb-10">
//       <div className="hero-content text-center max-w-4xl mt-20">
//         <h1 className="tracking-widest header-text text-5xl font-black tracking-tight leading-none text-gray-800 dark:text-white mb-4">
//           <div className="mb-6">
//             <span className="static-text font-clashdisplay font-bold tracking-widest text-gray-800 text-2xl md:text-4xl mb-6 leading-snug md:leading-normal">
//               The intelligent library for
//             </span>
//           </div>
//           <div className="typewriter-container">
//             <Typewriter
//               options={{
//                 strings: [
//                   "researchers",
//                   "writers",
//                   "students",
//                   "analysts",
//                   "educators",
//                   "journalists",
//                   "lawyers",
//                   "scientists",
//                 ],
//                 autoStart: true,
//                 loop: true,
//                 deleteSpeed: 50,
//                 delay: 75,
//                 wrapperClassName:
//                   "text-secondary pb-10 text-xl sm:text-lg md:text-2xl lg:text-3xl xl:text-4xl",
//                 cursorClassName:
//                   "text-secondary pb-10 text-xl sm:text-lg md:text-2xl lg:text-3xl xl:text-4xl",
//               }}
//               className="typewriter-text"
//             />
//           </div>
//         </h1>
//         <button
//           type="button"
//           className="inline-flex mb-10 tracking-widest leading-10 items-center px-3.5 py-2.5 h-[2rem] border bg-blue-500 border-transparent text-xl md:text-sm font-semibold rounded-xl text-white hover:opacity-80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
//           onClick={() =>
//             (window.location.href =
//               "https://fllky8j5bmm.typeform.com/to/WHfbZpRJ")
//           }
//         >
//           Apply for early access
//         </button>

//         <div className="hero-image mb-4 md:mb-8 max-w-md md:max-w-5xl">
//           <div
//             className={`border-4 rounded-2xl border-teal-500 overflow-hidden transition-shadow duration-300 ${
//               imageLoaded ? "animate-pop-in shadow-md" : ""
//             }`}
//           >
//             <img
//               src={hero}
//               alt="A screenshot of the Serenity platform interface"
//               className={`block w-full md:w-auto h-auto object-cover transition-opacity duration-300 ${
//                 imageLoaded ? "opacity-100" : "opacity-0"
//               }`}
//               onLoad={() => setImageLoaded(true)}
//             />
//           </div>
//         </div>

//         {/* Stats Section */}
//         <div className="stats-section grid grid-cols-1 sm:grid-cols-3 gap-4 mt-8">
//           <div className="stat-item bg-white shadow-lg rounded-2xl p-6 text-center">
//             <Counter
//               end={214000000}
//               duration={3}
//               formatFunc={formatNumber}
//               className="text-4xl font-bold text-blue-500 mb-2"
//             />
//             <p className="text-xl font-semibold text-gray-800">Papers</p>
//           </div>
//           <div className="stat-item bg-white shadow-lg rounded-2xl p-6 text-center">
//             <Counter
//               end={2490000000}
//               duration={3}
//               formatFunc={formatNumber}
//               className="text-4xl font-bold text-blue-500 mb-2"
//             />
//             <p className="text-xl font-semibold text-gray-800">Citations</p>
//           </div>
//           <div className="stat-item bg-white shadow-lg rounded-2xl p-6 text-center">
//             <Counter
//               end={79000000}
//               duration={3}
//               formatFunc={formatNumber}
//               className="text-4xl font-bold text-blue-500 mb-2"
//             />
//             <p className="text-xl font-semibold text-gray-800">Authors</p>
//           </div>
//         </div>

//         <section className="tiles-section grid grid-cols-1 md:grid-cols-4 gap-4 mt-8">
//           <div className="tile bg-blue-100 border border-teal-500 rounded-2xl p-6 text-center">
//             <div className="mb-4">
//               <Syringe className="mx-auto text-blue-500" size={48} />
//             </div>
//             <h3 className="text-xl font-semibold text-gray-800">
//               Corporate, Pharma & Enterprise
//             </h3>
//             <p className="mt-2 text-gray-600">
//               Stay on top of drug development & efficacy, clinical trials, and more
//             </p>
//           </div>
//           <div className="tile bg-purple-100 border border-teal-500 rounded-2xl p-6 text-center">
//             <div className="mb-4">
//               <Briefcase className="mx-auto text-blue-500" size={48} />
//             </div>
//             <h3 className="text-xl font-semibold text-gray-800">
//               Universities & Governments
//             </h3>
//             <p className="mt-2 text-gray-600">
//               Track and improve research outcomes across your organization
//             </p>
//           </div>
//           <div className="tile bg-pink-100 border border-teal-500 rounded-2xl p-6 text-center">
//             <div className="mb-4">
//               <Microscope className="mx-auto text-blue-500" size={48} />
//             </div>
//             <h3 className="text-xl font-semibold text-gray-800">Researchers</h3>
//             <p className="mt-2 text-gray-600">
//               See exactly how publications have been cited by newer research
//             </p>
//           </div>
//           <div className="tile bg-orange-100 border border-teal-500 rounded-2xl p-6 text-center">
//             <div className="mb-4">
//               <BookOpenCheck className="mx-auto text-blue-500" size={48} />
//             </div>
//             <h3 className="text-xl font-semibold text-gray-800">Publishers</h3>
//             <p className="mt-2 text-gray-600">
//               Improve discoverability of your publications and support your authors
//             </p>
//           </div>
//         </section>
//       </div>

//       {isModalOpen && (
//         <div className="fixed inset-0 z-50 flex justify-center items-center bg-blue-500 bg-opacity-50">
//           <div className="bg-white p-6 rounded-2xl relative max-w-sm w-full">
//             <button
//               className="absolute top-2 right-2 text-blue-500 hover:text-gray-700"
//               onClick={toggleModal}
//             >
//               <svg
//                 className="w-6 h-6"
//                 fill="none"
//                 viewBox="0 0 24 24"
//                 stroke="currentColor"
//               >
//                 <path
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                   strokeWidth={2}
//                   d="M6 18L18 6M6 6l12 12"
//                 />
//               </svg>
//             </button>
//             <h2 className="mb-4 text-center text-gray-500">Sign Up</h2>

//             <div className="mb-4 flex items-center justify-center">
//               <hr className="border-gray-300 w-full" />
//             </div>
//             <button className="bg-teal-500 text-white px-4 py-2 rounded-2xl mb-4 w-full">
//               Sign up with Email
//             </button>
//             <button className="bg-blue-500 text-white px-4 py-2 rounded-2xl mb-4 w-full">
//               Sign up with Google
//             </button>
//             <p className="text-center text-gray-500 text-xs mt-4">
//               By signing up, you agree to our Terms and Conditions.
//             </p>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Hero;


// import React, { useState, useEffect } from "react";
// import Typewriter from "typewriter-effect";
// import Counter from "./Counter"; // Assuming you have created the Counter component as described
// import { Syringe, Briefcase, Microscope, BookOpenCheck } from "lucide-react";
// import hero from "../../assets/snlogo.png";

// // Helper function to format numbers
// const formatNumber = (number) => {
//   if (number >= 1e9) {
//     return `${(number / 1e9).toFixed(1)} Billion`;
//   } else if (number >= 1e6) {
//     return `${(number / 1e6).toFixed(1)} Million`;
//   }
//   return number.toString();
// };

// const Hero = () => {
//   const [imageLoaded, setImageLoaded] = useState(false);
//   const [isModalOpen, setIsModalOpen] = useState(false);
  
//   const toggleModal = () => {
//     setIsModalOpen(!isModalOpen);
//   };

//   useEffect(() => {
//     const handleImageLoad = () => {
//       setImageLoaded(true);
//     };
//     const img = new Image();
//     img.onload = handleImageLoad;
//     img.src = hero;

//     return () => {
//       img.onload = null;
//     };
//   }, []);

//   return (
//     <div className="hero-container flex flex-col items-center justify-center py-10 bg-white">
//       <div className="hero-content text-center max-w-4xl mt-10">
//         <h1 className="text-4xl md:text-5xl font-bold text-gray-800 mb-6">
//           The intelligent library for
//         </h1>
//         <div className="typewriter-container mb-6">
//           <Typewriter
//             options={{
//               strings: [
//                 "researchers",
//                 "writers",
//                 "students",
//                 "analysts",
//                 "educators",
//                 "journalists",
//                 "lawyers",
//                 "scientists",
//               ],
//               autoStart: true,
//               loop: true,
//               deleteSpeed: 50,
//               delay: 75,
//               wrapperClassName: "text-secondary text-2xl md:text-3xl",
//               cursorClassName: "text-secondary text-2xl md:text-3xl",
//             }}
//             className="typewriter-text"
//           />
//         </div>
//         <button
//           type="button"
//           className="btn-primary mb-10"
//           onClick={() =>
//             (window.location.href =
//               "https://fllky8j5bmm.typeform.com/to/WHfbZpRJ")
//           }
//         >
//           Apply for early access
//         </button>

//         <div className="hero-image mb-8 max-w-md md:max-w-5xl">
//           <div className={`border-4 rounded-xl border-teal-500 overflow-hidden transition-shadow duration-300 ${imageLoaded ? "shadow-md" : ""}`}>
//             <img
//               src={hero}
//               alt="Screenshot of the Serenity platform interface"
//               className={`block w-full h-auto object-cover transition-opacity duration-300 ${imageLoaded ? "opacity-100" : "opacity-0"}`}
//               onLoad={() => setImageLoaded(true)}
//             />
//           </div>
//         </div>

//         <div className="stats-section grid grid-cols-1 sm:grid-cols-3 gap-4">
//           <div className="stat-item bg-white shadow-lg rounded-xl p-6 text-center">
//             <Counter
//               end={214000000}
//               duration={3}
//               formatFunc={formatNumber}
//               className="text-4xl font-bold text-blue-500 mb-2"
//             />
//             <p className="text-xl font-semibold text-gray-800">Papers</p>
//           </div>
//           <div className="stat-item bg-white shadow-lg rounded-xl p-6 text-center">
//             <Counter
//               end={2490000000}
//               duration={3}
//               formatFunc={formatNumber}
//               className="text-4xl font-bold text-blue-500 mb-2"
//             />
//             <p className="text-xl font-semibold text-gray-800">Citations</p>
//           </div>
//           <div className="stat-item bg-white shadow-lg rounded-xl p-6 text-center">
//             <Counter
//               end={79000000}
//               duration={3}
//               formatFunc={formatNumber}
//               className="text-4xl font-bold text-blue-500 mb-2"
//             />
//             <p className="text-xl font-semibold text-gray-800">Authors</p>
//           </div>
//         </div>

//         <section className="tiles-section grid grid-cols-1 md:grid-cols-4 gap-4 mt-8">
//           <div className="tile bg-blue-100 border border-teal-500 rounded-xl p-6 text-center">
//             <div className="mb-4">
//               <Syringe className="mx-auto text-blue-500" size={48} />
//             </div>
//             <h3 className="text-xl font-semibold text-gray-800">
//               Corporate, Pharma & Enterprise
//             </h3>
//             <p className="mt-2 text-gray-600">
//               Stay on top of drug development & efficacy, clinical trials, and more
//             </p>
//           </div>
//           <div className="tile bg-purple-100 border border-teal-500 rounded-xl p-6 text-center">
//             <div className="mb-4">
//               <Briefcase className="mx-auto text-blue-500" size={48} />
//             </div>
//             <h3 className="text-xl font-semibold text-gray-800">
//               Universities & Governments
//             </h3>
//             <p className="mt-2 text-gray-600">
//               Track and improve research outcomes across your organization
//             </p>
//           </div>
//           <div className="tile bg-pink-100 border border-teal-500 rounded-xl p-6 text-center">
//             <div className="mb-4">
//               <Microscope className="mx-auto text-blue-500" size={48} />
//             </div>
//             <h3 className="text-xl font-semibold text-gray-800">Researchers</h3>
//             <p className="mt-2 text-gray-600">
//               See exactly how publications have been cited by newer research
//             </p>
//           </div>
//           <div className="tile bg-orange-100 border border-teal-500 rounded-xl p-6 text-center">
//             <div className="mb-4">
//               <BookOpenCheck className="mx-auto text-blue-500" size={48} />
//             </div>
//             <h3 className="text-xl font-semibold text-gray-800">Publishers</h3>
//             <p className="mt-2 text-gray-600">
//               Improve discoverability of your publications and support your authors
//             </p>
//           </div>
//         </section>
//       </div>

//       {isModalOpen && (
//         <div className="fixed inset-0 z-50 flex justify-center items-center bg-blue-500 bg-opacity-50">
//           <div className="bg-white p-6 rounded-xl relative max-w-sm w-full">
//             <button
//               className="absolute top-2 right-2 text-blue-500 hover:text-gray-700"
//               onClick={toggleModal}
//             >
//               <svg
//                 className="w-6 h-6"
//                 fill="none"
//                 viewBox="0 0 24 24"
//                 stroke="currentColor"
//               >
//                 <path
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                   strokeWidth={2}
//                   d="M6 18L18 6M6 6l12 12"
//                 />
//               </svg>
//             </button>
//             <h2 className="mb-4 text-center text-gray-500">Sign Up</h2>

//             <div className="mb-4 flex items-center justify-center">
//               <hr className="border-gray-300 w-full" />
//             </div>
//             <button className="bg-teal-500 text-white px-4 py-2 rounded-xl mb-4 w-full">
//               Sign up with Email
//             </button>
//             <button className="bg-blue-500 text-white px-4 py-2 rounded-xl mb-4 w-full">
//               Sign up with Google
//             </button>
//             <p className="text-center text-gray-500 text-xs mt-4">
//               By signing up, you agree to our Terms and Conditions.
//             </p>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Hero;


// import React, { useState, useEffect } from "react";
// import Typewriter from "typewriter-effect";
// import Counter from "./Counter"; // Assuming you have created the Counter component as described
// import { Syringe, Briefcase, Microscope, BookOpenCheck } from "lucide-react";
// import hero from "../../assets/scdemo.png";

// const formatNumber = (number) => {
//   if (number >= 1e9) {
//     return `${(number / 1e9).toFixed(1)} Billion`;
//   } else if (number >= 1e6) {
//     return `${(number / 1e6).toFixed(1)} Million`;
//   }
//   return number.toString();
// };

// const Hero = () => {
//   const [imageLoaded, setImageLoaded] = useState(false);
//   const [isModalOpen, setIsModalOpen] = useState(false);
  
//   const toggleModal = () => {
//     setIsModalOpen(!isModalOpen);
//   };

//   useEffect(() => {
//     const handleImageLoad = () => {
//       setImageLoaded(true);
//     };
//     const img = new Image();
//     img.onload = handleImageLoad;
//     img.src = hero;

//     return () => {
//       img.onload = null;
//     };
//   }, []);

//   return (
//     <div className="hero-container flex flex-col items-center justify-center py-10 bg-white">
//       <div className="hero-content text-center max-w-4xl mt-10">
//         <h1 className="text-4xl md:text-5xl font-bold text-primary mb-6">
//           The intelligent library for
//         </h1>
//         <div className="typewriter-container mb-6">
//           <Typewriter
//             options={{
//               strings: [
//                 "researchers",
//                 "writers",
//                 "students",
//                 "analysts",
//                 "educators",
//                 "journalists",
//                 "lawyers",
//                 "scientists",
//               ],
//               autoStart: true,
//               loop: true,
//               deleteSpeed: 50,
//               delay: 75,
//               wrapperClassName: "text-secondary text-2xl md:text-3xl",
//               cursorClassName: "text-secondary text-2xl md:text-3xl",
//             }}
//             className="typewriter-text"
//           />
//         </div>
//         <button
//           type="button"
//           className="btn-primary mb-10"
//           onClick={() =>
//             (window.location.href =
//               "https://fllky8j5bmm.typeform.com/to/WHfbZpRJ")
//           }
//         >
//           Apply for early access
//         </button>

//         <div className="hero-image mb-8 max-w-md md:max-w-5xl">
//           <div className={`border-4 rounded-xl border-tertiary overflow-hidden transition-shadow duration-300 ${imageLoaded ? "shadow-md" : ""}`}>
//             <img
//               src={hero}
//               alt="Screenshot of the Serenity platform interface"
//               className={`block w-full h-auto object-cover transition-opacity duration-300 ${imageLoaded ? "opacity-100" : "opacity-0"}`}
//               onLoad={() => setImageLoaded(true)}
//             />
//           </div>
//         </div>

//         <div className="stats-section grid grid-cols-1 sm:grid-cols-3 gap-4">
//           <div className="stat-item bg-white shadow-lg rounded-xl p-6 text-center">
//             <Counter
//               end={214000000}
//               duration={3}
//               formatFunc={formatNumber}
//               className="text-4xl font-bold text-secondary mb-2"
//             />
//             <p className="text-xl font-semibold text-gray-800">Papers</p>
//           </div>
//           <div className="stat-item bg-white shadow-lg rounded-xl p-6 text-center">
//             <Counter
//               end={2490000000}
//               duration={3}
//               formatFunc={formatNumber}
//               className="text-4xl font-bold text-secondary mb-2"
//             />
//             <p className="text-xl font-semibold text-gray-800">Citations</p>
//           </div>
//           <div className="stat-item bg-white shadow-lg rounded-xl p-6 text-center">
//             <Counter
//               end={79000000}
//               duration={3}
//               formatFunc={formatNumber}
//               className="text-4xl font-bold text-secondary mb-2"
//             />
//             <p className="text-xl font-semibold text-gray-800">Authors</p>
//           </div>
//         </div>

//         <section className="tiles-section grid grid-cols-1 md:grid-cols-4 gap-4 mt-8">
//           <div className="tile bg-hover1 border border-tertiary rounded-xl p-6 text-center">
//             <div className="mb-4">
//               <Syringe className="mx-auto text-secondary" size={48} />
//             </div>
//             <h3 className="text-xl font-semibold text-gray-800">
//               Corporate, Pharma & Enterprise
//             </h3>
//             <p className="mt-2 text-gray-600">
//               Stay on top of drug development & efficacy, clinical trials, and more
//             </p>
//           </div>
//           <div className="tile bg-hover1 border border-tertiary rounded-xl p-6 text-center">
//             <div className="mb-4">
//               <Briefcase className="mx-auto text-secondary" size={48} />
//             </div>
//             <h3 className="text-xl font-semibold text-gray-800">
//               Universities & Governments
//             </h3>
//             <p className="mt-2 text-gray-600">
//               Track and improve research outcomes across your organization
//             </p>
//           </div>
//           <div className="tile bg-hover1 border border-tertiary rounded-xl p-6 text-center">
//             <div className="mb-4">
//               <Microscope className="mx-auto text-secondary" size={48} />
//             </div>
//             <h3 className="text-xl font-semibold text-gray-800">Researchers</h3>
//             <p className="mt-2 text-gray-600">
//               See exactly how publications have been cited by newer research
//             </p>
//           </div>
//           <div className="tile bg-hover1 border border-tertiary rounded-xl p-6 text-center">
//             <div className="mb-4">
//               <BookOpenCheck className="mx-auto text-secondary" size={48} />
//             </div>
//             <h3 className="text-xl font-semibold text-gray-800">Publishers</h3>
//             <p className="mt-2 text-gray-600">
//               Improve discoverability of your publications and support your authors
//             </p>
//           </div>
//         </section>
//       </div>

//       {isModalOpen && (
//         <div className="fixed inset-0 z-50 flex justify-center items-center bg-tertiary bg-opacity-50">
//           <div className="bg-white p-6 rounded-xl relative max-w-sm w-full">
//             <button
//               className="absolute top-2 right-2 text-secondary hover:text-gray-700"
//               onClick={toggleModal}
//             >
//               <svg
//                 className="w-6 h-6"
//                 fill="none"
//                 viewBox="0 0 24 24"
//                 stroke="currentColor"
//               >
//                 <path
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                   strokeWidth={2}
//                   d="M6 18L18 6M6 6l12 12"
//                 />
//               </svg>
//             </button>
//             <h2 className="mb-4 text-center text-tertiary">Sign Up</h2>

//             <div className="mb-4 flex items-center justify-center">
//               <hr className="border-tertiary w-full" />
//             </div>
//             <button className="bg-primary text-white px-4 py-2 rounded-xl mb-4 w-full">
//               Sign up with Email
//             </button>
//             <button className="bg-primary text-white px-4 py-2 rounded-xl mb-4 w-full">
//               Sign up with Google
//             </button>
//             <p className="text-center text-secondary text-xs mt-4">
//               By signing up, you agree to our Terms and Conditions.
//             </p>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Hero;







// import React, { useState, useEffect } from "react";
// import Typewriter from "typewriter-effect";
// import Counter from "./Counter"; // Assuming you have created the Counter component as described
// import { Syringe, Briefcase, Microscope, BookOpenCheck } from "lucide-react";
// import hero from "../../assets/scdemo.png";

// const formatNumber = (number) => {
//   if (number >= 1e9) {
//     return `${(number / 1e9).toFixed(1)} Billion`;
//   } else if (number >= 1e6) {
//     return `${(number / 1e6).toFixed(1)} Million`;
//   }
//   return number.toString();
// };

// const Hero = () => {
//   const [imageLoaded, setImageLoaded] = useState(false);
//   const [isModalOpen, setIsModalOpen] = useState(false);
  
//   const toggleModal = () => {
//     setIsModalOpen(!isModalOpen);
//   };

//   useEffect(() => {
//     const handleImageLoad = () => {
//       setImageLoaded(true);
//     };
//     const img = new Image();
//     img.onload = handleImageLoad;
//     img.src = hero;

//     return () => {
//       img.onload = null;
//     };
//   }, []);

//   return (
//     <div className="hero-container flex flex-col items-center justify-center py-10 bg-white">
//       <div className="hero-content text-center max-w-4xl mt-10">
//         <h1 className="text-4xl md:text-5xl font-bold text-primary mb-6">
//     Responsible and Ethical AI for
//         </h1>
//         <div className="typewriter-container mb-6">
//           <Typewriter
//             options={{
//               strings: [
//                 "Researchers",
//                 "Educators",
//                 "Students",
//                 "Academics",
//                 "Scientists",
//                 "Scholars",
//                 "Data Analysts",
//                 "Lecturers"
//               ],
//               autoStart: true,
//               loop: true,
//               deleteSpeed: 50,
//               delay: 75,
//               wrapperClassName: "text-secondary text-2xl md:text-3xl",
//               cursorClassName: "text-secondary text-2xl md:text-3xl",
//             }}
//             className="typewriter-text"
//           />
//         </div>
//         <button
//   type="button"
//   className="bg-backgroundBlue text-primary hover:bg-hover1 active:bg-secondary shadow-md py-2 px-4 rounded-lg mb-10"
//   onClick={() =>
//     (window.location.href =
//       "https://fllky8j5bmm.typeform.com/to/WHfbZpRJ")
//   }
// >
//   Apply for early access
// </button>


//         <div className="hero-image mb-8 max-w-md md:max-w-5xl">
//           <div className={`border-4 rounded-xl border-tertiary overflow-hidden transition-shadow duration-300 ${imageLoaded ? "shadow-md" : ""}`}>
//             <img
//               src={hero}
//               alt="Screenshot of the Serenity platform interface"
//               className={`block w-full h-auto object-cover transition-opacity duration-300 ${imageLoaded ? "opacity-100" : "opacity-0"}`}
//               onLoad={() => setImageLoaded(true)}
//             />
//           </div>
//         </div>

     
      
//         <div className="stats-section grid grid-cols-1 sm:grid-cols-3 gap-4">
//           <div className="stat-item bg-white shadow-lg rounded-xl p-6 text-center">
//             <Counter
//               end={214000000}
//               duration={3}
//               formatFunc={formatNumber}
//               className="text-4xl font-bold text-secondary mb-2"
//             />
//             <p className="text-xl font-semibold text-gray-800">Papers</p>
//           </div>
//           <div className="stat-item bg-white shadow-lg rounded-xl p-6 text-center">
//             <Counter
//               end={2490000000}
//               duration={3}
//               formatFunc={formatNumber}
//               className="text-4xl font-bold text-secondary mb-2"
//             />
//             <p className="text-xl font-semibold text-gray-800">Citations</p>
//           </div>
//           <div className="stat-item bg-white shadow-lg rounded-xl p-6 text-center">
//             <Counter
//               end={79000000}
//               duration={3}
//               formatFunc={formatNumber}
//               className="text-4xl font-bold text-secondary mb-2"
//             />
//             <p className="text-xl font-semibold text-gray-800">Authors</p>
//           </div>
//         </div>

//         <section className="tiles-section grid grid-cols-1 md:grid-cols-4 gap-4 mt-8">
//           <div className="tile bg-hover1 border border-tertiary rounded-xl p-6 text-center">
//             <div className="mb-4">
//               <Syringe className="mx-auto text-secondary" size={48} />
//             </div>
//             <h3 className="text-xl font-semibold text-gray-800">
//               Corporate, Pharma & Enterprise
//             </h3>
//             <p className="mt-2 text-gray-600">
//               Stay on top of drug development & efficacy, clinical trials, and more
//             </p>
//           </div>
//           <div className="tile bg-hover1 border border-tertiary rounded-xl p-6 text-center">
//             <div className="mb-4">
//               <Briefcase className="mx-auto text-secondary" size={48} />
//             </div>
//             <h3 className="text-xl font-semibold text-gray-800">
//               Universities & Governments
//             </h3>
//             <p className="mt-2 text-gray-600">
//               Track and improve research outcomes across your organization
//             </p>
//           </div>
//           <div className="tile bg-hover1 border border-tertiary rounded-xl p-6 text-center">
//             <div className="mb-4">
//               <Microscope className="mx-auto text-secondary" size={48} />
//             </div>
//             <h3 className="text-xl font-semibold text-gray-800">Researchers</h3>
//             <p className="mt-2 text-gray-600">
//               See exactly how publications have been cited by newer research
//             </p>
//           </div>
//           <div className="tile bg-hover1 border border-tertiary rounded-xl p-6 text-center">
//             <div className="mb-4">
//               <BookOpenCheck className="mx-auto text-secondary" size={48} />
//             </div>
//             <h3 className="text-xl font-semibold text-gray-800">Publishers</h3>
//             <p className="mt-2 text-gray-600">
//               Improve discoverability of your publications and support your authors
//             </p>
//           </div>
//         </section>
//       </div>

//       {isModalOpen && (
//         <div className="fixed inset-0 z-50 flex justify-center items-center bg-tertiary bg-opacity-50">
//           <div className="bg-white p-6 rounded-xl relative max-w-sm w-full">
//             <button
//               className="absolute top-2 right-2 text-secondary hover:text-gray-700"
//               onClick={toggleModal}
//             >
//               <svg
//                 className="w-6 h-6"
//                 fill="none"
//                 viewBox="0 0 24 24"
//                 stroke="currentColor"
//               >
//                 <path
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                   strokeWidth={2}
//                   d="M6 18L18 6M6 6l12 12"
//                 />
//               </svg>
//             </button>
//             <h2 className="mb-4 text-center text-tertiary">Sign Up</h2>

//             <div className="mb-4 flex items-center justify-center">
//               <hr className="border-tertiary w-full" />
//             </div>
//             <button className="bg-primary text-white px-4 py-2 rounded-xl mb-4 w-full">
//               Sign up with Email
//             </button>
//             <button className="bg-primary text-white px-4 py-2 rounded-xl mb-4 w-full">
//               Sign up with Google
//             </button>
//             <p className="text-center text-secondary text-xs mt-4">
//               By signing up, you agree to our Terms and Conditions.
//             </p>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Hero;

// import React, { useState, useEffect } from "react";
// import Typewriter from "typewriter-effect";
// import Counter from "./Counter"; // Assuming you have created the Counter component as described
// import { Syringe, Briefcase, Microscope, BookOpenCheck } from "lucide-react";
// import hero from "../../assets/scdemo.png";

// const formatNumber = (number) => {
//   if (number >= 1e9) {
//     return `${(number / 1e9).toFixed(1)} Billion`;
//   } else if (number >= 1e6) {
//     return `${(number / 1e6).toFixed(1)} Million`;
//   }
//   return number.toString();
// };

// const Hero = () => {
//   const [imageLoaded, setImageLoaded] = useState(false);
//   const [isModalOpen, setIsModalOpen] = useState(false);
  
//   const toggleModal = () => {
//     setIsModalOpen(!isModalOpen);
//   };

//   useEffect(() => {
//     const handleImageLoad = () => {
//       setImageLoaded(true);
//     };
//     const img = new Image();
//     img.onload = handleImageLoad;
//     img.src = hero;

//     return () => {
//       img.onload = null;
//     };
//   }, []);

//   return (
//     <div className="hero-container flex flex-col items-center justify-center py-10 bg-white">
//       <div className="hero-content text-center max-w-4xl mt-10">
//         <h1 className="text-4xl md:text-5xl font-bold text-primary mb-6">
//           Responsible and Ethical AI for
//         </h1>
//         <div className="typewriter-container mb-6">
//           <Typewriter
//             options={{
//               strings: [
//                 "Researchers",
//                 "Educators",
//                 "Students",
//                 "Academics",
//                 "Scientists",
//                 "Scholars",
//                 "Data Analysts",
//                 "Lecturers"
//               ],
//               autoStart: true,
//               loop: true,
//               deleteSpeed: 50,
//               delay: 75,
//               wrapperClassName: "text-secondary text-2xl md:text-3xl",
//               cursorClassName: "text-secondary text-2xl md:text-3xl",
//             }}
//             className="typewriter-text"
//           />
//         </div>
//         <button
//           type="button"
//           className="bg-backgroundBlue text-primary hover:bg-hover1 active:bg-secondary shadow-md py-2 px-4 rounded-lg mb-10"
//           onClick={() =>
//             (window.location.href =
//               "https://fllky8j5bmm.typeform.com/to/WHfbZpRJ")
//           }
//         >
//           Apply for early access
//         </button>

//         <div className="hero-image mb-8 max-w-md md:max-w-5xl">
//           <div className={`border-4 rounded-xl border-tertiary overflow-hidden transition-shadow duration-300 ${imageLoaded ? "shadow-md" : ""}`}>
//             <img
//               src={hero}
//               alt="Screenshot of the Serenity platform interface"
//               className={`block w-full h-auto object-cover transition-opacity duration-300 ${imageLoaded ? "opacity-100" : "opacity-0"}`}
//               onLoad={() => setImageLoaded(true)}
//             />
//           </div>
//         </div>

//         <div className="stats-section grid grid-cols-1 sm:grid-cols-3 gap-4">
//           <div className="stat-item bg-white shadow-lg rounded-xl p-6 text-center">
//             <Counter
//               end={214000000}
//               duration={3}
//               formatFunc={formatNumber}
//               className="text-4xl font-bold text-secondary mb-2"
//             />
//             <p className="text-xl font-semibold text-gray-800">Papers</p>
//           </div>
//           <div className="stat-item bg-white shadow-lg rounded-xl p-6 text-center">
//             <Counter
//               end={2490000000}
//               duration={3}
//               formatFunc={formatNumber}
//               className="text-4xl font-bold text-secondary mb-2"
//             />
//             <p className="text-xl font-semibold text-gray-800">Citations</p>
//           </div>
//           <div className="stat-item bg-white shadow-lg rounded-xl p-6 text-center">
//             <Counter
//               end={79000000}
//               duration={3}
//               formatFunc={formatNumber}
//               className="text-4xl font-bold text-secondary mb-2"
//             />
//             <p className="text-xl font-semibold text-gray-800">Authors</p>
//           </div>
//         </div>

//         <section className="tiles-section grid grid-cols-1 md:grid-cols-4 gap-4 mt-8">
//           <div className="tile bg-hover1 border border-tertiary rounded-xl p-6 text-center">
//             <div className="mb-4">
//               <Syringe className="mx-auto text-secondary" size={48} />
//             </div>
//             <h3 className="text-xl font-semibold text-gray-800">
//               Corporate, Pharma & Enterprise
//             </h3>
//             <p className="mt-2 text-gray-600">
//               Stay on top of drug development & efficacy, clinical trials, and more
//             </p>
//           </div>
//           <div className="tile bg-hover1 border border-tertiary rounded-xl p-6 text-center">
//             <div className="mb-4">
//               <Briefcase className="mx-auto text-secondary" size={48} />
//             </div>
//             <h3 className="text-xl font-semibold text-gray-800">
//               Universities & Governments
//             </h3>
//             <p className="mt-2 text-gray-600">
//               Track and improve research outcomes across your organization
//             </p>
//           </div>
//           <div className="tile bg-hover1 border border-tertiary rounded-xl p-6 text-center">
//             <div className="mb-4">
//               <Microscope className="mx-auto text-secondary" size={48} />
//             </div>
//             <h3 className="text-xl font-semibold text-gray-800">Researchers</h3>
//             <p className="mt-2 text-gray-600">
//               See exactly how publications have been cited by newer research
//             </p>
//           </div>
//           <div className="tile bg-hover1 border border-tertiary rounded-xl p-6 text-center">
//             <div className="mb-4">
//               <BookOpenCheck className="mx-auto text-secondary" size={48} />
//             </div>
//             <h3 className="text-xl font-semibold text-gray-800">Publishers</h3>
//             <p className="mt-2 text-gray-600">
//               Improve discoverability of your publications and support your authors
//             </p>
//           </div>
//         </section>
//       </div>

//       {isModalOpen && (
//         <div className="fixed inset-0 z-50 flex justify-center items-center bg-tertiary bg-opacity-50">
//           <div className="bg-white p-6 rounded-xl relative max-w-sm w-full">
//             <button
//               className="absolute top-2 right-2 text-secondary hover:text-gray-700"
//               onClick={toggleModal}
//             >
//               <svg
//                 className="w-6 h-6"
//                 fill="none"
//                 viewBox="0 0 24 24"
//                 stroke="currentColor"
//               >
//                 <path
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                   strokeWidth={2}
//                   d="M6 18L18 6M6 6l12 12"
//                 />
//               </svg>
//             </button>
//             <h2 className="mb-4 text-center text-tertiary">Sign Up</h2>

//             <div className="mb-4 flex items-center justify-center">
//               <hr className="border-tertiary w-full" />
//             </div>
//             <button className="bg-primary text-white px-4 py-2 rounded-xl mb-4 w-full">
//               Sign up with Email
//             </button>
//             <button className="bg-primary text-white px-4 py-2 rounded-xl mb-4 w-full">
//               Sign up with Google
//             </button>
//             <p className="text-center text-secondary text-xs mt-4">
//               By signing up, you agree to our Terms and Conditions.
//             </p>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Hero;





// import React, { useState, useEffect } from "react";
// import Typewriter from "typewriter-effect";
// import Counter from "./Counter"; // Assuming you have created the Counter component as described
// import { Syringe, Briefcase, Microscope, BookOpenCheck } from "lucide-react";
// import hero from "../../assets/scdemo.png";

// const formatNumber = (number) => {
//   if (number >= 1e9) {
//     return `${(number / 1e9).toFixed(1)} Billion`;
//   } else if (number >= 1e6) {
//     return `${(number / 1e6).toFixed(1)} Million`;
//   }
//   return number.toString();
// };

// const Hero = () => {
//   const [imageLoaded, setImageLoaded] = useState(false);
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   const toggleModal = () => {
//     setIsModalOpen(!isModalOpen);
//   };

//   useEffect(() => {
//     const handleImageLoad = () => {
//       setImageLoaded(true);
//     };
//     const img = new Image();
//     img.onload = handleImageLoad;
//     img.src = hero;

//     return () => {
//       img.onload = null;
//     };
//   }, []);

//   return (
//     <div className="hero-container flex flex-col items-center justify-center py-10 bg-white">
//       <div className="hero-content text-center max-w-4xl mt-10">
//         <h1 className="text-4xl md:text-5xl font-bold text-primary mb-6">
//           Responsible and Ethical AI for
//         </h1>
//         <div className="typewriter-container mb-6">
//           <Typewriter
//             options={{
//               strings: [
//                 "Researchers",
//                 "Educators",
//                 "Students",
//                 "Academics",
//                 "Scientists",
//                 "Scholars",
//                 "Data Analysts",
//                 "Lecturers",
//               ],
//               autoStart: true,
//               loop: true,
//               deleteSpeed: 50,
//               delay: 75,
//               wrapperClassName: "text-secondary text-2xl md:text-3xl",
//               cursorClassName: "text-secondary text-2xl md:text-3xl",
//             }}
//             className="typewriter-text"
//           />
//         </div>
//         <button
//           type="button"
//           className="bg-backgroundBlue text-primary hover:bg-hover1 active:bg-secondary shadow-md py-2 px-4 rounded-lg mb-10"
//           onClick={() =>
//             (window.location.href =
//               "https://fllky8j5bmm.typeform.com/to/WHfbZpRJ")
//           }
//         >
//           Apply for early access
//         </button>

//         <div className="hero-image mb-8 max-w-md md:max-w-5xl">
//           <div
//             className={`border-4 rounded-xl border-tertiary overflow-hidden transition-shadow duration-300 ${
//               imageLoaded ? "shadow-md" : ""
//             }`}
//           >
//             <img
//               src={hero}
//               alt="Screenshot of the Serenity platform interface"
//               className={`block w-full h-auto object-cover transition-opacity duration-300 ${
//                 imageLoaded ? "opacity-100" : "opacity-0"
//               }`}
//               onLoad={() => setImageLoaded(true)}
//             />
//           </div>
//         </div>

//         <StatsSection />

//         <TilesSection />

//         {isModalOpen && <SignUpModal toggleModal={toggleModal} />}
//       </div>
//     </div>
//   );
// };

// const StatsSection = () => (
//   <div className="stats-section grid grid-cols-1 sm:grid-cols-3 gap-4">
//     <StatItem end={214000000} label="Papers" />
//     <StatItem end={2490000000} label="Citations" />
//     <StatItem end={79000000} label="Authors" />
//   </div>
// );

// const StatItem = ({ end, label }) => (
//   <div className="stat-item bg-white shadow-lg rounded-xl p-6 text-center">
//     <Counter
//       end={end}
//       duration={3}
//       formatFunc={formatNumber}
//       className="text-4xl font-bold text-secondary mb-2"
//     />
//     <p className="text-xl font-semibold text-gray-800">{label}</p>
//   </div>
// );

// const TilesSection = () => (
//   <section className="tiles-section grid grid-cols-1 md:grid-cols-4 gap-4 mt-8">
//     <Tile
//       icon={<Syringe className="mx-auto text-secondary" size={48} />}
//       title="Corporate, Pharma & Enterprise"
//       description="Stay on top of drug development & efficacy, clinical trials, and more"
//     />
//     <Tile
//       icon={<Briefcase className="mx-auto text-secondary" size={48} />}
//       title="Universities & Governments"
//       description="Track and improve research outcomes across your organization"
//     />
//     <Tile
//       icon={<Microscope className="mx-auto text-secondary" size={48} />}
//       title="Researchers"
//       description="See exactly how publications have been cited by newer research"
//     />
//     <Tile
//       icon={<BookOpenCheck className="mx-auto text-secondary" size={48} />}
//       title="Publishers"
//       description="Improve discoverability of your publications and support your authors"
//     />
//   </section>
// );

// const Tile = ({ icon, title, description }) => (
//   <div className="tile bg-hover1 border border-tertiary rounded-xl p-6 text-center">
//     <div className="mb-4">{icon}</div>
//     <h3 className="text-xl font-semibold text-gray-800">{title}</h3>
//     <p className="mt-2 text-gray-600">{description}</p>
//   </div>
// );

// const SignUpModal = ({ toggleModal }) => (
//   <div className="fixed inset-0 z-50 flex justify-center items-center bg-tertiary bg-opacity-50">
//     <div className="bg-white p-6 rounded-xl relative max-w-sm w-full">
//       <button
//         className="absolute top-2 right-2 text-secondary hover:text-gray-700"
//         onClick={toggleModal}
//       >
//         <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//           <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
//         </svg>
//       </button>
//       <h2 className="mb-4 text-center text-tertiary">Sign Up</h2>

//       <div className="mb-4 flex items-center justify-center">
//         <hr className="border-tertiary w-full" />
//       </div>
//       <button className="bg-primary text-white px-4 py-2 rounded-xl mb-4 w-full">
//         Sign up with Email
//       </button>
//       <button className="bg-primary text-white px-4 py-2 rounded-xl mb-4 w-full">
//         Sign up with Google
//       </button>
//       <p className="text-center text-secondary text-xs mt-4">
//         By signing up, you agree to our Terms and Conditions.
//       </p>
//     </div>
//   </div>
// );

// export default Hero;











//     return (
//       <div className="mx-3 md:mx-20 my-6">
//         <section className="bg-white dark:bg-gray-900">
//           <div className="py-8 px-4 pt-20 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-12">
//             {/* Beta Alert */}
//             <div className="mb-7">
//               {/* Beta Alert */}
             
    
//               {/* Hero Text */}
//               <h1 className="header-text text-5xl font-black tracking-tight leading-none text-gray-800 md:text-2xl lg:text-4xl dark:text-white mb-4">
//                 <span className="static-text">Responsible AI for</span>
//                 <span className="text-primary md:text-2xl lg:text-2xl">
//                   {/* Apply the text color here */}
//                   <Typewriter
//                     options={{
//                       strings: ['Researchers', 'Professionals', 'Writers', 'Students'],
//                       autoStart: true,
//                       loop: true,
//                       deleteSpeed: 50,
//                       delay: 75,
//                       wrapperClassName: "text-primary", // If Typewriter supports this prop
//                       cursorClassName: "text-primary" // If Typewriter supports this prop
//                     }}
//                   />
//                 </span>
//                 <br />
//               </h1>
//               {/* Description */}
//               <p className="mb-8 text-lg lg:text-l sm:px-16 xl:px-48 text-gray-400">
//                 Harness the power of AI to enhance the way you digest information. For researchers, students, and professionals.
//               </p>
//               {/* Logos */}
//               <div className="flex flex-col mb-8 lg:mb-16 space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4">
//                 {/* Logos Here */}
//               </div>
//               <div className="px-4 mx-auto text-center md:max-w-screen-md lg:max-w-screen-lg lg:px-36">
               
//                 <div className="flex justify-center items-center mt-6 space-x-2 sm:space-x-6"> {/* Reduce space-x on smaller screens */}
//                   {/* Logos Wrapper with Padding and Max Width */}
//                   <div className="px-4 mx-auto text-center md:max-w-screen-md lg:max-w-screen-lg lg:px-36">
//   <span className="text-gray-400">Endorsed by students and researchers from:</span>
//   <div className="flex justify-center items-center mt-6">
//   <div className="flex justify-center items-center flex-wrap w-full py-5">
//     {/* Set a smaller width for mobile and larger for tablets and up */}
//     <img src={uoft} alt="University of Toronto Logo" className="w-1/3 sm:w-1/4 md:w-1/4 p-2" />
//     <img src={uofc} alt="University of Calgary Logo" className="w-1/3 sm:w-1/4 md:w-1/4 p-2" />
//     <img src={uofa} alt="University of Alberta Logo" className="w-1/3 sm:w-1/4 md:w-1/4 p-2" />
//     <img src={ubc} alt="University of British Columbia Logo" className="w-1/3 sm:w-1/4 md:w-1/4 p-2" />
//   </div>
// </div>


// </div>






//                 </div>
//               </div>
//               <br />
//               <div className="mb-8 md:mb-16 flex justify-center">
//                 <div className={`border-4 border-gray-300 rounded-lg overflow-hidden transition-shadow duration-300 ${imageLoaded ? 'animate-pop-in shadow-md' : ''}`}>
//                   <img
//                     src={hero}
//                     alt="A screenshot of the Serenoty platform interface"
//                     className={`block w-full h-auto object-cover ${imageLoaded ? 'opacity-100' : 'opacity-0'}`}
//                     onLoad={() => setImageLoaded(true)} // Here we set imageLoaded to true when the image finishes loading
//                   />
//                 </div>
//               </div>
//             </div>
//           </div>
//         </section>
//       </div>
//     );
    
// };

// export default Hero;


// return (
//     <div className="mx-3 md:mx-20 my-6">
//       <section className="bg-white dark:bg-gray-900">
//         <div className=" hero-container py-8 px-4 pt-20 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-12">
//           {/* Hero Text */}
//           <h1 className="header-text text-5xl font-black tracking-tight leading-none text-gray-800 md:text-2xl lg:text-4xl dark:text-white mb-4">
//             <span className="static-text">Responsible AI for</span>
//             <span className="text-primary md:text-l lg:text-2xl">
//               {/* Apply the text color here */}
//               <Typewriter
//                 options={{
//                   strings: ['Researchers', 'Professionals', 'Writers', 'Students'],
//                   autoStart: true,
//                   loop: true,
//                   deleteSpeed: 50,
//                   delay: 75,
//                   wrapperClassName: "text-primary", // If Typewriter supports this prop
//                   cursorClassName: "text-primary" // If Typewriter supports this prop
//                 }}
//               />
//             </span>
//             <br />
//           </h1>
//           {/* Description */}
//           <p className="mb-8 text-lg lg:text-l sm:px-16 xl:px-48 text-gray-400">
//             Harness the power of AI to enhance the way you digest information. For researchers, students, and professionals.
//           </p>
//           {/* Logos */}

//           <span className="text-gray-400">Endorsed by students and researchers from:</span>

//           <div className="flex justify-center items-center mb-8 lg:mb-16">
//             {/* Logos Here */}
//             <img src={uoft} alt="University of Toronto Logo" className="w-1/4 sm:w-1/5 md:w-1/6 p-2" />
//             <img src={uofc} alt="University of Calgary Logo" className="w-1/4 sm:w-1/5 md:w-1/6 p-2" />
//             <img src={uofa} alt="University of Alberta Logo" className="w-1/4 sm:w-1/5 md:w-1/6 p-2" />
//             <img src={ubc} alt="University of British Columbia Logo" className="w-1/4 sm:w-1/5 md:w-1/6 p-2" />
//           </div>
//           {/* Image */}
//           <div className="mb-8 md:mb-16 flex justify-center">
//             <div className={`border-4 border-gray-300 rounded-lg overflow-hidden transition-shadow duration-300 ${imageLoaded ? 'animate-pop-in shadow-md' : ''}`}>
//               <img
//                 src={hero}
//                 alt="A screenshot of the Serenoty platform interface"
//                 className={`block w-full h-auto object-cover ${imageLoaded ? 'opacity-100' : 'opacity-0'}`}
//                 onLoad={() => setImageLoaded(true)} // Here we yset imageLoaded to true when the image finishes loading
//               />
//             </div>
//           </div>
//         </div>
//       </section>
//     </div>
//   );
// };

// export default Hero;



// import React, { useState, useEffect } from "react";
// import Typewriter from "typewriter-effect";
// import Counter from "./Counter"; // Assuming you have created the Counter component as described
// import { Syringe, Briefcase, Microscope, BookOpenCheck } from "lucide-react";
// import hero from "../../assets/scdemo.png";

// const formatNumber = (number) => {
//   if (number >= 1e9) {
//     return `${(number / 1e9).toFixed(1)} Billion`;
//   } else if (number >= 1e6) {
//     return `${(number / 1e6).toFixed(1)} Million`;
//   }
//   return number.toString();
// };

// const Hero = () => {
//   const [imageLoaded, setImageLoaded] = useState(false);
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   const toggleModal = () => {
//     setIsModalOpen(!isModalOpen);
//   };

//   useEffect(() => {
//     const handleImageLoad = () => {
//       setImageLoaded(true);
//     };
//     const img = new Image();
//     img.onload = handleImageLoad;
//     img.src = hero;

//     return () => {
//       img.onload = null;
//     };
//   }, []);

//   return (
//     <div className="hero-container flex flex-col items-center justify-center py-10 bg-white">
//       <div className="hero-content text-center max-w-4xl mt-10">
//         <h1 className="text-4xl md:text-5xl font-bold text-primary mb-6">
//           Responsible and Ethical AI for
//         </h1>
//         <div className="typewriter-container mb-6">
//           <Typewriter
//             options={{
//               strings: [
//                 "Researchers",
//                 "Educators",
//                 "Students",
//                 "Academics",
//                 "Scientists",
//                 "Scholars",
//                 "Data Analysts",
//                 "Lecturers",
//               ],
//               autoStart: true,
//               loop: true,
//               deleteSpeed: 50,
//               delay: 75,
//               wrapperClassName: "text-secondary text-2xl md:text-3xl",
//               cursorClassName: "text-secondary text-2xl md:text-3xl",
//             }}
//             className="typewriter-text"
//           />
//         </div>
//         <button
//           type="button"
//           className="bg-backgroundBlue text-primary hover:bg-hover1 active:bg-secondary shadow-md py-2 px-4 rounded-lg mb-10"
//           onClick={() =>
//             (window.location.href =
//               "https://fllky8j5bmm.typeform.com/to/WHfbZpRJ")
//           }
//         >
//           Apply for early access
//         </button>

//         <div className="hero-image mb-8 max-w-md md:max-w-5xl">
//           <div
//             className={`border-4 rounded-xl border-tertiary overflow-hidden transition-shadow duration-300 ${
//               imageLoaded ? "shadow-md" : ""
//             }`}
//           >
//             <img
//               src={hero}
//               alt="Screenshot of the Serenity platform interface"
//               className={`block w-full h-auto object-cover transition-opacity duration-300 ${
//                 imageLoaded ? "opacity-100" : "opacity-0"
//               }`}
//               onLoad={() => setImageLoaded(true)}
//             />
//           </div>
//         </div>

//         <StatsSection />

//         <TilesSection />

//         {isModalOpen && <SignUpModal toggleModal={toggleModal} />}
//       </div>
//     </div>
//   );
// };

// const StatsSection = () => (
//   <div className="stats-section grid grid-cols-1 sm:grid-cols-3 gap-6 mt-8 mb-8">
//     <StatItem end={214000000} label="Papers" />
//     <StatItem end={2490000000} label="Citations" />
//     <StatItem end={79000000} label="Authors" />
//   </div>
// );

// const StatItem = ({ end, label }) => (
//   <div className="stat-item bg-white shadow-lg rounded-xl p-6 text-center">
//     <Counter
//       end={end}
//       duration={3}
//       formatFunc={formatNumber}
//       className="text-4xl font-bold text-secondary mb-2"
//     />
//     <p className="text-xl font-semibold text-gray-800">{label}</p>
//   </div>
// );

// const TilesSection = () => (
//   <section className="tiles-section grid grid-cols-1 md:grid-cols-4 gap-6 mt-8">
//     <Tile
//       icon={<Syringe className="mx-auto text-secondary" size={48} />}
//       title="Corporate, Pharma & Enterprise"
//       description="Stay on top of drug development & efficacy, clinical trials, and more"
//     />
//     <Tile
//       icon={<Briefcase className="mx-auto text-secondary" size={48} />}
//       title="Universities & Governments"
//       description="Track and improve research outcomes across your organization"
//     />
//     <Tile
//       icon={<Microscope className="mx-auto text-secondary" size={48} />}
//       title="Researchers"
//       description="See exactly how publications have been cited by newer research"
//     />
//     <Tile
//       icon={<BookOpenCheck className="mx-auto text-secondary" size={48} />}
//       title="Publishers"
//       description="Improve discoverability of your publications and support your authors"
//     />
//   </section>
// );

// const Tile = ({ icon, title, description }) => (
//   <div className="tile bg-hover1 border border-tertiary rounded-xl p-6 text-center">
//     <div className="mb-4">{icon}</div>
//     <h3 className="text-xl font-semibold text-gray-800">{title}</h3>
//     <p className="mt-2 text-gray-600">{description}</p>
//   </div>
// );

// const SignUpModal = ({ toggleModal }) => (
//   <div className="fixed inset-0 z-50 flex justify-center items-center bg-tertiary bg-opacity-50">
//     <div className="bg-white p-6 rounded-xl relative max-w-sm w-full">
//       <button
//         className="absolute top-2 right-2 text-secondary hover:text-gray-700"
//         onClick={toggleModal}
//       >
//         <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//           <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
//         </svg>
//       </button>
//       <h2 className="mb-4 text-center text-tertiary">Sign Up</h2>

//       <div className="mb-4 flex items-center justify-center">
//         <hr className="border-tertiary w-full" />
//       </div>
//       <button className="bg-primary text-white px-4 py-2 rounded-xl mb-4 w-full">
//         Sign up with Email
//       </button>
//       <button className="bg-primary text-white px-4 py-2 rounded-xl mb-4 w-full">
//         Sign up with Google
//       </button>
//       <p className="text-center text-secondary text-xs mt-4">
//         By signing up, you agree to our Terms and Conditions.
//       </p>
//     </div>
//   </div>
// );

// export default Hero;


// import React, { useState, useEffect } from "react";
// import Typewriter from "typewriter-effect";
// import Counter from "./Counter"; // Assuming you have created the Counter component as described
// import { Syringe, Briefcase, Microscope, BookOpenCheck } from "lucide-react";
// import hero from "../../assets/scdemo.png";

// const formatNumber = (number) => {
//   if (number >= 1e9) {
//     return `${(number / 1e9).toFixed(1)} Billion`;
//   } else if (number >= 1e6) {
//     return `${(number / 1e6).toFixed(1)} Million`;
//   }
//   return number.toString();
// };

// const Hero = () => {
//   const [imageLoaded, setImageLoaded] = useState(false);
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   const toggleModal = () => {
//     setIsModalOpen(!isModalOpen);
//   };

//   useEffect(() => {
//     const handleImageLoad = () => {
//       setImageLoaded(true);
//     };
//     const img = new Image();
//     img.onload = handleImageLoad;
//     img.src = hero;

//     return () => {
//       img.onload = null;
//     };
//   }, []);

//   return (
//     <div className="hero-container flex flex-col items-center justify-center py-10 bg-white">
//       <div className="hero-content text-center max-w-4xl mt-10">
//         <h1 className="text-4xl md:text-5xl font-bold text-primary mb-6">
//           Responsible and Ethical AI for
//         </h1>
//         <div className="typewriter-container mb-6">
//           <Typewriter
//             options={{
//               strings: [
//                 "Researchers",
//                 "Educators",
//                 "Students",
//                 "Academics",
//                 "Scientists",
//                 "Scholars",
//                 "Data Analysts",
//                 "Lecturers",
//               ],
//               autoStart: true,
//               loop: true,
//               deleteSpeed: 50,
//               delay: 75,
//               wrapperClassName: "text-secondary text-2xl md:text-3xl",
//               cursorClassName: "text-secondary text-2xl md:text-3xl",
//             }}
//             className="typewriter-text"
//           />
//         </div>
//         <button
//           type="button"
//           className="bg-backgroundBlue text-primary hover:bg-hover1 active:bg-secondary shadow-md py-2 px-4 rounded-lg mb-10"
//           onClick={() =>
//             (window.location.href =
//               "https://fllky8j5bmm.typeform.com/to/WHfbZpRJ")
//           }
//         >
//           Apply for early access
//         </button>

//         <div className="hero-image mb-8 max-w-md md:max-w-5xl">
//           <div
//             className={`border-4 rounded-xl border-tertiary overflow-hidden transition-shadow duration-300 ${
//               imageLoaded ? "shadow-md" : ""
//             }`}
//           >
//             <img
//               src={hero}
//               alt="Screenshot of the Serenity platform interface"
//               className={`block w-full h-auto object-cover transition-opacity duration-300 ${
//                 imageLoaded ? "opacity-100" : "opacity-0"
//               }`}
//               onLoad={() => setImageLoaded(true)}
//             />
//           </div>
//         </div>

//         <StatsSection />

//         <TilesSection />

//         {isModalOpen && <SignUpModal toggleModal={toggleModal} />}
//       </div>
//     </div>
//   );
// };

// const StatsSection = () => (
//   <div className="stats-section grid grid-cols-1 sm:grid-cols-3 gap-6 mt-8 mb-8">
//     <StatItem end={214000000} label="Papers" />
//     <StatItem end={2490000000} label="Citations" />
//     <StatItem end={79000000} label="Authors" />
//   </div>
// );

// const StatItem = ({ end, label }) => (
//   <div className="stat-item bg-white shadow-lg rounded-xl p-6 text-center">
//     <Counter
//       end={end}
//       duration={3}
//       formatFunc={formatNumber}
//       className="text-4xl font-bold text-secondary mb-2"
//     />
//     <p className="text-xl font-semibold text-gray-800">{label}</p>
//   </div>
// );

// const TilesSection = () => (
//   <section className="tiles-section grid grid-cols-1 md:grid-cols-4 gap-6 mt-8">
//     <Tile
//       icon={<Syringe className="mx-auto text-secondary" size={48} />}
//       title="Corporate, Pharma & Enterprise"
//       description="Stay on top of drug development and efficacy, clinical trials, and more."
//     />
//     <Tile
//       icon={<Briefcase className="mx-auto text-secondary" size={48} />}
//       title="Universities & Governments"
//       description="Track and improve research outcomes across your organization."
//     />
//     <Tile
//       icon={<Microscope className="mx-auto text-secondary" size={48} />}
//       title="Researchers"
//       description="See exactly how publications have been cited by newer research."
//     />
//     <Tile
//       icon={<BookOpenCheck className="mx-auto text-secondary" size={48} />}
//       title="Publishers"
//       description="Improve discoverability of your publications and support your authors."
//     />
//   </section>
// );

// const Tile = ({ icon, title, description }) => (
//   <div className="tile bg-hover1 border border-tertiary rounded-xl p-6 text-center">
//     <div className="mb-4">{icon}</div>
//     <h3 className="text-xl font-semibold text-gray-800">{title}</h3>
//     <p className="mt-2 text-gray-600">{description}</p>
//   </div>
// );

// const SignUpModal = ({ toggleModal }) => (
//   <div className="fixed inset-0 z-50 flex justify-center items-center bg-tertiary bg-opacity-50">
//     <div className="bg-white p-6 rounded-xl relative max-w-sm w-full">
//       <button
//         className="absolute top-2 right-2 text-secondary hover:text-gray-700"
//         onClick={toggleModal}
//       >
//         <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//           <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
//         </svg>
//       </button>
//       <h2 className="mb-4 text-center text-tertiary">Sign Up</h2>

//       <div className="mb-4 flex items-center justify-center">
//         <hr className="border-tertiary w-full" />
//       </div>
//       <button className="bg-primary text-white px-4 py-2 rounded-xl mb-4 w-full">
//         Sign up with Email
//       </button>
//       <button className="bg-primary text-white px-4 py-2 rounded-xl mb-4 w-full">
//         Sign up with Google
//       </button>
//       <p className="text-center text-secondary text-xs mt-4">
//         By signing up, you agree to our Terms and Conditions.
//       </p>
//     </div>
//   </div>
// );

// export default Hero;

 
//  import React, { useState, useEffect } from "react";
//  import Typewriter from "typewriter-effect";
//  import Counter from "./Counter"; // Assuming you have created the Counter component as described
//  import { Syringe, Briefcase, Microscope, BookOpenCheck } from "lucide-react";
//  import hero from "../../assets/scdemo.png";
 
//  const formatNumber = (number) => {
//    if (number >= 1e9) {
//      return `${(number / 1e9).toFixed(1)} Billion`;
//    } else if (number >= 1e6) {
//      return `${(number / 1e6).toFixed(1)} Million`;
//    }
//    return number.toString();
//  };
 
//  const Hero = () => {
//    const [imageLoaded, setImageLoaded] = useState(false);
//    const [isModalOpen, setIsModalOpen] = useState(false);
 
//    const toggleModal = () => {
//      setIsModalOpen(!isModalOpen);
//    };
 
//    useEffect(() => {
//      const handleImageLoad = () => {
//        setImageLoaded(true);
//      };
//      const img = new Image();
//      img.onload = handleImageLoad;
//      img.src = hero;
 
//      return () => {
//        img.onload = null;
//      };
//    }, []);
 
//    return (
//      <div className="hero-container flex flex-col items-center justify-center py-10 bg-white">
//        <div className="hero-content text-center max-w-4xl mt-10">
//          <h1 className="text-4xl md:text-5xl font-bold text-primary mb-6">
//            Responsible and Ethical AI for
//          </h1>
//          <div className="typewriter-container mb-6">
//            <Typewriter
//              options={{
//                strings: [
//                  "Researchers",
//                  "Educators",
//                  "Students",
//                  "Academics",
//                  "Scientists",
//                  "Scholars",
//                  "Data Analysts",
//                  "Lecturers",
//                ],
//                autoStart: true,
//                loop: true,
//                deleteSpeed: 50,
//                delay: 75,
//                wrapperClassName: "text-secondary text-2xl md:text-3xl",
//                cursorClassName: "text-secondary text-2xl md:text-3xl",
//              }}
//              className="typewriter-text"
//            />
//          </div>
//          <button
//            type="button"
//            className="bg-backgroundBlue text-primary hover:bg-hover1 active:bg-secondary shadow-md py-2 px-4 rounded-lg mb-10"
//            onClick={() =>
//              (window.location.href =
//                "https://fllky8j5bmm.typeform.com/to/WHfbZpRJ")
//            }
//          >
//            Apply for early access
//          </button>
 
//          <div className="hero-image mb-8 max-w-md md:max-w-5xl">
//            <div
//              className={`border-4 rounded-xl border-tertiary overflow-hidden transition-shadow duration-300 ${
//                imageLoaded ? "shadow-md" : ""
//              }`}
//            >
//              <img
//                src={hero}
//                alt="Screenshot of the Serenity platform interface"
//                className={`block w-full h-auto object-cover transition-opacity duration-300 ${
//                  imageLoaded ? "opacity-100" : "opacity-0"
//                }`}
//                onLoad={() => setImageLoaded(true)}
//              />
//            </div>
//          </div>
 
//          <StatsSection />
 
//          <TilesSection />
 
//          {isModalOpen && <SignUpModal toggleModal={toggleModal} />}
//        </div>
//      </div>
//    );
//  };
 
//  const StatsSection = () => (
//    <div className="stats-section text-center mt-8 mb-8">
//      <h2 className="text-2xl font-bold text-primary mb-4">Extensive Research Database</h2>
//      <p className="text-lg text-gray-700 mb-6">
//        Access millions of papers, citations, and authors for your research needs.
//      </p>
//      <div className="grid grid-cols-1 sm:grid-cols-3 gap-6">
//        <StatItem end={214000000} label="Papers" />
//        <StatItem end={2490000000} label="Citations" />
//        <StatItem end={79000000} label="Authors" />
//      </div>
//    </div>
//  );
 
//  const StatItem = ({ end, label }) => (
//    <div className="stat-item bg-white shadow-lg rounded-xl p-6 text-center">
//      <Counter
//        end={end}
//        duration={3}
//        formatFunc={formatNumber}
//        className="text-4xl font-bold text-secondary mb-2"
//      />
//      <p className="text-xl font-semibold text-gray-800">{label}</p>
//    </div>
//  );
 
//  const TilesSection = () => (
//    <section className="tiles-section grid grid-cols-1 md:grid-cols-4 gap-6 mt-8">
//      <Tile
//        icon={<Syringe className="mx-auto text-secondary" size={48} />}
//        title="Corporate, Pharma & Enterprise"
//        description="Stay on top of drug development and efficacy, clinical trials, and more."
//      />
//      <Tile
//        icon={<Briefcase className="mx-auto text-secondary" size={48} />}
//        title="Universities & Governments"
//        description="Track and improve research outcomes across your organization."
//      />
//      <Tile
//        icon={<Microscope className="mx-auto text-secondary" size={48} />}
//        title="Researchers"
//        description="See exactly how publications have been cited by newer research."
//      />
//      <Tile
//        icon={<BookOpenCheck className="mx-auto text-secondary" size={48} />}
//        title="Publishers"
//        description="Improve discoverability of your publications and support your authors."
//      />
//    </section>
//  );
 
//  const Tile = ({ icon, title, description }) => (
//    <div className="tile bg-hover1 border border-tertiary rounded-xl p-6 text-center">
//      <div className="mb-4">{icon}</div>
//      <h3 className="text-xl font-semibold text-gray-800">{title}</h3>
//      <p className="mt-2 text-gray-600">{description}</p>
//    </div>
//  );
 
//  const SignUpModal = ({ toggleModal }) => (
//    <div className="fixed inset-0 z-50 flex justify-center items-center bg-tertiary bg-opacity-50">
//      <div className="bg-white p-6 rounded-xl relative max-w-sm w-full">
//        <button
//          className="absolute top-2 right-2 text-secondary hover:text-gray-700"
//          onClick={toggleModal}
//        >
//          <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
//          </svg>
//        </button>
//        <h2 className="mb-4 text-center text-tertiary">Sign Up</h2>
 
//        <div className="mb-4 flex items-center justify-center">
//          <hr className="border-tertiary w-full" />
//        </div>
//        <button className="bg-primary text-white px-4 py-2 rounded-xl mb-4 w-full">
//          Sign up with Email
//        </button>
//        <button className="bg-primary text-white px-4 py-2 rounded-xl mb-4 w-full">
//          Sign up with Google
//        </button>
//        <p className="text-center text-secondary text-xs mt-4">
//          By signing up, you agree to our Terms and Conditions.
//        </p>
//      </div>
//    </div>
//  );
 
//  export default Hero;
 


// import React, { useState, useEffect } from "react";
// import Typewriter from "typewriter-effect";
// import Counter from "./Counter"; // Assuming you have created the Counter component as described
// import { Syringe, Briefcase, Microscope, BookOpenCheck } from "lucide-react";
// import hero from "../../assets/home.png";

// const formatNumber = (number) => {
//   if (number >= 1e9) {
//     return `${(number / 1e9).toFixed(1)} Billion`;
//   } else if (number >= 1e6) {
//     return `${(number / 1e6).toFixed(1)} Million`;
//   }
//   return number.toString();
// };

// const Hero = () => {
//   const [imageLoaded, setImageLoaded] = useState(false);
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   const toggleModal = () => {
//     setIsModalOpen(!isModalOpen);
//   };

//   useEffect(() => {
//     const handleImageLoad = () => {
//       setImageLoaded(true);
//     };
//     const img = new Image();
//     img.onload = handleImageLoad;
//     img.src = hero;

//     return () => {
//       img.onload = null;
//     };
//   }, []);

//   return (
//     <div className="hero-container flex flex-col items-center justify-center py-10 bg-white">
//       <div className="hero-content text-center max-w-4xl mt-10">
//         <h1 className="text-4xl md:text-5xl font-bold text-primary mb-6">
//           Responsible and Ethical AI for
//         </h1>
//         <div className="typewriter-container mb-6">
//           <Typewriter
//             options={{
//               strings: [
//                 "Researchers",
//                 "Educators",
//                 "Students",
//                 "Academics",
//                 "Scientists",
//                 "Scholars",
//                 "Data Analysts",
//                 "Lecturers",
//               ],
//               autoStart: true,
//               loop: true,
//               deleteSpeed: 50,
//               delay: 75,
//               wrapperClassName: "text-secondary text-2xl md:text-3xl",
//               cursorClassName: "text-secondary text-2xl md:text-3xl",
//             }}
//             className="typewriter-text"
//           />
//         </div>
//         <button
//           type="button"
//           className="bg-backgroundBlue text-primary hover:bg-hover1 active:bg-secondary shadow-md py-2 px-4 rounded-lg mb-10"
//           onClick={() =>
//             (window.location.href =
//               "https://fllky8j5bmm.typeform.com/to/WHfbZpRJ")
//           }
//         >
//           Apply for early access
//         </button>

//         <div className="hero-image mb-8 max-w-md md:max-w-5xl">
//           <img
//             src={hero}
//             alt="Screenshot of the Serenity platform interface"
//             className={`block w-full h-auto object-cover transition-opacity duration-300 ${
//               imageLoaded ? "opacity-100" : "opacity-0"
//             }`}
//             onLoad={() => setImageLoaded(true)}
//           />
//         </div>

//         <StatsSection />

//         <TilesSection />

//         {isModalOpen && <SignUpModal toggleModal={toggleModal} />}
//       </div>
//     </div>
//   );
// };

// const StatsSection = () => (
//   <div className="stats-section text-center mt-8 mb-8">
//     <h2 className="text-2xl font-bold text-primary mb-4">Extensive Research Database</h2>
//     <p className="text-lg text-gray-700 mb-6">
//       Access millions of papers, citations, and authors for your research needs.
//     </p>
//     <div className="grid grid-cols-1 sm:grid-cols-3 gap-6">
//       <StatItem end={214000000} label="Papers" />
//       <StatItem end={2490000000} label="Citations" />
//       <StatItem end={79000000} label="Authors" />
//     </div>
//   </div>
// );

// const StatItem = ({ end, label }) => (
//   <div className="stat-item bg-white shadow-lg rounded-xl p-6 text-center">
//     <Counter
//       end={end}
//       duration={3}
//       formatFunc={formatNumber}
//       className="text-4xl font-bold text-secondary mb-2"
//     />
//     <p className="text-xl font-semibold text-gray-800">{label}</p>
//   </div>
// );

// const TilesSection = () => (
//   <section className="tiles-section grid grid-cols-1 md:grid-cols-4 gap-6 mt-8">
//     <Tile
//       icon={<Syringe className="mx-auto text-secondary" size={48} />}
//       title="Corporate, Pharma & Enterprise"
//       description="Stay on top of drug development and efficacy, clinical trials, and more."
//     />
//     <Tile
//       icon={<Briefcase className="mx-auto text-secondary" size={48} />}
//       title="Universities & Governments"
//       description="Track and improve research outcomes across your organization."
//     />
//     <Tile
//       icon={<Microscope className="mx-auto text-secondary" size={48} />}
//       title="Researchers"
//       description="See exactly how publications have been cited by newer research."
//     />
//     <Tile
//       icon={<BookOpenCheck className="mx-auto text-secondary" size={48} />}
//       title="Publishers"
//       description="Improve discoverability of your publications and support your authors."
//     />
//   </section>
// );

// const Tile = ({ icon, title, description }) => (
//   <div className="tile bg-hover1 rounded-xl p-6 text-center">
//     <div className="mb-4">{icon}</div>
//     <h3 className="text-xl font-semibold text-gray-800">{title}</h3>
//     <p className="mt-2 text-gray-600">{description}</p>
//   </div>
// );

// const SignUpModal = ({ toggleModal }) => (
//   <div className="fixed inset-0 z-50 flex justify-center items-center bg-tertiary bg-opacity-50">
//     <div className="bg-white p-6 rounded-xl relative max-w-sm w-full">
//       <button
//         className="absolute top-2 right-2 text-secondary hover:text-gray-700"
//         onClick={toggleModal}
//       >
//         <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//           <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
//         </svg>
//       </button>
//       <h2 className="mb-4 text-center text-tertiary">Sign Up</h2>

//       <div className="mb-4 flex items-center justify-center">
//         <hr className="border-tertiary w-full" />
//       </div>
//       <button className="bg-primary text-white px-4 py-2 rounded-xl mb-4 w-full">
//         Sign up with Email
//       </button>
//       <button className="bg-primary text-white px-4 py-2 rounded-xl mb-4 w-full">
//         Sign up with Google
//       </button>
//       <p className="text-center text-secondary text-xs mt-4">
//         By signing up, you agree to our Terms and Conditions.
//       </p>
//     </div>
//   </div>
// );

// export default Hero;


// import React, { useState, useEffect } from "react";
// import Typewriter from "typewriter-effect";
// import Counter from "./Counter"; // Assuming you have created the Counter component as described
// import { Syringe, Briefcase, Microscope, BookOpenCheck } from "lucide-react";
// import hero from "../../assets/vr01.png";

// const formatNumber = (number) => {
//   if (number >= 1e9) {
//     return `${(number / 1e9).toFixed(1)} Billion`;
//   } else if (number >= 1e6) {
//     return `${(number / 1e6).toFixed(1)} Million`;
//   }
//   return number.toString();
// };

// const Hero = () => {
//   const [imageLoaded, setImageLoaded] = useState(false);
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   const toggleModal = () => {
//     setIsModalOpen(!isModalOpen);
//   };

//   useEffect(() => {
//     const handleImageLoad = () => {
//       setImageLoaded(true);
//     };
//     const img = new Image();
//     img.onload = handleImageLoad;
//     img.src = hero;

//     return () => {
//       img.onload = null;
//     };
//   }, []);

//   return (
//     <div className="hero-container flex flex-col items-center justify-center py-10 bg-white">
//       <div className="hero-content text-center max-w-4xl mt-10">
//         <h1 className="text-4xl md:text-5xl font-bold text-primary mb-6">
//           Responsible and Ethical AI for
//         </h1>
//         <div className="typewriter-container mb-6">
//           <Typewriter
//             options={{
//               strings: [
//                 "Researchers",
//                 "Educators",
//                 "Students",
//                 "Academics",
//                 "Scientists",
//                 "Scholars",
//                 "Data Analysts",
//                 "Lecturers",
//               ],
//               autoStart: true,
//               loop: true,
//               deleteSpeed: 50,
//               delay: 75,
//               wrapperClassName: "text-secondary text-2xl md:text-3xl",
//               cursorClassName: "text-secondary text-2xl md:text-3xl",
//             }}
//             className="typewriter-text"
//           />
//         </div>
//         <button
//           type="button"
//           className="bg-backgroundBlue text-primary hover:bg-hover1 active:bg-secondary shadow-md py-2 px-4 rounded-lg mb-10"
//           onClick={() =>
//             (window.location.href =
//               "https://fllky8j5bmm.typeform.com/to/WHfbZpRJ")
//           }
//         >
//           Apply for early access
//         </button>

//         <div className="hero-image mb-8 max-w-md md:max-w-5xl ipad-style">
//           <img
//             src={hero}
//             alt="Screenshot of the Serenity platform interface"
//             className={`block w-full h-auto object-cover transition-opacity duration-300 ${
//               imageLoaded ? "opacity-100" : "opacity-0"
//             }`}
//             onLoad={() => setImageLoaded(true)}
//           />
//         </div>

//         <StatsSection />

//         <TilesSection />

//         {isModalOpen && <SignUpModal toggleModal={toggleModal} />}
//       </div>
//     </div>
//   );
// };

// const StatsSection = () => (
//   <div className="stats-section text-center mt-8 mb-8">
//     <h2 className="text-2xl font-bold text-primary mb-4">Extensive Research Database</h2>
//     <p className="text-lg text-gray-700 mb-6">
//       Access millions of papers, citations, and authors for your research needs.
//     </p>
//     <div className="grid grid-cols-1 sm:grid-cols-3 gap-6">
//       <StatItem end={214000000} label="Papers" />
//       <StatItem end={2490000000} label="Citations" />
//       <StatItem end={79000000} label="Authors" />
//     </div>
//   </div>
// );

// const StatItem = ({ end, label }) => (
//   <div className="stat-item bg-white shadow-lg rounded-xl p-6 text-center">
//     <Counter
//       end={end}
//       duration={3}
//       formatFunc={formatNumber}
//       className="text-4xl font-bold text-secondary mb-2"
//     />
//     <p className="text-xl font-semibold text-gray-800">{label}</p>
//   </div>
// );

// const TilesSection = () => (
//   <section className="tiles-section grid grid-cols-1 md:grid-cols-4 gap-6 mt-8">
//     <Tile
//       icon={<Syringe className="mx-auto text-secondary" size={48} />}
//       title="Corporate, Pharma & Enterprise"
//       description="Stay on top of drug development and efficacy, clinical trials, and more."
//     />
//     <Tile
//       icon={<Briefcase className="mx-auto text-secondary" size={48} />}
//       title="Universities & Governments"
//       description="Track and improve research outcomes across your organization."
//     />
//     <Tile
//       icon={<Microscope className="mx-auto text-secondary" size={48} />}
//       title="Researchers"
//       description="See exactly how publications have been cited by newer research."
//     />
//     <Tile
//       icon={<BookOpenCheck className="mx-auto text-secondary" size={48} />}
//       title="Publishers"
//       description="Improve discoverability of your publications and support your authors."
//     />
//   </section>
// );

// const Tile = ({ icon, title, description }) => (
//   <div className="tile bg-hover1 rounded-xl p-6 text-center">
//     <div className="mb-4">{icon}</div>
//     <h3 className="text-xl font-semibold text-gray-800">{title}</h3>
//     <p className="mt-2 text-gray-600">{description}</p>
//   </div>
// );

// const SignUpModal = ({ toggleModal }) => (
//   <div className="fixed inset-0 z-50 flex justify-center items-center bg-tertiary bg-opacity-50">
//     <div className="bg-white p-6 rounded-xl relative max-w-sm w-full">
//       <button
//         className="absolute top-2 right-2 text-secondary hover:text-gray-700"
//         onClick={toggleModal}
//       >
//         <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//           <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
//         </svg>
//       </button>
//       <h2 className="mb-4 text-center text-tertiary">Sign Up</h2>

//       <div className="mb-4 flex items-center justify-center">
//         <hr className="border-tertiary w-full" />
//       </div>
//       <button className="bg-primary text-white px-4 py-2 rounded-xl mb-4 w-full">
//         Sign up with Email
//       </button>
//       <button className="bg-primary text-white px-4 py-2 rounded-xl mb-4 w-full">
//         Sign up with Google
//       </button>
//       <p className="text-center text-secondary text-xs mt-4">
//         By signing up, you agree to our Terms and Conditions.
//       </p>
//     </div>
//   </div>
// );

// export default Hero;

//  import React, { useState, useEffect } from "react";
//  import Typewriter from "typewriter-effect";
//  import Counter from "./Counter"; // Assuming you have created the Counter component as described
//  import { Syringe, Briefcase, Microscope, BookOpenCheck } from "lucide-react";
//  import hero from "../../assets/vr01.png";
 
//  const formatNumber = (number) => {
//    if (number >= 1e9) {
//      return `${(number / 1e9).toFixed(1)} Billion`;
//    } else if (number >= 1e6) {
//      return `${(number / 1e6).toFixed(1)} Million`;
//    }
//    return number.toString();
//  };
 
//  const Hero = () => {
//    const [imageLoaded, setImageLoaded] = useState(false);
//    const [isModalOpen, setIsModalOpen] = useState(false);
 
//    const toggleModal = () => {
//      setIsModalOpen(!isModalOpen);
//    };
 
//    useEffect(() => {
//      const handleImageLoad = () => {
//        setImageLoaded(true);
//      };
//      const img = new Image();
//      img.onload = handleImageLoad;
//      img.src = hero;
 
//      return () => {
//        img.onload = null;
//      };
//    }, []);
 
//    return (
//      <div className="hero-container flex flex-col items-center justify-center py-10 bg-white">
//        <div className="hero-content text-center max-w-4xl mt-10">
//          <h1 className="text-4xl md:text-5xl font-bold text-primary mb-6">
//            Responsible and Ethical AI for
//          </h1>
//          <div className="typewriter-container mb-6">
//            <Typewriter
//              options={{
//                strings: [
//                  "Researchers",
//                  "Educators",
//                  "Students",
//                  "Academics",
//                  "Scientists",
//                  "Scholars",
//                  "Data Analysts",
//                  "Lecturers",
//                ],
//                autoStart: true,
//                loop: true,
//                deleteSpeed: 50,
//                delay: 75,
//                wrapperClassName: "text-secondary text-2xl md:text-3xl",
//                cursorClassName: "text-secondary text-2xl md:text-3xl",
//              }}
//              className="typewriter-text"
//            />
//          </div>
//          <button
//            type="button"
//            className="bg-backgroundBlue text-primary hover:bg-hover1 active:bg-secondary shadow-md py-2 px-4 rounded-lg mb-10"
//            onClick={() =>
//              (window.location.href =
//                "https://fllky8j5bmm.typeform.com/to/WHfbZpRJ")
//            }
//          >
//            Apply for early access
//          </button>
 
//          <div className="hero-image mb-8 max-w-md md:max-w-5xl ipad-style">
//            <div className="ipad-frame">
//              <img
//                src={hero}
//                alt="Screenshot of the Serenity platform interface"
//                className={`block w-full h-auto object-cover transition-opacity duration-300 ${
//                  imageLoaded ? "opacity-100" : "opacity-0"
//                }`}
//                onLoad={() => setImageLoaded(true)}
//              />
//            </div>
//          </div>
 
//          <StatsSection />
 
//          <TilesSection />
 
//          {isModalOpen && <SignUpModal toggleModal={toggleModal} />}
//        </div>
//      </div>
//    );
//  };
 
//  const StatsSection = () => (
//    <div className="stats-section text-center mt-8 mb-8">
//      <h2 className="text-2xl font-bold text-primary mb-4">Extensive Research Database</h2>
//      <p className="text-lg text-gray-700 mb-6">
//        Access millions of papers, citations, and authors for your research needs.
//      </p>
//      <div className="grid grid-cols-1 sm:grid-cols-3 gap-6">
//        <StatItem end={214000000} label="Papers" />
//        <StatItem end={2490000000} label="Citations" />
//        <StatItem end={79000000} label="Authors" />
//      </div>
//    </div>
//  );
 
//  const StatItem = ({ end, label }) => (
//    <div className="stat-item bg-white shadow-lg rounded-xl p-6 text-center">
//      <Counter
//        end={end}
//        duration={3}
//        formatFunc={formatNumber}
//        className="text-4xl font-bold text-secondary mb-2"
//      />
//      <p className="text-xl font-semibold text-gray-800">{label}</p>
//    </div>
//  );
 
//  const TilesSection = () => (
//    <section className="tiles-section grid grid-cols-1 md:grid-cols-4 gap-6 mt-8">
//      <Tile
//        icon={<Syringe className="mx-auto text-secondary" size={48} />}
//        title="Corporate, Pharma & Enterprise"
//        description="Stay on top of drug development and efficacy, clinical trials, and more."
//      />
//      <Tile
//        icon={<Briefcase className="mx-auto text-secondary" size={48} />}
//        title="Universities & Governments"
//        description="Track and improve research outcomes across your organization."
//      />
//      <Tile
//        icon={<Microscope className="mx-auto text-secondary" size={48} />}
//        title="Researchers"
//        description="See exactly how publications have been cited by newer research."
//      />
//      <Tile
//        icon={<BookOpenCheck className="mx-auto text-secondary" size={48} />}
//        title="Publishers"
//        description="Improve discoverability of your publications and support your authors."
//      />
//    </section>
//  );
 
//  const Tile = ({ icon, title, description }) => (
//    <div className="tile bg-hover1 rounded-xl p-6 text-center">
//      <div className="mb-4">{icon}</div>
//      <h3 className="text-xl font-semibold text-gray-800">{title}</h3>
//      <p className="mt-2 text-gray-600">{description}</p>
//    </div>
//  );
 
//  const SignUpModal = ({ toggleModal }) => (
//    <div className="fixed inset-0 z-50 flex justify-center items-center bg-tertiary bg-opacity-50">
//      <div className="bg-white p-6 rounded-xl relative max-w-sm w-full">
//        <button
//          className="absolute top-2 right-2 text-secondary hover:text-gray-700"
//          onClick={toggleModal}
//        >
//          <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
//          </svg>
//        </button>
//        <h2 className="mb-4 text-center text-tertiary">Sign Up</h2>
 
//        <div className="mb-4 flex items-center justify-center">
//          <hr className="border-tertiary w-full" />
//        </div>
//        <button className="bg-primary text-white px-4 py-2 rounded-xl mb-4 w-full">
//          Sign up with Email
//        </button>
//        <button className="bg-primary text-white px-4 py-2 rounded-xl mb-4 w-full">
//          Sign up with Google
//        </button>
//        <p className="text-center text-secondary text-xs mt-4">
//          By signing up, you agree to our Terms and Conditions.
//        </p>
//      </div>
//    </div>
//  );
 
//  export default Hero;



// import React, { useState, useEffect } from "react";
// import Typewriter from "typewriter-effect";
// import Counter from "./Counter"; // Assuming you have created the Counter component as described
// import { Syringe, Briefcase, Microscope, BookOpenCheck } from "lucide-react";
// import hero from "../../assets/vr01.png";

// const formatNumber = (number) => {
//   if (number >= 1e9) {
//     return `${(number / 1e9).toFixed(1)} Billion`;
//   } else if (number >= 1e6) {
//     return `${(number / 1e6).toFixed(1)} Million`;
//   }
//   return number.toString();
// };

// const Hero = () => {
//   const [imageLoaded, setImageLoaded] = useState(false);
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   const toggleModal = () => {
//     setIsModalOpen(!isModalOpen);
//   };

//   useEffect(() => {
//     const handleImageLoad = () => {
//       setImageLoaded(true);
//     };
//     const img = new Image();
//     img.onload = handleImageLoad;
//     img.src = hero;

//     return () => {
//       img.onload = null;
//     };
//   }, []);

//   return (
//     <div className="hero-container flex flex-col items-center justify-center py-10 bg-white">
//       <div className="hero-content text-center max-w-4xl mt-10">
//         <h1 className="text-4xl md:text-5xl font-bold text-primary mb-6">
//           Responsible and Ethical AI for
//         </h1>
//         <div className="typewriter-container mb-6">
//           <Typewriter
//             options={{
//               strings: [
//                 "Researchers",
//                 "Educators",
//                 "Students",
//                 "Academics",
//                 "Scientists",
//                 "Scholars",
//                 "Data Analysts",
//                 "Lecturers",
//               ],
//               autoStart: true,
//               loop: true,
//               deleteSpeed: 50,
//               delay: 75,
//               wrapperClassName: "text-secondary text-2xl md:text-3xl",
//               cursorClassName: "text-secondary text-2xl md:text-3xl",
//             }}
//             className="typewriter-text"
//           />
//         </div>
//         <button
//           type="button"
//           className="bg-backgroundBlue text-primary hover:bg-hover1 active:bg-secondary shadow-md py-2 px-4 rounded-lg mb-10"
//           onClick={() =>
//             (window.location.href =
//               "https://fllky8j5bmm.typeform.com/to/WHfbZpRJ")
//           }
//         >
//           Apply for early access
//         </button>

//         <div className="hero-image mb-8 max-w-md md:max-w-5xl ipad-style">
//           <div className="relative w-full max-w-4xl mx-auto rounded-3xl bg-gray-200 shadow-lg p-6">
//             <div className="absolute top-2 left-1/2 transform -translate-x-1/2 bg-gray-400 rounded-full h-3 w-24"></div>
//             <img
//               src={hero}
//               alt="Screenshot of the Serenity platform interface"
//               className={`block w-full h-auto object-cover rounded-2xl transition-opacity duration-300 ${
//                 imageLoaded ? "opacity-100" : "opacity-0"
//               }`}
//               onLoad={() => setImageLoaded(true)}
//             />
//           </div>
//         </div>

//         <StatsSection />

//         <TilesSection />

//         {isModalOpen && <SignUpModal toggleModal={toggleModal} />}
//       </div>
//     </div>
//   );
// };

// const StatsSection = () => (
//   <div className="stats-section text-center mt-8 mb-8">
//     <h2 className="text-2xl font-bold text-primary mb-4">Extensive Research Database</h2>
//     <p className="text-lg text-gray-700 mb-6">
//       Access millions of papers, citations, and authors for your research needs.
//     </p>
//     <div className="grid grid-cols-1 sm:grid-cols-3 gap-6">
//       <StatItem end={214000000} label="Papers" />
//       <StatItem end={2490000000} label="Citations" />
//       <StatItem end={79000000} label="Authors" />
//     </div>
//   </div>
// );

// const StatItem = ({ end, label }) => (
//   <div className="stat-item bg-white shadow-lg rounded-xl p-6 text-center">
//     <Counter
//       end={end}
//       duration={3}
//       formatFunc={formatNumber}
//       className="text-4xl font-bold text-secondary mb-2"
//     />
//     <p className="text-xl font-semibold text-gray-800">{label}</p>
//   </div>
// );

// const TilesSection = () => (
//   <section className="tiles-section grid grid-cols-1 md:grid-cols-4 gap-6 mt-8">
//     <Tile
//       icon={<Syringe className="mx-auto text-secondary" size={48} />}
//       title="Corporate, Pharma & Enterprise"
//       description="Stay on top of drug development and efficacy, clinical trials, and more."
//     />
//     <Tile
//       icon={<Briefcase className="mx-auto text-secondary" size={48} />}
//       title="Universities & Governments"
//       description="Track and improve research outcomes across your organization."
//     />
//     <Tile
//       icon={<Microscope className="mx-auto text-secondary" size={48} />}
//       title="Researchers"
//       description="See exactly how publications have been cited by newer research."
//     />
//     <Tile
//       icon={<BookOpenCheck className="mx-auto text-secondary" size={48} />}
//       title="Publishers"
//       description="Improve discoverability of your publications and support your authors."
//     />
//   </section>
// );

// const Tile = ({ icon, title, description }) => (
//   <div className="tile bg-hover1 rounded-xl p-6 text-center">
//     <div className="mb-4">{icon}</div>
//     <h3 className="text-xl font-semibold text-gray-800">{title}</h3>
//     <p className="mt-2 text-gray-600">{description}</p>
//   </div>
// );

// const SignUpModal = ({ toggleModal }) => (
//   <div className="fixed inset-0 z-50 flex justify-center items-center bg-tertiary bg-opacity-50">
//     <div className="bg-white p-6 rounded-xl relative max-w-sm w-full">
//       <button
//         className="absolute top-2 right-2 text-secondary hover:text-gray-700"
//         onClick={toggleModal}
//       >
//         <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//           <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
//         </svg>
//       </button>
//       <h2 className="mb-4 text-center text-tertiary">Sign Up</h2>

//       <div className="mb-4 flex items-center justify-center">
//         <hr className="border-tertiary w-full" />
//       </div>
//       <button className="bg-primary text-white px-4 py-2 rounded-xl mb-4 w-full">
//         Sign up with Email
//       </button>
//       <button className="bg-primary text-white px-4 py-2 rounded-xl mb-4 w-full">
//         Sign up with Google
//       </button>
//       <p className="text-center text-secondary text-xs mt-4">
//         By signing up, you agree to our Terms and Conditions.
//       </p>
//     </div>
//   </div>
// );

// export default Hero;




// import React, { useState, useEffect } from "react";
// import { useInView } from "react-intersection-observer";
// import Typewriter from "typewriter-effect";
// import Counter from "./Counter"; // Assuming you have created the Counter component as described
// import { Syringe, Briefcase, Microscope, BookOpenCheck } from "lucide-react";
// import hero from "../../assets/scdemo.png";

// const formatNumber = (number) => {
//   if (number >= 1e9) {
//     return `${(number / 1e9).toFixed(1)} Billion`;
//   } else if (number >= 1e6) {
//     return `${(number / 1e6).toFixed(1)} Million`;
//   }
//   return number.toString();
// };

// const Hero = () => {
//   const [imageLoaded, setImageLoaded] = useState(false);
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   const toggleModal = () => {
//     setIsModalOpen(!isModalOpen);
//   };

//   useEffect(() => {
//     const handleImageLoad = () => {
//       setImageLoaded(true);
//     };
//     const img = new Image();
//     img.onload = handleImageLoad;
//     img.src = hero;

//     return () => {
//       img.onload = null;
//     };
//   }, []);

//   return (
//     <div className="hero-container flex flex-col items-center justify-center py-10 bg-white">
//       <div className="hero-content text-center max-w-4xl mt-10">
//         <h1 className="text-4xl md:text-5xl font-bold text-primary mb-6">
//           Responsible and Ethical AI for
//         </h1>
//         <div className="typewriter-container mb-6">
//           <Typewriter
//             options={{
//               strings: [
//                 "Researchers",
//                 "Educators",
//                 "Students",
//                 "Academics",
//                 "Scientists",
//                 "Scholars",
//                 "Data Analysts",
//                 "Lecturers",
//               ],
//               autoStart: true,
//               loop: true,
//               deleteSpeed: 50,
//               delay: 75,
//               wrapperClassName: "text-secondary text-2xl md:text-3xl",
//               cursorClassName: "text-secondary text-2xl md:text-3xl",
//             }}
//             className="typewriter-text"
//           />
//         </div>
//         <button
//           type="button"
//           className="bg-backgroundBlue text-primary hover:bg-hover1 active:bg-secondary shadow-md py-2 px-4 rounded-lg mb-10"
//           onClick={() =>
//             (window.location.href =
//               "https://fllky8j5bmm.typeform.com/to/WHfbZpRJ")
//           }
//         >
//           Apply for early access
//         </button>

//         <div className="hero-image mb-8 max-w-md md:max-w-5xl">
//           <div
//             className={`border-4 rounded-xl border-tertiary overflow-hidden transition-shadow duration-300 ${
//               imageLoaded ? "shadow-md" : ""
//             }`}
//           >
//             <img
//               src={hero}
//               alt="Screenshot of the Serenity platform interface"
//               className={`block w-full h-auto object-cover transition-opacity duration-300 ${
//                 imageLoaded ? "opacity-100" : "opacity-0"
//               }`}
//               onLoad={() => setImageLoaded(true)}
//             />
//           </div>
//         </div>

//         <StatsSection />

//         <TilesSection />

//         {isModalOpen && <SignUpModal toggleModal={toggleModal} />}
//       </div>
//     </div>
//   );
// };

// const StatsSection = () => (
//   <div className="stats-section text-center mt-8 mb-8">
//     <h2 className="text-2xl font-bold text-primary mb-4">Extensive Research Database</h2>
//     <p className="text-lg text-gray-700 mb-6">
//       Access millions of papers, citations, and authors for your research needs.
//     </p>
//     <div className="grid grid-cols-1 sm:grid-cols-3 gap-6">
//       <StatItem end={214000000} label="Papers" />
//       <StatItem end={2490000000} label="Citations" />
//       <StatItem end={79000000} label="Authors" />
//     </div>
//   </div>
// );

// const StatItem = ({ end, label }) => (
//   <div className="stat-item bg-white shadow-lg rounded-xl p-6 text-center">
//     <Counter
//       end={end}
//       duration={3}
//       formatFunc={formatNumber}
//       className="text-4xl font-bold text-secondary mb-2"
//     />
//     <p className="text-xl font-semibold text-gray-800">{label}</p>
//   </div>
// );

// const TilesSection = () => (
//   <section className="tiles-section grid grid-cols-1 md:grid-cols-4 gap-6 mt-8">
//     <Tile
//       icon={<Syringe className="mx-auto text-secondary" size={48} />}
//       title="Corporate, Pharma & Enterprise"
//       description="Stay on top of drug development and efficacy, clinical trials, and more."
//     />
//     <Tile
//       icon={<Briefcase className="mx-auto text-secondary" size={48} />}
//       title="Universities & Governments"
//       description="Track and improve research outcomes across your organization."
//     />
//     <Tile
//       icon={<Microscope className="mx-auto text-secondary" size={48} />}
//       title="Researchers"
//       description="See exactly how publications have been cited by newer research."
//     />
//     <Tile
//       icon={<BookOpenCheck className="mx-auto text-secondary" size={48} />}
//       title="Publishers"
//       description="Improve discoverability of your publications and support your authors."
//     />
//   </section>
// );

// const Tile = ({ icon, title, description }) => {
//   const { ref, inView } = useInView({
//     triggerOnce: true,
//     threshold: 0.1,
//   });

//   return (
//     <div
//       ref={ref}
//       className={`tile bg-hover1 border border-tertiary rounded-xl p-6 text-center transition-opacity duration-700 ${
//         inView ? "opacity-100" : "opacity-0"
//       }`}
//     >
//       <div className="mb-4">{icon}</div>
//       <h3 className="text-xl font-semibold text-gray-800">{title}</h3>
//       <p className="mt-2 text-gray-600">{description}</p>
//     </div>
//   );
// };

// const SignUpModal = ({ toggleModal }) => (
//   <div className="fixed inset-0 z-50 flex justify-center items-center bg-tertiary bg-opacity-50">
//     <div className="bg-white p-6 rounded-xl relative max-w-sm w-full">
//       <button
//         className="absolute top-2 right-2 text-secondary hover:text-gray-700"
//         onClick={toggleModal}
//       >
//         <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//           <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
//         </svg>
//       </button>
//       <h2 className="mb-4 text-center text-tertiary">Sign Up</h2>

//       <div className="mb-4 flex items-center justify-center">
//         <hr className="border-tertiary w-full" />
//       </div>
//       <button className="bg-primary text-white px-4 py-2 rounded-xl mb-4 w-full">
//         Sign up with Email
//       </button>
//       <button className="bg-primary text-white px-4 py-2 rounded-xl mb-4 w-full">
//         Sign up with Google
//       </button>
//       <p className="text-center text-secondary text-xs mt-4">
//         By signing up, you agree to our Terms and Conditions.
//       </p>
//     </div>
//   </div>
// );

// export default Hero;


// import React, { useState, useEffect } from "react";
// import { useInView } from "react-intersection-observer";
// import Typewriter from "typewriter-effect";
// import Counter from "./Counter"; // Assuming you have created the Counter component as described
// import { Syringe, Briefcase, Microscope, BookOpenCheck } from "lucide-react";
// import hero from "../../assets/scdemo.png";

// const formatNumber = (number) => {
//   if (number >= 1e9) {
//     return `${(number / 1e9).toFixed(1)} Billion`;
//   } else if (number >= 1e6) {
//     return `${(number / 1e6).toFixed(1)} Million`;
//   }
//   return number.toString();
// };

// const Hero = () => {
//   const [imageLoaded, setImageLoaded] = useState(false);
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   const toggleModal = () => {
//     setIsModalOpen(!isModalOpen);
//   };

//   useEffect(() => {
//     const handleImageLoad = () => {
//       setImageLoaded(true);
//     };
//     const img = new Image();
//     img.onload = handleImageLoad;
//     img.src = hero;

//     return () => {
//       img.onload = null;
//     };
//   }, []);

//   return (
//     <div className="hero-container flex flex-col items-center justify-center py-10 bg-white">
//       <div className="hero-content text-center max-w-4xl mt-10">
//         <h1 className="text-4xl md:text-5xl font-bold text-primary mb-6">
//           Responsible and Ethical AI for
//         </h1>
//         <div className="typewriter-container mb-6">
//           <Typewriter
//             options={{
//               strings: [
//                 "Researchers",
//                 "Educators",
//                 "Students",
//                 "Academics",
//                 "Scientists",
//                 "Scholars",
//                 "Data Analysts",
//                 "Lecturers",
//               ],
//               autoStart: true,
//               loop: true,
//               deleteSpeed: 50,
//               delay: 75,
//               wrapperClassName: "text-secondary text-2xl md:text-3xl",
//               cursorClassName: "text-secondary text-2xl md:text-3xl",
//             }}
//             className="typewriter-text"
//           />
//         </div>
//         <button
//           type="button"
//           className="bg-backgroundBlue text-primary hover:bg-hover1 active:bg-secondary shadow-md py-2 px-4 rounded-lg mb-10"
//           onClick={() =>
//             (window.location.href =
//               "https://fllky8j5bmm.typeform.com/to/WHfbZpRJ")
//           }
//         >
//           Apply for early access
//         </button>

//         <div className="hero-image mb-8 max-w-md md:max-w-5xl">
//           <div
//             className={`border-4 rounded-xl border-tertiary overflow-hidden transition-shadow duration-300 ${
//               imageLoaded ? "shadow-md" : ""
//             }`}
//           >
//             <img
//               src={hero}
//               alt="Screenshot of the Serenity platform interface"
//               className={`block w-full h-auto object-cover transition-opacity duration-300 ${
//                 imageLoaded ? "opacity-100" : "opacity-0"
//               }`}
//               onLoad={() => setImageLoaded(true)}
//             />
//           </div>
//         </div>

//         <StatsSection />

//         <TilesSection />

//         {isModalOpen && <SignUpModal toggleModal={toggleModal} />}
//       </div>
//     </div>
//   );
// };

// const StatsSection = () => (
//   <div className="stats-section text-center mt-8 mb-8">
//     <h2 className="text-2xl font-bold text-primary mb-4">Extensive Research Database</h2>
//     <p className="text-lg text-gray-700 mb-6">
//       Access millions of papers, citations, and authors for your research needs.
//     </p>
//     <div className="grid grid-cols-1 sm:grid-cols-3 gap-6">
//       <StatItem end={214000000} label="Papers" />
//       <StatItem end={2490000000} label="Citations" />
//       <StatItem end={79000000} label="Authors" />
//     </div>
//   </div>
// );

// const StatItem = ({ end, label }) => (
//   <div className="stat-item bg-white shadow-lg rounded-xl p-6 text-center">
//     <Counter
//       end={end}
//       duration={3}
//       formatFunc={formatNumber}
//       className="text-4xl font-bold text-secondary mb-2"
//     />
//     <p className="text-xl font-semibold text-gray-800">{label}</p>
//   </div>
// );

// const TilesSection = () => (
//   <section className="tiles-section grid grid-cols-1 md:grid-cols-4 gap-6 mt-8">
//     <Tile
//       icon={<Syringe className="mx-auto text-secondary" size={48} />}
//       title="Corporate, Pharma & Enterprise"
//       description="Stay on top of drug development and efficacy, clinical trials, and more."
//       index={0}
//     />
//     <Tile
//       icon={<Briefcase className="mx-auto text-secondary" size={48} />}
//       title="Universities & Governments"
//       description="Track and improve research outcomes across your organization."
//       index={1}
//     />
//     <Tile
//       icon={<Microscope className="mx-auto text-secondary" size={48} />}
//       title="Researchers"
//       description="See exactly how publications have been cited by newer research."
//       index={2}
//     />
//     <Tile
//       icon={<BookOpenCheck className="mx-auto text-secondary" size={48} />}
//       title="Publishers"
//       description="Improve discoverability of your publications and support your authors."
//       index={3}
//     />
//   </section>
// );

// const Tile = ({ icon, title, description, index }) => {
//   const { ref, inView } = useInView({
//     triggerOnce: true,
//     threshold: 0.1,
//   });

//   return (
//     <div
//       ref={ref}
//       className={`tile bg-[#f8f8f8] border border-tertiary rounded-xl p-6 text-center transition-opacity duration-700 delay-${index * 200} ${
//         inView ? "opacity-100 translate-y-0" : "opacity-0 translate-y-10"
//       }`}
//     >
//       <div className="mb-4">{icon}</div>
//       <h3 className="text-xl font-semibold text-gray-800">{title}</h3>
//       <p className="mt-2 text-gray-600">{description}</p>
//     </div>
//   );
// };

// const SignUpModal = ({ toggleModal }) => (
//   <div className="fixed inset-0 z-50 flex justify-center items-center bg-tertiary bg-opacity-50">
//     <div className="bg-white p-6 rounded-xl relative max-w-sm w-full">
//       <button
//         className="absolute top-2 right-2 text-secondary hover:text-gray-700"
//         onClick={toggleModal}
//       >
//         <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//           <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
//         </svg>
//       </button>
//       <h2 className="mb-4 text-center text-tertiary">Sign Up</h2>

//       <div className="mb-4 flex items-center justify-center">
//         <hr className="border-tertiary w-full" />
//       </div>
//       <button className="bg-primary text-white px-4 py-2 rounded-xl mb-4 w-full">
//         Sign up with Email
//       </button>
//       <button className="bg-primary text-white px-4 py-2 rounded-xl mb-4 w-full">
//         Sign up with Google
//       </button>
//       <p className="text-center text-secondary text-xs mt-4">
//         By signing up, you agree to our Terms and Conditions.
//       </p>
//     </div>
//   </div>
// );

// export default Hero;


// import React, { useState, useEffect } from "react";
// import Typewriter from "typewriter-effect";
// import Counter from "./Counter"; // Assuming you have created the Counter component as described
// import { Syringe, Briefcase, Microscope, BookOpenCheck } from "lucide-react";
// import hero from "../../assets/screenshot12.png";
// import hunterHub from "../../assets/hunterhub.png";
// import innovateCalgary from "../../assets/innovatecalgary.png";
// import uofc from "../../assets/uofc.png"; // Make sure to add the uofc.png image to your assets directory

// const formatNumber = (number) => {
//   if (number >= 1e9) {
//     return `${(number / 1e9).toFixed(1)} Billion`;
//   } else if (number >= 1e6) {
//     return `${(number / 1e6).toFixed(1)} Million`;
//   }
//   return number.toString();
// };

// const Hero = () => {
//   const [imageLoaded, setImageLoaded] = useState(false);
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   const toggleModal = () => {
//     setIsModalOpen(!isModalOpen);
//   };

//   useEffect(() => {
//     const handleImageLoad = () => {
//       setImageLoaded(true);
//     };
//     const img = new Image();
//     img.onload = handleImageLoad;
//     img.src = hero;

//     return () => {
//       img.onload = null;
//     };
//   }, []);

//   return (
//     <div className="hero-container flex flex-col items-center justify-center py-10 bg-white">
//       <div className="hero-content text-center max-w-4xl mt-10">
//         <h1 className="text-4xl md:text-5xl font-bold text-primary mb-6">
//           Responsible and Ethical AI for
//         </h1>
//         <div className="typewriter-container mb-6">
//           <Typewriter
//             options={{
//               strings: [
//                 "Researchers",
//                 "Educators",
//                 "Students",
//                 "Academics",
//                 "Scientists",
//                 "Scholars",
//                 "Data Analysts",
//                 "Lecturers",
//               ],
//               autoStart: true,
//               loop: true,
//               deleteSpeed: 50,
//               delay: 75,
//               wrapperClassName: "text-secondary text-2xl md:text-3xl",
//               cursorClassName: "text-secondary text-2xl md:text-3xl",
//             }}
//             className="typewriter-text"
//           />
//         </div>
//         <button
//           type="button"
//           className="bg-backgroundBlue text-primary hover:bg-hover1 active:bg-secondary shadow-md py-2 px-4 rounded-lg mb-10"
//           onClick={() =>
//             (window.location.href =
//               "https://fllky8j5bmm.typeform.com/to/WHfbZpRJ")
//           }
//         >
//           Apply for early access
//         </button>

//         <div className="hero-image mb-8 max-w-md md:max-w-5xl ipad-style">
//           <div className="border-4 border-gray-300 p-2 rounded-lg">
//             <img
//               src={hero}
//               alt="Screenshot of the Serenity platform interface"
//               className={`block w-full h-auto object-cover rounded transition-opacity duration-300 ${
//                 imageLoaded ? "opacity-100" : "opacity-0"
//               }`}
//               onLoad={() => setImageLoaded(true)}
//             />
//           </div>
//         </div>

//         <StatsSection />

//         <TilesSection />

//         <PartnersSection />

//         {isModalOpen && <SignUpModal toggleModal={toggleModal} />}
//       </div>
//     </div>
//   );
// };

// const StatsSection = () => (
//   <div className="stats-section text-center mt-8 mb-8">
//     <h2 className="text-2xl font-bold text-primary mb-4">Extensive Research Database</h2>
//     <p className="text-lg text-gray-700 mb-6">
//       Access millions of papers, citations, and authors for your research needs.
//     </p>
//     <div className="grid grid-cols-1 sm:grid-cols-3 gap-6">
//       <StatItem end={214000000} label="Papers" />
//       <StatItem end={2490000000} label="Citations" />
//       <StatItem end={79000000} label="Authors" />
//     </div>
//   </div>
// );

// const StatItem = ({ end, label }) => (
//   <div className="stat-item bg-white shadow-lg rounded-xl p-6 text-center">
//     <Counter
//       end={end}
//       duration={3}
//       formatFunc={formatNumber}
//       className="text-4xl font-bold text-secondary mb-2"
//     />
//     <p className="text-xl font-semibold text-gray-800">{label}</p>
//   </div>
// );

// const TilesSection = () => (
//   <section className="tiles-section grid grid-cols-1 md:grid-cols-4 gap-6 mt-8">
//     <Tile
//       icon={<Syringe className="mx-auto text-secondary" size={48} />}
//       title="Corporate, Pharma & Enterprise"
//       description="Stay on top of drug development and efficacy, clinical trials, and more."
//     />
//     <Tile
//       icon={<Briefcase className="mx-auto text-secondary" size={48} />}
//       title="Universities & Governments"
//       description="Track and improve research outcomes across your organization."
//     />
//     <Tile
//       icon={<Microscope className="mx-auto text-secondary" size={48} />}
//       title="Researchers"
//       description="See exactly how publications have been cited by newer research."
//     />
//     <Tile
//       icon={<BookOpenCheck className="mx-auto text-secondary" size={48} />}
//       title="Publishers"
//       description="Improve discoverability of your publications and support your authors."
//     />
//   </section>
// );

// const Tile = ({ icon, title, description }) => (
//   <div className="tile bg-hover1 rounded-xl p-6 text-center">
//     <div className="mb-4">{icon}</div>
//     <h3 className="text-xl font-semibold text-gray-800">{title}</h3>
//     <p className="mt-2 text-gray-600">{description}</p>
//   </div>
// );

// const PartnersSection = () => (
//   <section className="partners-section text-center mt-16">
//     <h2 className="text-2xl font-bold text-primary mb-6">Our Partners</h2>
//     <div className="flex flex-col md:flex-row justify-center items-center gap-8">
//       <img src={hunterHub} alt="Hunter Hub" className="h-20" />
//       <img src={innovateCalgary} alt="Innovate Calgary" className="h-20" />
//       <img src={uofc} alt="University of Calgary" className="h-20" />
//     </div>
//   </section>
// );

// const SignUpModal = ({ toggleModal }) => (
//   <div className="fixed inset-0 z-50 flex justify-center items-center bg-tertiary bg-opacity-50">
//     <div className="bg-white p-6 rounded-xl relative max-w-sm w-full">
//       <button
//         className="absolute top-2 right-2 text-secondary hover:text-gray-700"
//         onClick={toggleModal}
//       >
//         <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//           <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
//         </svg>
//       </button>
//       <h2 className="mb-4 text-center text-tertiary">Sign Up</h2>

//       <div className="mb-4 flex items-center justify-center">
//         <hr className="border-tertiary w-full" />
//       </div>
//       <button className="bg-primary text-white px-4 py-2 rounded-xl mb-4 w-full">
//         Sign up with Email
//       </button>
//       <button className="bg-primary text-white px-4 py-2 rounded-xl mb-4 w-full">
//         Sign up with Google
//       </button>
//       <p className="text-center text-secondary text-xs mt-4">
//         By signing up, you agree to our Terms and Conditions.
//       </p>
//     </div>
//   </div>
// );

// export default Hero;




import React, { useState, useEffect } from "react";
import Typewriter from "typewriter-effect";
import Counter from "./Counter"; // Assuming you have created the Counter component as described
import { Syringe, Briefcase, Microscope, BookOpenCheck } from "lucide-react";
import hero from "../../assets/serenotylogo.png";
import hunterHub from "../../assets/hunterhub.png";
import innovateCalgary from "../../assets/innovatecalgary.png";
import uofc from "../../assets/uofc.png"; // Make sure to add the uofc.png image to your assets directory

const formatNumber = (number) => {
  if (number >= 1e9) {
    return `${(number / 1e9).toFixed(1)} Billion`;
  } else if (number >= 1e6) {
    return `${(number / 1e6).toFixed(1)} Million`;
  }
  return number.toString();
};

const Hero = () => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  useEffect(() => {
    const handleImageLoad = () => {
      setImageLoaded(true);
    };
    const img = new Image();
    img.onload = handleImageLoad;
    img.src = hero;

    return () => {
      img.onload = null;
    };
  }, []);

  return (
    <div className="hero-container flex flex-col items-center justify-center py-10 bg-white">
      <div className="hero-content text-center max-w-4xl mt-10">
        <h1 className="text-4xl md:text-5xl font-bold text-primary mb-6">
          Responsible and Ethical AI for
        </h1>
        <div className="typewriter-container mb-6">
          <Typewriter
            options={{
              strings: [
                "Researchers",
                "Educators",
                "Students",
                "Academics",
                "Scientists",
                "Scholars",
                "Data Analysts",
                "Lecturers",
              ],
              autoStart: true,
              loop: true,
              deleteSpeed: 50,
              delay: 75,
              wrapperClassName: "text-secondary text-2xl md:text-3xl",
              cursorClassName: "text-secondary text-2xl md:text-3xl",
            }}
            className="typewriter-text"
          />
        </div>
        <button
          type="button"
          className="bg-backgroundBlue text-primary hover:bg-hover1 active:bg-secondary shadow-md py-2 px-4 rounded-lg mb-10"
          onClick={() =>
            (window.location.href =
              "https://fllky8j5bmm.typeform.com/to/WHfbZpRJ")
          }
        >
          Apply for early access
        </button>

        <div className="hero-image mb-8 max-w-md md:max-w-5xl ipad-style">
          <div className="border-4 border-gray-300 p-2 rounded-lg">
            <img
              src={hero}
              alt="Screenshot of the Serenity platform interface"
              className={`block w-full h-auto object-cover rounded transition-opacity duration-300 ${
                imageLoaded ? "opacity-100" : "opacity-0"
              }`}
              onLoad={() => setImageLoaded(true)}
            />
          </div>
        </div>

        <StatsSection />

        <TilesSection />

        <PartnersSection />

        {isModalOpen && <SignUpModal toggleModal={toggleModal} />}
      </div>
    </div>
  );
};

const StatsSection = () => (
  <div className="stats-section text-center mt-8 mb-8">
    <h2 className="text-2xl font-bold text-primary mb-4">Extensive Research Database</h2>
    <p className="text-lg text-gray-700 mb-6">
      Access millions of papers, citations, and authors for your research needs.
    </p>
    <div className="grid grid-cols-1 sm:grid-cols-3 gap-6">
      <StatItem end={214000000} label="Papers" />
      <StatItem end={2490000000} label="Citations" />
      <StatItem end={79000000} label="Authors" />
    </div>
  </div>
);

const StatItem = ({ end, label }) => (
  <div className="stat-item bg-white shadow-lg rounded-xl p-6 text-center">
    <Counter
      end={end}
      duration={3}
      formatFunc={formatNumber}
      className="text-4xl font-bold text-secondary mb-2"
    />
    <p className="text-xl font-semibold text-gray-800">{label}</p>
  </div>
);

const TilesSection = () => (
  <section className="tiles-section grid grid-cols-1 md:grid-cols-4 gap-6 mt-8">
    <Tile
      icon={<Syringe className="mx-auto text-secondary" size={48} />}
      title="Corporate, Pharma & Enterprise"
      description="Stay on top of drug development and efficacy, clinical trials, and more."
    />
    <Tile
      icon={<Briefcase className="mx-auto text-secondary" size={48} />}
      title="Universities & Governments"
      description="Track and improve research outcomes across your organization."
    />
    <Tile
      icon={<Microscope className="mx-auto text-secondary" size={48} />}
      title="Researchers"
      description="See exactly how publications have been cited by newer research."
    />
    <Tile
      icon={<BookOpenCheck className="mx-auto text-secondary" size={48} />}
      title="Publishers"
      description="Improve discoverability of your publications and support your authors."
    />
  </section>
);

const Tile = ({ icon, title, description }) => (
  <div className="tile bg-hover1 rounded-xl p-6 text-center">
    <div className="mb-4">{icon}</div>
    <h3 className="text-xl font-semibold text-gray-800">{title}</h3>
    <p className="mt-2 text-gray-600">{description}</p>
  </div>
);

const PartnersSection = () => (
  <section className="partners-section text-center mt-16">
    <h2 className="text-2xl font-bold text-primary mb-6">Our Partners</h2>
    <div className="flex flex-col md:flex-row justify-center items-center gap-8">
      <img src={hunterHub} alt="Hunter Hub" className="h-16 filter grayscale contrast-200" />
      <img src={innovateCalgary} alt="Innovate Calgary" className="h-16 filter grayscale contrast-200" />
      <img src={uofc} alt="University of Calgary" className="h-16 filter grayscale contrast-200" />
    </div>
  </section>
);

const SignUpModal = ({ toggleModal }) => (
  <div className="fixed inset-0 z-50 flex justify-center items-center bg-tertiary bg-opacity-50">
    <div className="bg-white p-6 rounded-xl relative max-w-sm w-full">
      <button
        className="absolute top-2 right-2 text-secondary hover:text-gray-700"
        onClick={toggleModal}
      >
        <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
      <h2 className="mb-4 text-center text-tertiary">Sign Up</h2>

      <div className="mb-4 flex items-center justify-center">
        <hr className="border-tertiary w-full" />
      </div>
      <button className="bg-primary text-white px-4 py-2 rounded-xl mb-4 w-full">
        Sign up with Email
      </button>
      <button className="bg-primary text-white px-4 py-2 rounded-xl mb-4 w-full">
        Sign up with Google
      </button>
      <p className="text-center text-secondary text-xs mt-4">
        By signing up, you agree to our Terms and Conditions.
      </p>
    </div>
  </div>
);

export default Hero;







// import React, { useState, useEffect } from "react";
// import Typewriter from "typewriter-effect";
// import Counter from "./Counter";
// import { Syringe, Briefcase, Microscope, BookOpenCheck } from "lucide-react";
// import hunterHub from "../../assets/hunterhub.png";
// import innovateCalgary from "../../assets/innovatecalgary.png";
// import uofc from "../../assets/uofc.png";

// const gifURL = "https://www.ycombinator.com/media/?type=post&id=81908&key=user_uploads/1948635/de8cba51-6915-48fe-a808-35ed533080d1";

// const formatNumber = (number) => {
//   if (number >= 1e9) return `${(number / 1e9).toFixed(1)}B`;
//   if (number >= 1e6) return `${(number / 1e6).toFixed(1)}M`;
//   return number.toString();
// };

// const Hero = () => {
//   const [imageLoaded, setImageLoaded] = useState(false);
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   const toggleModal = () => setIsModalOpen(!isModalOpen);

//   useEffect(() => {
//     const img = new Image();
//     img.onload = () => setImageLoaded(true);
//     img.src = gifURL;
//     return () => { img.onload = null; };
//   }, []);

//   return (
//     <div className="hero-container flex flex-col items-center justify-center py-10 bg-white">
//       <div className="hero-content text-center max-w-4xl mt-10">
//         <h1 className="text-4xl md:text-5xl font-bold text-primary mb-6">
//           Transform Your Research Process
//         </h1>
//         <div className="typewriter-container mb-6">
//           <Typewriter
//             options={{
//               strings: [
//                 "Efficient Data Analysis",
//                 "Comprehensive Literature Review",
//                 "Seamless Collaboration",
//                 "Insightful Visualizations",
//               ],
//               autoStart: true,
//               loop: true,
//               deleteSpeed: 50,
//               delay: 75,
//               wrapperClassName: "text-secondary text-2xl md:text-3xl",
//               cursorClassName: "text-secondary text-2xl md:text-3xl",
//             }}
//             className="typewriter-text"
//           />
//         </div>
//         <button
//           type="button"
//           className="bg-backgroundBlue text-primary hover:bg-hover1 active:bg-secondary shadow-md py-3 px-6 rounded-lg mb-10 text-lg font-semibold transition-all duration-300"
//           onClick={() => window.location.href = "https://fllky8j5bmm.typeform.com/to/WHfbZpRJ"}
//         >
//           Apply for Early Access
//         </button>

//         <div className="hero-image mb-8 max-w-md md:max-w-5xl ipad-style">
//           <div className="border-4 border-gray-300 p-2 rounded-lg shadow-lg">
//             <img
//               src={gifURL}
//               alt="Smart Notebook for STEM in action"
//               className={`block w-full h-auto object-cover rounded transition-opacity duration-300 ${imageLoaded ? "opacity-100" : "opacity-0"}`}
//               onLoad={() => setImageLoaded(true)}
//             />
//           </div>
//         </div>

//         <p className="text-xl text-gray-700 mb-6 leading-relaxed">
//           Experience a more efficient research process with our Smart Notebook for STEM. Streamline your workflow, analyze data more effectively, and uncover valuable insights with our advanced tools.
//         </p>

//         <StatsSection />

//         <TilesSection />

//         <PartnersSection />

//         {isModalOpen && <SignUpModal toggleModal={toggleModal} />}
//       </div>
//     </div>
//   );
// };

// const StatsSection = () => (
//   <div className="stats-section text-center mt-12 mb-12">
//     <h2 className="text-3xl font-bold text-primary mb-4">Comprehensive Research Database</h2>
//     <p className="text-xl text-gray-700 mb-8">
//       Access a vast collection of research papers, citations, and author profiles to support your work.
//     </p>
//     <div className="grid grid-cols-1 sm:grid-cols-3 gap-8">
//       <StatItem end={214000000} label="Research Papers" />
//       <StatItem end={2490000000} label="Citations" />
//       <StatItem end={79000000} label="Author Profiles" />
//     </div>
//   </div>
// );

// const StatItem = ({ end, label }) => (
//   <div className="stat-item bg-white shadow-lg rounded-xl p-6 text-center transition-all duration-300 hover:shadow-xl hover:transform hover:scale-105">
//     <Counter
//       end={end}
//       duration={3}
//       formatFunc={formatNumber}
//       className="text-4xl font-bold text-secondary mb-2"
//     />
//     <p className="text-xl font-semibold text-gray-800">{label}</p>
//   </div>
// );

// const TilesSection = () => (
//   <section className="tiles-section grid grid-cols-1 md:grid-cols-4 gap-6 mt-12">
//     <Tile
//       icon={<Syringe className="mx-auto text-secondary" size={48} />}
//       title="Pharmaceutical Research"
//       description="Optimize drug development processes and clinical trial analysis."
//     />
//     <Tile
//       icon={<Briefcase className="mx-auto text-secondary" size={48} />}
//       title="Academic Institutions"
//       description="Enhance research output and collaboration across departments."
//     />
//     <Tile
//       icon={<Microscope className="mx-auto text-secondary" size={48} />}
//       title="Individual Researchers"
//       description="Identify key connections and increase research impact."
//     />
//     <Tile
//       icon={<BookOpenCheck className="mx-auto text-secondary" size={48} />}
//       title="Publishers"
//       description="Improve content discoverability and author support."
//     />
//   </section>
// );

// const Tile = ({ icon, title, description }) => (
//   <div className="tile bg-hover1 rounded-xl p-6 text-center transition-all duration-300 hover:shadow-lg hover:transform hover:scale-105">
//     <div className="mb-4">{icon}</div>
//     <h3 className="text-xl font-semibold text-gray-800">{title}</h3>
//     <p className="mt-2 text-gray-600">{description}</p>
//   </div>
// );

// const PartnersSection = () => (
//   <section className="partners-section text-center mt-16">
//     <h2 className="text-3xl font-bold text-primary mb-6">Our Partners</h2>
//     <div className="flex flex-col md:flex-row justify-center items-center gap-8">
//       <img src={hunterHub} alt="Hunter Hub" className="h-16 filter grayscale contrast-200 transition-all duration-300 hover:filter-none" />
//       <img src={innovateCalgary} alt="Innovate Calgary" className="h-16 filter grayscale contrast-200 transition-all duration-300 hover:filter-none" />
//       <img src={uofc} alt="University of Calgary" className="h-16 filter grayscale contrast-200 transition-all duration-300 hover:filter-none" />
//     </div>
//   </section>
// );

// const SignUpModal = ({ toggleModal }) => (
//   <div className="fixed inset-0 z-50 flex justify-center items-center bg-tertiary bg-opacity-50">
//     <div className="bg-white p-6 rounded-xl relative max-w-sm w-full">
//       <button
//         className="absolute top-2 right-2 text-secondary hover:text-gray-700"
//         onClick={toggleModal}
//       >
//         <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//           <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
//         </svg>
//       </button>
//       <h2 className="mb-4 text-center text-tertiary">Join Our Research Community</h2>

//       <div className="mb-4 flex items-center justify-center">
//         <hr className="border-tertiary w-full" />
//       </div>
//       <button className="bg-primary text-white px-4 py-2 rounded-xl mb-4 w-full hover:bg-secondary transition-colors duration-300">
//         Sign up with Email
//       </button>
//       <button className="bg-primary text-white px-4 py-2 rounded-xl mb-4 w-full hover:bg-secondary transition-colors duration-300">
//         Sign up with Google
//       </button>
//       <p className="text-center text-secondary text-xs mt-4">
//         By signing up, you agree to our Terms of Service and Privacy Policy.
//       </p>
//     </div>
//   </div>
// );

// export default Hero;