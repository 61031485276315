// import React, { useEffect, useState } from "react";

// const Counter = ({ end, duration, formatFunc, className }) => {
//   const [count, setCount] = useState(Math.floor(end * 0.9));

//   useEffect(() => {
//     let start = Math.floor(end * 0.9);
//     const incrementTime = (duration / (end - start)) * 1000;

//     const increment = () => {
//       start += 1;
//       setCount(start);

//       if (start < end) {
//         setTimeout(increment, incrementTime);
//       }
//     };

//     increment();
//   }, [end, duration]);

//   return (
//     <div className={className}>
//       {formatFunc ? formatFunc(count) : count}
//     </div>
//   );
// };

// export default Counter;


// import React, { useEffect, useState } from "react";

// const Counter = ({ end, duration, formatFunc, className }) => {
//   const [count, setCount] = useState(Math.floor(end * 0.9));

//   useEffect(() => {
//     let start = Math.floor(end * 0.9);
//     const incrementTime = (duration / (end - start)) * 1000;

//     const increment = () => {
//       start += 1;
//       setCount(start);

//       if (start < end) {
//         setTimeout(increment, incrementTime);
//       }
//     };

//     increment();
//   }, [end, duration]);

//   return (
//     <div className={className}>
//       {formatFunc ? formatFunc(count) : count}
//     </div>
//   );
// };

// export default Counter;

// import React, { useEffect, useState } from "react";

// const Counter = ({ end, duration, formatFunc, className }) => {
//   const [count, setCount] = useState(Math.floor(end * 0.9));
//   const incrementAmount = Math.ceil((end - count) / (duration * 60)); // Calculate increment amount based on duration

//   useEffect(() => {
//     const timer = setInterval(() => {
//       setCount((prevCount) => {
//         const nextCount = prevCount + incrementAmount;
//         return nextCount >= end ? end : nextCount; // Ensure we don't exceed the end value
//       });
//     }, 1000 / 60); // Run every 1/60th of a second for smoother animation

//     return () => clearInterval(timer); // Cleanup interval on unmount
//   }, [end, duration]);

//   return (
//     <div className={className}>
//       {formatFunc ? formatFunc(count) : count}
//     </div>
//   );
// };

// export default Counter;




// import React, { useEffect, useState, useRef } from "react";

// const Counter = ({ end, duration, formatFunc, className }) => {
//   const [count, setCount] = useState(Math.floor(end * 0.9));
//   const endRef = useRef(end); // Use ref to keep track of dynamic end value
//   endRef.current = end;

//   useEffect(() => {
//     const startTime = Date.now();
//     const startCount = count;
//     const animate = () => {
//       const elapsedTime = Date.now() - startTime;
//       const progress = Math.min(1, elapsedTime / (duration * 1000));
//       const nextCount = Math.floor(startCount + (endRef.current - startCount) * progress);
//       setCount(nextCount);

//       if (progress < 1) {
//         requestAnimationFrame(animate); // Continue animation until duration is reached
//       }
//     };

//     const animationFrame = requestAnimationFrame(animate);

//     return () => cancelAnimationFrame(animationFrame); // Cleanup animation frame on unmount
//   }, [end, duration]);

//   return (
//     <div className={className}>
//       {formatFunc ? formatFunc(count) : count}
//     </div>
//   );
// };

// export default Counter;

import React, { useEffect, useState, useRef } from "react";

const Counter = ({ end, duration, formatFunc, className }) => {
  const [count, setCount] = useState(0);
  const endRef = useRef(end);

  useEffect(() => {
    const startTime = Date.now();
    const startCount = count;
    
    const animate = () => {
      const elapsedTime = Date.now() - startTime;
      const progress = elapsedTime / (duration * 1000);
      const easedProgress = easeOutCubic(progress); // Apply easing function
      
      const nextCount = Math.floor(startCount + (endRef.current - startCount) * easedProgress);
      setCount(nextCount);

      if (progress < 1) {
        requestAnimationFrame(animate);
      }
    };

    const animationFrame = requestAnimationFrame(animate);

    return () => cancelAnimationFrame(animationFrame);
  }, [end, duration]);

  // Easing function: cubic out
  const easeOutCubic = (t) => 1 - Math.pow(1 - t, 3);

  return (
    <div className={className}>
      {formatFunc ? formatFunc(count) : count}
    </div>
  );
};

export default Counter;


